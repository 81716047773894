import { useCallback, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import ConfirmDialog from "../../../common/inputs/ConfirmDialog";
import { Task } from "@emberly/zenith-client";
import { useDriver } from "../../../../providers/DriverProvider";

export default function VehicleSelector() {
  const { t } = useTranslation();
  const { id, vehicles, vehicle, setVehicle } = useDriver();
  const [action, setAction] = useState(null);

  const handleChange = useCallback(async (ev) => {
    const value = ev.target.value;
    const selectedVehicle = vehicles.find(t => t.id === value);

    // Show dialog to warn user that vehicle is already in user by another driver
    if (!!selectedVehicle && !!selectedVehicle.driver?.id && selectedVehicle.driver?.id !== id) {
      const task = new Task();
      setAction(task);

      try {
        await task.wait();
      } catch {
        return;
      } finally {
        setAction(null);
      }
    }

    if (vehicle?.id !== value) {
      setVehicle(selectedVehicle);
    }
  }, [vehicles, setVehicle, id, vehicle]);

  const vehicleDoesntExist = !!vehicle?.id && !vehicles.find(t => t.id === vehicle.id);

  return (
    <>
      <FormControl fullWidth size="small" variant="filled">
        <InputLabel>{t("myMissions:vehicleSelector:title")}</InputLabel>
        <Select
          onChange={handleChange}
          value={vehicle?.id || ""}
        >
          <MenuItem value="">{t("myMissions:vehicleSelector:none")}</MenuItem>

          <Divider />

          {vehicleDoesntExist ? (
            <MenuItem value={vehicle.id}>{vehicle.name}</MenuItem>
          ) : null}

          {vehicles.map((c, key) => <MenuItem value={c.id} key={key}>{c.title}</MenuItem>)}
        </Select>
      </FormControl>

      <ConfirmDialog
        title={t("myMissions:vehicleSelector:vehicleInUseTitle")}
        description={t("myMissions:vehicleSelector:vehicleInUseDescription")}
        task={action}
      />
    </>
  );
}