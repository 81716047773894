import { Grid, Typography } from "@mui/material";

export default function SurfaceSection(props) {
  const { title } = props;

  return (
    <Grid item container xs={12} sm={12} md={6} lg={12} xl={6} alignItems="flex-start" justifyContent="flex-start">
      <Grid item container spacing={1} direction="row">
        {!!title ? (
          <Grid item xs={12}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        ) : null}

        {props.children}

      </Grid>
    </Grid>
  );
}