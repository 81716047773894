import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Compare } from "@emberly/zenith-client";
import EntityTable from "../../../common/tables/EntityTable";
import moment from "moment/moment";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import { FixNumber } from "../../../../common/orders";

export default function ContactOrdersCard(props) {
  const { contactId } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onRowClick = useCallback((params, ev) => {
    navigate(`./${params.row.missionId}`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        headerName: t("common:tables:id"),
        flex: 0,
        minWidth: 60,
      },
      {
        field: "missionNumber",
        headerName: t("contact:contactOrdersCard:fieldMissionNumber"),
        flex: 0,
        minWidth: 60,
      },
      {
        field: "created",
        type: "date",
        headerName: t("common:tables:created"),
        valueFormatter: (params) => moment(params.value).locale(i18n.language).format("L"),
        flex: 0,
        minWidth: 120,
      },
      {
        field: "calculatedTotal",
        type: "number",
        headerName: t("contact:contactOrdersCard:fieldTotal"),
        valueGetter: (params) => params.row.payment?.calculatedTotal || { value: "0", currency: 0 },
        valueFormatter: (params) => `${FixNumber(params.value.value)} ${getCurrencyShorthand(t, params.value.currency)}`,
        flex: 0,
        minWidth: 120,
      }
    ]
  }, [t, i18n.language]);

  const query = useMemo(() => ({
    or: [
      {
        path: "customer.id",
        comparer: Compare.EQ,
        value: contactId, 
      },
    ],
    name: `contactorderscard_${contactId}`
  }), [contactId]);

  return (
    <EntityTable
      tableId="contact_orders"
      type="Order"
      columns={columns}
      filter={query}
      onRowClick={onRowClick}
      emptyStateTitle={t("contact:contactOrdersCard:emptyStateTitle")}
      emptyStateDescription={t("contact:contactOrdersCard:emptyStateDescription")}
      xs={12}
    />
  );
}