import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Table, TableBody } from "@mui/material";
import { useTranslation } from "react-i18next";
import StateChange from "./StateChange";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      background: theme.palette.background.grey,
      width: "100%",
      height: "auto"
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      }
    }
  })
);

export default function StateCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { path } = props;

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        <Grid container alignItems="center">

          <Grid item xs={12}>
            <Typography variant="subtitle1">{t("mission:cards:tasks:driver:states:title")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Table className={classes.table}>
              <TableBody>

                <StateChange
                  title={t("mission:cards:tasks:driver:states:started")}
                  path={`${path}.execution.history.started`}
                />

                <StateChange
                  title={t("mission:cards:tasks:driver:states:arrived")}
                  path={`${path}.execution.history.arrived`}
                />

                <StateChange
                  title={t("mission:cards:tasks:driver:states:loaded")}
                  path={`${path}.execution.history.loaded`}
                />

                <StateChange
                  title={t("mission:cards:tasks:driver:states:completed")}
                  path={`${path}.execution.history.completed`}
                />

                <StateChange
                  title={t("mission:cards:tasks:driver:states:acknowledged")}
                  path={`${path}.execution.history.acknowledged`}
                />

              </TableBody>
            </Table>
          </Grid>

        </Grid>
      </div>
    </Grid>
  );
}

