import { useTranslation } from "react-i18next";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { useMission } from "../../../../providers/MissionProvider";
import { makeStyles } from "tss-react/mui";
import { useEntityById } from "@emberly/zenith-client";

const useStyles = makeStyles()(
  (theme) => ({
    header: {
      marginTop: theme.spacing(0.5)
    },
    chip: {
      borderRadius: theme.spacing(0.5),
      color: theme.palette.info.dark,
      background: theme.palette.info.background,
      maxWidth: "320px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  })
);

export default function ContactSuggestions(props) {
  const { onSelect } = props;
  const { t } = useTranslation();
  const { mission } = useMission();
  const { classes } = useStyles();

  const actors = mission.actors;
  const owner = actors.owner;
  const requester = actors.requester;
  const agreementId = actors?.contract?.agreement?.id;

  const { entity: agreement } = useEntityById("Agreement", agreementId, !!agreementId);
  const accountId = agreement?.defaultAccount?.id;
  const { entity: defaultAccount } = useEntityById("Contact", accountId, !!accountId);

  return (
    <Grid item container spacing={1} xs={12} alignItems="space-around">

      {
        !!owner?.name  || !!requester?.name || !!defaultAccount?.name ? (
          <Grid item xs={12}>
            <Box className={classes.header}>
              <Typography variant="caption" color="textSecondary" >{t("order:customer:suggestions")}</Typography>
            </Box>
          </Grid>
        ) : null
      }

      {
        !!owner?.name ? (
          <Grid item>
            <Chip className={classes.chip} label={`${t("order:customer:owner")}: ${owner.name}`} onClick={() => onSelect(owner.name, !!owner?.customerNumber ? owner : null)} />
          </Grid>
        ) : null
      }

      {
        !!requester?.name ? (
          <Grid item>
            <Chip className={classes.chip} label={`${t("order:customer:requester")}: ${requester.name}`} onClick={() => onSelect(requester.name, !!requester?.customerNumber ? requester : null)} />
          </Grid>
        ) : null
      }

      {
        !!defaultAccount?.name ? (
          <Grid item>
            <Chip className={classes.chip} label={`${t("order:customer:agreement")}: ${defaultAccount.name}`} onClick={() => onSelect(defaultAccount.name, !!defaultAccount?.customerNumber ? defaultAccount : null)} />
          </Grid>
        ) : null
      }

    </Grid>
  );
}
