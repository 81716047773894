import { useTranslation } from "react-i18next";
import ResponsiveSubPage from "../../../common/core/ResponsiveSubPage";
import { useMission } from "../../../../providers/MissionProvider";
import { ToggleSurface } from "../../../common/core/Surface";
import { Delete as DeleteIcon } from "@mui/icons-material";
import ServiceInfoSection from "../../../common/sections/ServiceInfoSection";
import { Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import SubPageEmptyState from "../common/SubPageEmptyState";
import { useMemo } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme) => ({
    table: {
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        paddingTop: theme.spacing(0.5),
        paddingLeft: 0,
        paddingRight: theme.spacing(3),
        verticalAlign: "top"
      }
    }
  })
);

export default function MissionTaskServiceInfoPage() {
  const { t } = useTranslation();
  const { showServiceInfo, removeServiceInfo, addServiceInfo, mission } = useMission();
  const { classes } = useStyles();

  const menuItems = useMemo(() => [
    {
      label: t("taskServiceInfoPage:delete"),
      icon: <DeleteIcon />,
      onClick: removeServiceInfo
    },
  ], [t, removeServiceInfo]);

  const contract = mission.actors?.contract;

  return (
    <ResponsiveSubPage
      title={t("taskServiceInfoPage:title")}
      xs={12} sm={12} md={8} lg={8} xl={6} xxl={6}
      backPath="../"
      menuItems={showServiceInfo ? menuItems : undefined}
      backButton
    >
      <ToggleSurface xs={12}>
        <Grid item container spacing={1} xs={12}>
          <Grid item>
            <Table className={classes.table}>
              <TableBody>

                <TableRow>
                  <TableCell>
                    <Typography variant="body1" color="textSecondary">{t("taskServiceInfoPage:contract")}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="body1">{contract?.agreement?.name || "-"}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography variant="body1" color="textSecondary">{t("taskServiceInfoPage:insurer")}</Typography>
                  </TableCell>

                  <TableCell>
                    <Typography variant="body1">{contract?.insurance?.insurer || "-"}</Typography>
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </ToggleSurface>

      <ToggleSurface xs={12}>

        {showServiceInfo ? ( // TODO show empty state and a button here
          <ServiceInfoSection />
        ) : (
          <SubPageEmptyState
            title={t("taskServiceInfoPage:emptyState:title")}
            description={t("taskServiceInfoPage:emptyState:description")}
            buttonText={t("taskServiceInfoPage:emptyState:buttonText")}
            onClick={addServiceInfo}
          />
        )}

      </ToggleSurface>
    </ResponsiveSubPage>
  );
}
