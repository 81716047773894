import { useMemo } from "react";
import { useEntity } from "@emberly/zenith-client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DeleteOutline as DeleteIcon } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import ResponsivePage from "../../../common/core/ResponsivePage";

const useStyles = makeStyles()(
  (theme) => ({
    number: {
      color: theme.palette.text.disabled,
      fontWeight: "500"
    }
  })
);

export default function ContactLayout(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entity, hasUpdate, deleteEntity } = useEntity();
  const navigate = useNavigate();

  const menuItems = useMemo(() => [
    {
      label: t("contact:delete"),
      icon: <DeleteIcon />,
      onClick: () => {
        deleteEntity();
        navigate("../../", { relative: "path" });
      }
    },
  ], [t, navigate, deleteEntity]);

  return (
    <ResponsivePage
      title={<>{entity.name}{hasUpdate ? "*" : ""} <span className={classes.number}>#{entity.number}</span></>}
      menuItems={menuItems}
      backButton
      backPath="../../"
    >
      {props.children}
    </ResponsivePage>

  );
}