import { makeStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";
import { useEffect, useRef } from "react";
import { useDevice } from "../../../providers/DeviceProvider";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative",
      height: "48px",
      width: "100%",
      borderRadius: "4px",
      padding: "4px",
      background: theme.palette.common.grey.background,
      overflow: "hidden"
    },
    slider: {
      position: "absolute",
      height: "40px",
      top: "4px",
      left: "0px",
      width: "calc(100% - 8px)",
      borderRadius: "4px",
      border: `2px solid ${theme.palette.primary.main}`,
      transition: "transform 0.2s",
      background: theme.palette.primary.contrast,
      zIndex: 0
    },
    button: {
      zIndex: 2,
      flex: "1 1 0px",
      fontSize: "16px"
    }
  })
);

export default function HorizontalSelector(props) {
  const { items, value, onChange, className } = props;
  const { classes } = useStyles();
  const sliderRef = useRef(null);
  const { screenWidth } = useDevice();
 
  useEffect(() => {
    if (!!sliderRef.current && items.length > 0) {
      const slider = sliderRef.current;
      const parent = slider.parentElement;
      const siblings = parent.children;
      const selected = siblings[value];
      slider.style.width = `calc(${100 / items.length}% - 4px)`;
      slider.style.transform = `translateX(${selected.offsetLeft}px)`;
    }
  }, [value, items.length, value, screenWidth]);

  return (
    <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={`${classes.root} ${className || ""}`}>
      {items.map((item, key) => (
        <Button key={key} className={classes.button} onClick={() => onChange(key, item)}>{item}</Button>
      ))}
      <div className={classes.slider} ref={sliderRef}></div>
    </Grid>
  );
}