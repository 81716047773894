import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Divider, Stack, Chip } from "@mui/material";
import { useTranslation } from "react-i18next";
import ActivityLog from "./common/ActivityLog";
import { ActivityEnumsLists } from "../../../common/constants";
import { useCallback, useState } from "react";
import { CheckBoxOutlineBlank as CheckBoxBlankIcon, CheckBox as CheckBoxIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative",
      background: theme.palette.background.default,
      borderLeft: `1px solid ${theme.palette.divider}`,
      height: "100%",
      width: "100%",
    },
    containedRoot: {
      height: "70vh",
      width: "320px"
    },
    container: {
      padding: theme.spacing(2),
      paddingBottom: 0
    },
    chip: {
      borderRadius: theme.spacing(1)
    }
  })
);

export default function ActivityLogPane(props) {
  const { resourceId, contained } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [categories, setCategories] = useState([...ActivityEnumsLists.Category]);

  const onToggleTag = useCallback((tag, categories) => {
    const checked = categories.includes(tag);
    if (checked) {
      setCategories(categories.filter(t => t !== tag))
    } else {
      setCategories([...categories, tag]);
    }
  }, []);

  return (
    <Stack className={contained ? classes.containedRoot : classes.root}>
      <Grid container spacing={2} className={classes.container}>

        <Grid item xs={12}>
          <Typography variant="h6">{t("activityLogPane:title")}</Typography>
        </Grid>

        {
          !resourceId ? (
            <Grid item xs={12} container spacing={1}>
              {ActivityEnumsLists.Category.slice(1).map(
                (tag, key) => {
                  const checked = categories.includes(tag);
                  return (
                    <Grid item key={key}>
                      <Chip
                        key={key}
                        className={classes.chip}
                        label={t(`activity:enums:category:${tag}`)}
                        color={checked ? "primary" : undefined}
                        variant={checked ? "filled" : "outlined"}
                        onClick={() => onToggleTag(tag, categories)}
                        icon={checked ? <CheckBoxIcon /> : <CheckBoxBlankIcon />}
                      />
                    </Grid>
                  )
                }
              )}
            </Grid>
          ) : null
        }

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <ActivityLog categories={categories} resourceId={resourceId} />

    </Stack>
  );
}