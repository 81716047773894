import { Grid} from "@mui/material";
import { ToggleSurface } from "../../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import ActorsDetailsSection from "./ActorsDetailsSection";
import ContractSection from "./ContractSection";
import { useDevice } from "../../../../../providers/DeviceProvider";

export default function ActorsCard() {
  const { t } = useTranslation();

  return (
    <ToggleSurface title={t("mission:cards:actors:title")} md={12} lg={6}>
      <Grid item container spacing={3}>
        <ActorsDetailsSection/>
        <ContractSection/>
      </Grid>
    </ToggleSurface>
  );
}