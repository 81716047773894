import moment from "moment/moment";
import { Expression, Compare, ParseType } from "@emberly/zenith-client";


export function transpile(columns, tableFilters, initialFilter = null) {

  const filters = tableFilters.items.map((item) => {
    const column = columns.find(t => item.columnField === t.field);
    if (!column) return null;
    return !!column.entityFilter ? compileEntityFilter(column, item) : getFilters(column, item);
  }).filter(t => !!t);

  if (filters.length === 0) {
    return initialFilter;
  } else if (filters.length === 1) {
    return !!initialFilter ? { and: [initialFilter, filters[0]] } : filters[0];
  } else if (!!initialFilter) {
    return tableFilters.linkOperator === "and" || !tableFilters.linkOperator ? { and: [initialFilter, ...filters] } : { and: [initialFilter, { or: filters }] };
  } else {
    return tableFilters.linkOperator === "and" || !tableFilters.linkOperator ? { and: filters } : { or: filters };
  }
}

function compileEntityFilter(column, item) {
  const value = getValue(column, item);
  const comparer = getStringComparer(column, item);
  const expr = column.entityFilter(value, comparer);

  if (!expr) {
    return null;
  } else {
    const e = Expression.Analyze(expr);
    const f = e.convertToFilter();
    return f.compile();
  }
}

function getFilters(column, item) {
  const paths = item.columnField.split("||");

  if (paths.length === 1) {
    return getFilter(column, item, paths[0]);
  } else {
    return {
      or: paths.map(path => getFilter(column, item, path))
    };
  }
}


function getFilter(column, item, path) {
  if (column.type === "date") return getDateFilter(column, item, path);

  return {
    path,
    value: getValue(column, item),
    comparer: getComparerKey(column, item),
    parseType: column.type === "dateTime" ? ParseType.DateTime : ParseType.None
  };
}

function getValue(column, item) {

  if (item?.value === undefined) return "";

  if (item.value?.constructor?.name === "Array") {
    return item.value.map(t => getValue(column, { value: t }))
  }

  if (!!column.entityType) {
    // TODO    
    switch (column.entityType) {
      case "decimal":
      case "string":
        return `${item.value}`;

      case "enum":
        return matchEnumValue(item.value, column.enumMap, column.enumTranslations)
    }
  }

  switch (item.operatorValue) {
    case "startsWith":
      return `/^${item.value}/i`;
    case "endsWith":
      return `/${item.value}$/i`;
    case "contains":
      return `/${item.value}/i`;
  }

  switch (column.type) {
    case "number":
      return Number(item.value);

    case "boolean":
      return !!(item.value === "true");

    default:
      return item.value || "";
  }
}

function matchEnumValue(value, enumMap, enumTranslations) {
  if (!enumMap || !value || !enumTranslations) return 0;

  const keys = Object.keys(enumTranslations);
  const q = value.toLowerCase();

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const m = enumTranslations[key].toLowerCase();
    if (m === q) return enumMap[key];
  }

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const m = enumTranslations[key].toLowerCase();
    if (m.startsWith(q)) return enumMap[key];
  }

  return 0;
}


function getStringComparer(column, item) {

  switch (item.operatorValue) {
    case "equals":
    case "==":
    case "isEmpty":
    case "is":
      return "===";

    default:
      return "===";
  }
}


function getComparerKey(column, item) {

  switch (item.operatorValue) {
    case "equals":
    case "==":
    case "=":
    case "is":
      return Compare.EQ;

    case "not":
      return Compare.NEQ;

    case ">":
    case "after":
      return Compare.GT;

    case ">=":
    case "onOrAfter":
      return Compare.GTE;

    case "<":
    case "before":
      return Compare.LT;

    case "<=":
    case "onOrBefore":
      return Compare.LTE;

    case "isAnyOf":
      return Compare.IN;

    case "isEmpty":
    case "isNotEmpty":
      return Compare.EQ;

    case "startsWith":
    case "endsWith":
    case "contains":
      return column?.entityType === "enum" ? Compare.EQ : Compare.REGEX;

    default:
      return Compare.EQ;
  }
}

// special filter override for date, as we need to account for start and end of day.
function getDateFilter(column, item, path) {
  const value = getValue(column, item);
  const start = moment(value).startOf("date").toISOString();
  const end = moment(value).endOf("date").toISOString();

  switch (item.operatorValue) {
    case "is":
      return {
        and: [
          {
            path,
            comparer: Compare.GTE,
            value: start,
            parseType: ParseType.DateTime
          },
          {
            path,
            comparer: Compare.LTE,
            value: end,
            parseType: ParseType.DateTime
          },
        ]
      };
    case "not":
      return {
        or: [
          {
            path,
            comparer: Compare.LT,
            value: start,
            parseType: ParseType.DateTime
          },
          {
            path,
            comparer: Compare.GT,
            value: end,
            parseType: ParseType.DateTime
          },
        ]
      };
    case "before":
      return {
        path,
        comparer: Compare.LT,
        value: start,
        parseType: ParseType.DateTime
      };
    case "onOrBefore":
      return {
        path,
        comparer: Compare.LT,
        value: end,
        parseType: ParseType.DateTime
      };
    case "after":
      return {
        path,
        comparer: Compare.GT,
        value: end,
        parseType: ParseType.DateTime
      };
    case "onOrAfter":
      return {
        path,
        comparer: Compare.GTE,
        value: start,
        parseType: ParseType.DateTime
      };

    case "isEmpty":
      return {
        path,
        comparer: Compare.EQ,
        value: null
      };

    case "isNotEmpty":
      return {
        path,
        comparer: Compare.NEQ,
        value: null
      };
  }
}