import { useTranslation } from "react-i18next";
import ResponsiveSubPage from "../../../common/core/ResponsiveSubPage";
import { useMission } from "../../../../providers/MissionProvider";
import { ToggleSurface } from "../../../common/core/Surface";
import { Delete as DeleteIcon, NoCrash as NoCrashIcon } from "@mui/icons-material";
import { Grid, Typography, Chip } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { OrderEnumsLists } from "../../../../common/constants";
import { EntityContextProvider, Task } from "@emberly/zenith-client";
import { OrderProvider } from "../../../../providers/OrderProvider";
import OrderSection from "../../../common/sections/OrderSection";
import { useCallback, useMemo, useState } from "react";
import { GetOrderState, GetOrderStateColor, CanOrderBeDeleted } from "../../../../common/orders";
import ConfirmDialog from "../../../common/inputs/ConfirmDialog";

export default function OrderPageSection(props) {
  const { backPath } = props;
  const { t } = useTranslation();
  const { orders, deleteOrder, mission } = useMission();
  const { orderId } = useParams();
  const navigate = useNavigate();

  const order = orders?.find(t => t.id === orderId);
  const [deleteAction, setDeleteAction] = useState(null);

  const orderState = GetOrderState(order);
  const canDelete = CanOrderBeDeleted(orderState, order, orders, mission);

  const onDeleteOrder = useCallback(async () => {
    try {

      const t = new Task();
      setDeleteAction(t);
      await t.wait();
      deleteOrder(orderId);
      navigate("../", { replace: true, relative: "path" });

    } catch {
      setDeleteAction(null);
    }
  }, [deleteOrder, navigate, orderId]);

  const menuItems = useMemo(() => canDelete ? [
    {
      label: t("taskOrderPage:delete"),
      icon: <DeleteIcon />,
      onClick: onDeleteOrder
    },
    {
      label: t("taskOrderPage:openMission"),
      icon: <NoCrashIcon />,
      onClick: () => navigate("./mission", { relative: "path" })
    }
  ] : [
    {
      label: t("taskOrderPage:openMission"),
      icon: <NoCrashIcon />,
      onClick: () => navigate("./mission", { relative: "path" })
    }
  ], [t, onDeleteOrder, navigate, canDelete]);

  return (
    <ResponsiveSubPage
      title={`${t("taskOrderPage:title")} ${order?.name || ""}`}
      xs={12} sm={12} md={8} lg={8} xl={6} xxl={6}
      menuItems={menuItems}
      backPath={backPath || "../../"}
      backButton
    >
      {
        !!order ? (
          <>
            <Grid item container xs={12} justifyContent="space-between" alignItems="baseline">
              <Grid item>
                <Chip label={t(`order:enums:orderState:${OrderEnumsLists.OrderState[orderState]}`)} color={GetOrderStateColor(orderState)} />
              </Grid>
              <Grid item>
                <Typography variant="body2" color="textSecondary">{t("taskOrderPage:number")} {order.number}</Typography>
              </Grid>
            </Grid>

            <ToggleSurface xs={12}>
              <EntityContextProvider id={order.id} type="Order">
                <OrderProvider>
                  <OrderSection />
                </OrderProvider>
              </EntityContextProvider>

            </ToggleSurface>
          </>
        ) : null
      }
      <ConfirmDialog
        title={t("mission:cards:orderCards:deleteDialog:title")}
        description={t("mission:cards:orderCards:deleteDialog:description")}
        task={deleteAction}
        confirmText={t("mission:cards:orderCards:deleteDialog:action")}
      />
    </ResponsiveSubPage>
  );
}
