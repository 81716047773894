import { ListItem, ListItemText, ListItemIcon, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { NavLink } from "react-router-dom"
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  theme => ({
    listItem: {
      height: "48px",
      marginTop: "2px",
      marginBottom: "2px",
    },
    category: {
      height: "48px",
      cursor: "pointer",
      transition: "color 0.2s",
      transitionTimingFunction: "linear",
      color: theme.palette.primary.main,
      "&:after": {
        zIndex: 3,
        position: "absolute",
        height: "100%",
        width: "100%",
        right: 0,
        opacity: 0,
        background: theme.palette.action.hover,
        borderRadius: "8px",
        content: "''",
        display: "block"
      },
      "&:hover:after": {
        opacity: 1
      }
    },
    categoryActive: {
      "&:after": {
        opacity: 1
      }
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
      transition: "color 0.2s",
      transitionTimingFunction: "linear",
      "& li": {
        transition: "color 0.2s",
        transitionTimingFunction: "linear",
        color: theme.palette.primary.main,
        "&:before": {
          zIndex: -1,
          position: "absolute",
          height: "100%",
          width: "100%",
          right: 0,
          background: theme.palette.primary.main,
          opacity: 0,
          borderRadius: "8px",
          content: "''",
          display: "block",
          transform: "scale(0,1)",
          transition: "opacity 0.2s, transform 0.2s",
          transitionTimingFunction: "linear"
        },
        "&:after": {
          zIndex: 3,
          position: "absolute",
          height: "100%",
          width: "100%",
          right: 0,
          opacity: 0,
          background: theme.palette.action.hover,
          borderRadius: "8px",
          content: "''",
          display: "block"
        },
      },
      "&.active > li": {
        color: theme.palette.primary.contrast,
        zIndex: 1,
        "&:before": {
          opacity: 1,
          transform: "scale(1,1)"
        }
      },
      "&:hover li:after": {
        opacity: 1
      }
    },
    text: {
      fontWeight: "500",
      color: "inherit",
      userSelect: "none"
    },
    subText: {
      padding: "8px",
      userSelect: "none"
    }
  }));

export default function SidebarListItem(props) {
  const { text, to, onClick, subText } = props;
  const { classes } = useStyles();

  return (
    <NavLink to={to} className={classes.link}>
      <ListItem className={classes.listItem} onClick={onClick} secondaryAction={!!subText ? <Typography className={classes.subText} variant="subtitle2" textAlign="right">{subText}</Typography> : undefined}>
        <ListItemText>
          <Typography variant="body2" className={classes.text}>
            {text}
          </Typography>
        </ListItemText>
      </ListItem>
    </NavLink>
  );
}

export function SidebarCategoryItem(props) {
  const { text, onClick, expanded, active } = props;
  const { classes } = useStyles();

  return (
    <ListItem className={`${classes.category} ${active ? classes.categoryActive : ""}`} onClick={onClick} secondaryAction={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}>
      <ListItemText>
        <Typography variant="body2" className={classes.text}>
          {text}
        </Typography>
      </ListItemText>
    </ListItem>
  );
}