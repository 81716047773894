import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useInvoiceOrderExport } from "../../../../../hooks/useInvoiceExport";
import ConfirmDialog from "../../../inputs/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";


export default function JournalizeInvoiceButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading, working, exportAction, exportOrder, hasExportSettings } = useInvoiceOrderExport();


  const onExportOrder = useCallback(async () => {
    const { success, exportId } = await exportOrder();
    if (!!success) {
      navigate(`/invoicing/journals/${exportId}`, { relative: "path" });
    }
  }, [exportOrder, navigate]);

  return (
    <>
      <Button fullWidth variant="contained" color="primary" onClick={onExportOrder} disabled={loading || working || !hasExportSettings}>
        {t("order:invoiceFlow:export")}
      </Button>

      <ConfirmDialog
        task={exportAction}
        title={t("order:invoiceFlow:exportDialog:title")}
        description={t("order:invoiceFlow:exportDialog:description")}
        confirmText={t("order:invoiceFlow:exportDialog:confirmText")}
      />
    </>
  );
}
