import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import AvatarEditor from "../inputs/AvatarEditor";
import { useAuth } from "@emberly/zenith-client";
import { useCallback, useEffect, useState } from "react";
import { useStation } from "../../../providers/StationProvider";
import { useDriver } from "../../../providers/DriverProvider";

export default function AccountSettingsDialog(props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const { user, updateUserProfile, updating } = useAuth();
  const { isDriver } = useStation();

  const [name, setName] = useState(user?.name || "");
  const { driver: driverEntity } = useDriver();
  const [phoneNumber, setPhoneNumber] = useState(driverEntity?.phoneNumber || "");
  
  useEffect(() => {
    if (!!user) {
      setName(user.name);
      setPhoneNumber(p => p || driverEntity?.phoneNumber || "");
    } 
  }, [!!user, !!driverEntity]);

  const isValid = name.length > 3;
  const change = user.name !== name || isDriver && !!driverEntity && !!phoneNumber && phoneNumber !== driverEntity.phoneNumber;

  const onSubmit = useCallback(async () => {
    await updateUserProfile({
      name, phoneNumber
    });
    onClose();
  }, [updateUserProfile, name, phoneNumber, onClose]);

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t("sidebar:accountSettings:title")}
      </DialogTitle>
      <DialogContent>

        <Grid container direction="row" spacing={2}>

          <Grid item xs={12}>
            <AvatarEditor />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("sidebar:accountSettings:name")}
              variant="filled"
              fullWidth
              size="small"
              value={name}
              onChange={(ev) => setName(ev.target.value)}
              disabled={updating}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("sidebar:accountSettings:email")}
              variant="filled"
              value={user.email}
              fullWidth
              size="small"
              disabled
              helperText={t("sidebar:accountSettings:emailHelperText")}
            />
          </Grid>

          {
            isDriver ? (
              <Grid item xs={8}>
                <TextField
                  label={t("sidebar:accountSettings:phoneNumber")}
                  variant="filled"
                  fullWidth
                  size="small"
                  value={phoneNumber}
                  onChange={(ev) => setPhoneNumber(ev.target.value)}
                  disabled={updating}
                />
              </Grid>
            ) : null
          }

        </Grid>


      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit} disabled={updating || !isValid || !change}>
          {t("common:save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}