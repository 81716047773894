import { Route, Routes, useParams } from "react-router-dom";
import { EntityContextProvider } from "@emberly/zenith-client";
import MissionContextProvider from "../../providers/MissionProvider";
import MissionTaskPage from "../pages/missiontask/MissionTaskPage";
import MissionTaskContextProvider from "../../providers/MissionTaskProvider";
import MissionTaskFilesPage from "../pages/missiontask/subpages/MissionTaskFilesPage";
import MissionTaskServiceInfoPage from "../pages/missiontask/subpages/MissionTaskServiceInfoPage";
import MissionTaskAssistancePage from "../pages/missiontask/subpages/MissionTaskAssistancePage";
import MissionTaskStoragePage from "../pages/missiontask/subpages/MissionTaskStoragePage";
import MissionTaskOrdersPage from "../pages/missiontask/subpages/MissionTaskOrdersPage";
import OrderPageSection from "../pages/order/common/OrderPageSection";
import MissionPage from "../pages/mission/MissionPage";

export default function MissionTaskRoot() {
  const { missionId, taskId } = useParams();

  return (
    <EntityContextProvider id={missionId} type="Mission">
      <MissionContextProvider>
        <MissionTaskContextProvider id={taskId}>
          <Routes>

            <Route path="storage" element={<MissionTaskStoragePage />} />
            <Route path="assistance" element={<MissionTaskAssistancePage />} />
            <Route path="serviceinfo" element={<MissionTaskServiceInfoPage />} />
            <Route path="files" element={<MissionTaskFilesPage />} />

            <Route path="payment" element={<MissionTaskOrdersPage />} />
            <Route path="payment/:orderId" element={<OrderPageSection backPath="../" />} />
            <Route path="payment/:orderId/mission" element={<MissionPage />} />
            <Route path="payment/:orderId/mission/:taskNumber" element={<MissionPage />} />

            <Route path="mission" element={<MissionPage />} />
            <Route path="mission/:taskNumber" element={<MissionPage />} />
            <Route path="*" element={<MissionTaskPage />} />

          </Routes>
        </MissionTaskContextProvider>
      </MissionContextProvider>
    </EntityContextProvider>
  );
}
