import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { WifiOff as WifiOffIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";

export default function OfflinePage() {
  const { t } = useTranslation();
  const [isConnecting, setIsConnecting] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsConnecting(false), 7500);

    return () => {
      clearTimeout(timer);
      setIsConnecting(true);
    }
  }, []);

  return (
    <Box sx={{ height: "100%", width: "100%", position: "absolute" }}>
      <Grid container justifyContent="center" alignItems="center" sx={{ width: "100%", height: "100%" }}>
        <Grid item container spacing={1} direction="column" alignItems="center">

          <Grid item>
            {
              isConnecting ? (
                <CircularProgress sx={{ width: "64px", height: "64px" }} color="info" size="large" variant="indeterminate" />
              ) : (
                <WifiOffIcon sx={{ width: "64px", height: "64px" }} color="warning" />
              )
            }
          </Grid>

          <Grid item >
            <Typography variant="h6" textAlign="center">{isConnecting ? t("connecting:title") : t("offline:title")}</Typography>
            <Typography variant="body1" textAlign="center" sx={{ width: "360px", maxWidth: "calc(100vw - 32px)", marginBottom: "78px" }} color="textSecondary">{isConnecting ? t("connecting:description") : t("offline:description")}</Typography>
          </Grid>

        </Grid>
      </Grid>
    </Box>
  );
}