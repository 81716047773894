import { useCallback, useMemo } from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import { OrderEnums, OrderEnumsLists } from "../../../common/constants";
import { SetPaymentInfo } from "../../../common/orders";
import InvoiceFlow from "./payment/InvoiceFlow";
import RequestFlow from "./payment/RequestFlow";
import TerminalFlow from "./payment/TerminalFlow";
import { useOrder } from "../../../providers/OrderProvider";
import { useMission } from "../../../providers/MissionProvider";

export default function OrderCheckout() {
  const { t } = useTranslation();

  const { open, order, updateOrderField } = useOrder();
  const paymentMethod = useEntityField("payment.method", Defaults.Enum);
  const { orders } = useMission();

  const onPaymentMethod = useCallback((ev) => {
    paymentMethod.onChange(ev);
    const method = ev.target.value;
    SetPaymentInfo(order, method, updateOrderField);
  }, [paymentMethod, updateOrderField, order]);

  const pm = paymentMethod.value;

  const isLocked = useMemo(() => {
    if (!open) return true;

    if (!!order?.vatTransfer?.order?.id) return true;

    if (!!orders.find(t => t.vatTransfer?.order?.id === order.id || t.deductible?.order?.id === order.id)) return true;

    return false;
  }, [open, orders, order]);


  return ( // TODO try removing grid
    <Grid item container spacing={1}>

      <Grid item xs={12}>
        <FormControl fullWidth size="small" variant="filled" disabled={isLocked}>
          <InputLabel>{t("order:checkout:paymentMethod")}</InputLabel>
          <Select value={pm} onChange={onPaymentMethod}>
            {OrderEnumsLists.PaymentMethod.map((type, value) => <MenuItem value={value} key={value}>{t(`order:enums:paymentMethod:${type}`)}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>

      {
        pm === OrderEnums.PaymentMethod.Invoice ? (
          <InvoiceFlow />
        ) : pm === OrderEnums.PaymentMethod.MobilePaymentRequest ? (
          <RequestFlow />
        ) : pm === OrderEnums.PaymentMethod.Card || pm === OrderEnums.PaymentMethod.Cash || pm === OrderEnums.PaymentMethod.MobilePayment ? (
          <TerminalFlow />
        ) : (
          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" size="large" disabled>
              {t("order:checkout:selectPaymentMethod")}
            </Button>
          </Grid>
        )
      }

    </Grid>
  );
}
