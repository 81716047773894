import { useTranslation } from "react-i18next";
import ContactPicker from "../inputs/ContactPicker";
import ContactLink from "../../pages/mission/cards/actors/ContactLink";
import { useCallback, useMemo } from "react";
import { useOrder } from "../../../providers/OrderProvider";
import { Defaults, useEntityField } from "@emberly/zenith-client";
import ContactSuggestions from "./panels/ContactSuggestions";
import { UpdateCustomer } from "../../../common/orders";

export default function CustomerPicker() {
  const { t } = useTranslation();
  const { open, order, updateOrderField } = useOrder();
  const customerName = useEntityField("customer.name", Defaults.String);
  const customerId = useEntityField("customer.id", Defaults.String);
  const customerIsReferenced = useEntityField("customer.isReferenced", Defaults.Boolean)
  const { customer } = order;
  const isEmpty = !customerName.value;

  const onOwnerContactChanged = useCallback((name, contact) => {
    
    if (!!contact) {
      UpdateCustomer(contact, updateOrderField);

    } else {
      if (customerIsReferenced.value && name !== customerName.value) {
        customerIsReferenced.onChange(null, false);
        customerId.onChange(null, "");
      } 

      customerName.onChange(null, name);
    }
  }, [updateOrderField, customerName, customerId, customerIsReferenced]);

  const contact = useMemo(() => {
    return customerId.loading || customerName.loading ? null : { id: customerId.value, name: customerName.value };
  }, [customerId.value, customerName.value, customerId.loading, customerName.loading]);


  return (
    <>
      <ContactPicker
        label={t("order:customer:name")}
        onChange={onOwnerContactChanged}
        value={contact}
        disabled={!open}
        customer
      />
      
      <ContactLink
        open={customer.isReferenced}
        contactNumber={customer.contactNumber}
        contactId={customer.id}
        description={customer.description}
      />

      {
        isEmpty ? (
          <ContactSuggestions onSelect={onOwnerContactChanged} />
        ) : null
      }
    </>
  );
}
