import { useTranslation } from "react-i18next";
import { useDriver } from "../../../../providers/DriverProvider";
import { Tab, Tabs } from "@mui/material";

export default function DriverTabSelector(props) {
  const { onChange, value } = props;
  const { t } = useTranslation();
  const { activeMissions, completedMissions } = useDriver();

  return (
    <Tabs onChange={onChange} value={value} variant="fullWidth" >
      <Tab value={0} label={`${t("myMissions:activeMissionsTab")} (${activeMissions.length})`} />
      <Tab value={1} label={`${t("myMissions:completedMissionsTab")} (${completedMissions.length})`} />
    </Tabs>
  );
}
