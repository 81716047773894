import { ExportEnums } from "../constants";
import { convertTo as convertToXL10 } from "./xl10";
import { convertTo as convertToSO01 } from "./so01";


export function convertCashJournalsToFormat(format, journalLines) {
  switch (format) {
    case ExportEnums.CashExportFormats.XL10: 
      return convertToXL10(journalLines);
  }
}

export function convertInvoiceJournalsToFormat(format, journalLines, data, t) {
  switch (format) {
    case ExportEnums.InvoiceExportFormats.SO01:
      return convertToSO01(journalLines, data, t);
  }
}

// Save with UTF8-BOM
export function downloadCSV(data, filename) {
  const utf8 = new TextEncoder("utf-8").encode(data);

  const blob = new Blob(
    [ 
      new Uint8Array([0xEF, 0xBB, 0xBF]),
      utf8
    ],
    { 
      type: "text/csv;charset=utf-8"
    }
  );

  downloadBlob(blob, filename);
}


export function downloadBlob(blob, filename) {
  // Create an object URL for the blob object
  const url = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement("a");

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || "download";

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };

  // Add the click event listener on the anchor element
  // Comment out this line if you don"t want a one-off download of the blob content
  a.addEventListener("click", clickHandler, false);

  a.click();

  return a;
}