import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EntityTable from "../../common/tables/EntityTable";
import { EntityFieldTypes } from "@emberly/zenith-client";
import ResponsivePage from "../../common/core/ResponsivePage";

export default function WarehouseRegister() {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        headerName: t("warehouseRegister:fieldName"),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "address",
        headerName: t("contactRegister:fieldAddress"),
        flex: 1,
        valueGetter: params => params.row.location?.address || "-",
        minWidth: 120,
      },
    ]
  }, [t]);

  const fields = useMemo(() => [
    { name: t("warehouseRegister:fieldName"), path: "name", type: EntityFieldTypes.String, required: true },
    { name: t("warehouseRegister:fieldAddress"), path: "location", type: EntityFieldTypes.Location },
  ], [t])

  return (
    <ResponsivePage
      title={t("warehouseRegister:title")}
      xs={12}
      xl={8}
    >
      <EntityTable
        tableId="warehouses"
        type="Warehouse"
        columns={columns}
        fields={fields}
        createTitle={t("warehouseRegister:create")}
        editTitle={t("warehouseRegister:edit")}
        emptyStateTitle={t("warehouseRegister:emptyStateTitle")}
        emptyStateDescription={t("warehouseRegister:emptyStateDescription")}
        createButton
      />
    </ResponsivePage>
  );
}