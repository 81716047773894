import { Grid, Button, Typography, Divider } from "@mui/material";
import { useMission } from "../../../../../providers/MissionProvider";
import { Add as AddIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import OrderCard from "./OrderCard";
import ConfirmDialog from "../../../../common/inputs/ConfirmDialog";
import { useState, useCallback } from "react";
import { Task } from "@emberly/zenith-client";
import { MissionEnums } from "../../../../../common/constants";
import { inViewport } from "../../../../../common/helpers";

export default function OrderCards() {
  const { orders, makeOrder, deleteOrder, mission } = useMission();
  const { t } = useTranslation();
  const [triggerScroll, setTriggerScroll] = useState(false);

  const onMakeOrder = useCallback(() => {
    setTriggerScroll(true);
    makeOrder();
  }, [makeOrder]);

  const onLastCardRef = useCallback((element) => {
    if (!!triggerScroll && !!element && !inViewport(element)) {
      setTriggerScroll(false);
      element?.scrollIntoView();
    }
  }, [triggerScroll]);

  const [deleteAction, setDeleteAction] = useState(null);

  const onDeleteOrder = useCallback(async (orderId) => {
    const t = new Task();
    setDeleteAction(t);

    try {
      await t.wait();
      deleteOrder(orderId);
    } catch {
      setDeleteAction(null);
    }
  }, [deleteOrder]);

  return mission?.state > MissionEnums.State.Draft ? (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item container xs={12}>

        <Grid item xs>
          <Typography variant="h5">{t("mission:cards:orderCards:title")}</Typography>
        </Grid>

        <Grid item>
          <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={onMakeOrder}>{t("mission:cards:orderCards:add")}</Button>
        </Grid>
      </Grid>

      {orders.map((order, index) => (
        <OrderCard order={order} key={index} onDelete={() => onDeleteOrder(order.id)} sectionRef={index === orders.length - 1 ? onLastCardRef : undefined} />
      ))}

      <ConfirmDialog
        title={t("mission:cards:orderCards:deleteDialog:title")}
        description={t("mission:cards:orderCards:deleteDialog:description")}
        task={deleteAction}
        confirmText={t("mission:cards:orderCards:deleteDialog:action")}
      />
    </>
  ) : null;
}