import Decimal from "decimal.js";
import { makeId } from "@emberly/zenith-client"
import { ExportEnums, OrderEnums } from "./constants";
import { CalcProductionSum, GetUserReference, sanitizeNumber } from "./orders";
import { MakeProductGroup } from "./billing";

export function GenerateInvoiceExportData(orders, relatedOrders, missions, exportSettings, productGroups, vatCodes, user, priceUnit, t) {
  const exportId = makeId();
  const journalItems = orders.flatMap(order => MakeInvoiceJournalEntries(exportId, order, relatedOrders, exportSettings, productGroups, vatCodes, priceUnit, t));
  const missionItemSnapshots = missions.map(mission => MakeMissionItemSnapshot(mission));
  const orderUpdates = orders.map(order => MakeOrderExportUpdate(order, exportId));

  return {
    exportId,
    exportEntityData: {
      id: exportId,
      exportType: ExportEnums.ExportType.InvoiceJournal,
      sender: GetUserReference(user),
    },
    exportEntityUpdate: {
      data: {
        id: exportId,
        missions: missionItemSnapshots,
        journalLineCount: journalItems.length,
        orderCount: orders.length,
        totalSum: {
          currency: priceUnit,
          value: GetTotalSum(journalItems)
        }
      },
      fields: [
        "missions", "journalLineCount", "orderCount", "totalSum"
      ]
    },
    orderUpdates,
    journalItems,
    errorCode: ExportEnums.ErrorCode.Ok
  };
}


export function BuildMutations(exportEntity, exportEntityUpdate, journalItems, orderUpdates) {

  const exportNumber = exportEntity.number;

  return [
    {
      type: "InvoiceJournal",
      create: journalItems.map(item => ({ ...item, exportNumber }))
    },
    {
      type: "Order",
      update: orderUpdates
    },
    {
      type: "InvoiceExport",
      update: [
        exportEntityUpdate
      ]
    }
  ];
}


export function MakeInvoiceJournalEntries(exportId, order, relatedOrders, exportSettings, productGroups, vatCodes, priceUnit, t) {
  const result = [];
  const { id: orderId, missionId, payment, customer } = order;
  const { invoice } = payment;

  const hasVatTransfer = !!order.vatTransfer;

  for (let i = 0; i < order.orderLines.length; i++) {
    const item = order.orderLines[i];

    result.push({
      orderNumber: order.number,
      exportId,
      orderId,
      missionId,
      item: hasVatTransfer ? { 
        ...item, 
        productGroup: MakeProductGroup(exportSettings.deductibleProductGroup.id, productGroups, vatCodes) 
      }  : item,
      customer,
      invoiceDate: invoice.invoiceDate,
      dueDate: invoice.dueDate,
      sender: payment.sender,
      comment: payment?.comment,
      reference: payment?.reference,
      contactPerson: invoice.contactPerson,
    });
  }

  // deductibles from other orders
  const deductibles = relatedOrders.filter(t => t.deductible?.order?.id === orderId);

  for (let i = 0; i < deductibles.length; i++) {
    const deductibleOrder = deductibles[i];
    const priceValue = new Decimal(sanitizeNumber(deductibleOrder.deductible.price?.value || "0"));

    result.push({
      orderNumber: order.number,
      exportId,
      orderId,
      missionId,
      item: {
        name: t("invoiceExportLines:deductible"),
        productGroup: MakeProductGroup(exportSettings.deductibleProductGroup.id, productGroups, vatCodes),
        quantity: "1",
        unit: t("invoiceExportLines:defaultUnit"),
        price: {
          currency: deductibleOrder.deductible.price.currency,
          value: priceValue.negated().toString(),
        }
      },
      customer,
      invoiceDate: invoice.invoiceDate,
      dueDate: invoice.dueDate,
      sender: payment.sender,
      comment: payment?.comment,
      reference: payment?.reference,
      contactPerson: invoice.contactPerson,
    });
  }

  if (!!order.deductible) {
    result.push({
      orderNumber: order.number,
      exportId,
      orderId,
      missionId,
      item: {
        name: t("invoiceExportLines:deductible"),
        quantity: "1",
        unit: t("invoiceExportLines:defaultUnit"),
        productGroup: MakeProductGroup(exportSettings.deductibleProductGroup.id, productGroups, vatCodes),
        price: order.deductible.price
      },
      customer,
      invoiceDate: invoice.invoiceDate,
      dueDate: invoice.dueDate,
      sender: payment.sender,
      comment: payment?.comment,
      reference: payment?.reference,
      contactPerson: invoice.contactPerson,
    });
  }


  // vat transfers from other orders
  const vatTransfers = relatedOrders.filter(t => t.vatTransfer?.order?.id === orderId);

  for (let i = 0; i < vatTransfers.length; i++) {
    const vatTransferOrder = vatTransfers[i];

    // TODO here we add all orderlines from the transferring order
    for (let i = 0; i < vatTransferOrder.orderLines.length; i++) {
      const item = vatTransferOrder.orderLines[i];

      result.push({
        orderNumber: order.number,
        exportId,
        orderId,
        missionId,
        item,
        customer,
        invoiceDate: invoice.invoiceDate,
        dueDate: invoice.dueDate,
        sender: payment.sender,
        comment: payment?.comment,
        reference: payment?.reference,
        contactPerson: invoice.contactPerson,
      });
    }

    // Here we deduct the value of the items without vat, so we only end up with the value
    result.push({
      orderNumber: order.number,
      exportId,
      orderId,
      missionId,
      item: {
        name: `${t("invoiceExportLines:vatTransferSummary")} #${vatTransferOrder.number}`,
        quantity: "1",
        unit: t("invoiceExportLines:defaultUnit"),
        productGroup: MakeProductGroup(exportSettings.vatTransferProductGroup.id, productGroups, vatCodes),
        price: {
          currency: priceUnit,
          value: CalcProductionSum(vatTransferOrder.orderLines).negated().toString(),
        }
      },
      customer,
      invoiceDate: invoice.invoiceDate,
      dueDate: invoice.dueDate,
      sender: payment.sender,
      comment: payment?.comment,
      reference: payment?.reference,
      contactPerson: invoice.contactPerson,
    });
  }

  return result;
}

export function MakeMissionItemSnapshot(mission) {
  const { id, number, created, details, target, actors, storageTask, salvageTasks } = mission;

  return {
    id,
    number,
    created,
    details,
    target,
    actors,
    salvageTasks: salvageTasks?.map(t => ({
      taskId: t.taskId,
      number: t.number,
      route: t.route,
    })),
    storageTask: !!storageTask ? {
      taskId: storageTask.taskId,
      number: storageTask.number,
      delivered: storageTask.delivered,
      warehouse: storageTask.warehouse,
      deliveryTime: storageTask.deliveryTime,
      stored: storageTask.stored
    } : null
  }


}


function GetTotalSum(journalItems) {
  let sum = new Decimal("0.0");

  for (let i = 0; i < journalItems.length; i++) {
    const line = journalItems[i];
    let price = new Decimal(sanitizeNumber(line.item.price.value));
    let quantity = new Decimal(sanitizeNumber(line.item.quantity));
    sum = sum.plus(price.mul(quantity));
  }

  return sum.toString();
}




function MakeOrderExportUpdate(order, exportId) {

  return {
    data: {
      id: order.id,
      payment: {
        invoice: {
          exported: true,
          exportId,
          state: OrderEnums.InvoiceState.Finished
        }
      }
    },
    fields: ["payment.invoice.exported", "payment.invoice.exportId", "payment.invoice.state"]
  };

}