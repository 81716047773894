import { useState, useEffect } from "react";

// Hook
export default function useWindowSize() {
  const [windowSize, setWindowSize] = useState({ width: Math.max(window.innerWidth, 128), height: Math.max(window.innerHeight, 128) });

  useEffect(() => {
    let timer = null;
    let safetyTimer = null;

    function handleResize() {
      clearTimeout(timer);
      timer = setTimeout(() => setWindowSize({ width: Math.max(window.innerWidth, 128), height: Math.max(window.innerHeight, 128)}), 100);
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    if (window.innerWidth <= 128 || window.innerHeight <= 128) {
      safetyTimer = setInterval(() => {
        if (window.innerWidth > 128 || window.innerHeight > 128) {
          setWindowSize({ width: Math.max(window.innerWidth, 128), height: Math.max(window.innerHeight, 128) });
          clearInterval(safetyTimer);
        }
      }, 500);
    } else {
      setWindowSize({ width: Math.max(window.innerWidth, 128), height: Math.max(window.innerHeight, 128)});
    }


    return () => { 
      clearInterval(timer);
      clearTimeout(safetyTimer);
      window.removeEventListener("resize", handleResize); 
      window.removeEventListener("orientationchange", handleResize); 
    };
  }, [setWindowSize]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
