import { useState, useCallback, useMemo } from "react";

export function usePersistentFilter(tableId) {

  const defaultValues = useMemo(() => readTableSettings(tableId), [tableId]);

  const [filterOverride, _setFilterOverride] = useState(defaultValues.filterOverride);
  const [sortModel, _setSortModel] = useState(defaultValues.sortModel);
  const [visibleColumns, _setVisibleColumns] = useState(defaultValues.visibleColumns);
  const [pinnedColumns, _setPinnedColumns] = useState(defaultValues.pinnedColumns);
  const [bufferedTableId, setBufferedTableId] = useState(tableId);

  const isPinned = !!tableId && (sortModel?.length !== 0 || (!!filterOverride && filterOverride?.items?.length !== 0) || hasFalseKey(visibleColumns) || pinnedColumns?.left?.length || pinnedColumns?.right?.length);


  useMemo(() => {
    if (tableId !== bufferedTableId) {
      _setFilterOverride(defaultValues.filterOverride);
      _setSortModel(defaultValues.sortModel);
      _setVisibleColumns(defaultValues.visibleColumns);
      setBufferedTableId(tableId);
    }
  }, [defaultValues, tableId, bufferedTableId]);

  const isCurrent = !tableId || tableId === bufferedTableId;

  const setPinnedColumns = useCallback((data) => {
    _setPinnedColumns(data);
    writeTableSettings(tableId, visibleColumns, filterOverride, sortModel, data);
  }, [tableId, visibleColumns, filterOverride, sortModel]);

  const setSortModel = useCallback((data) => {
    _setSortModel(data);
    writeTableSettings(tableId, visibleColumns, filterOverride, data, pinnedColumns);
  }, [tableId, visibleColumns, filterOverride, pinnedColumns]);

  const setFilterOverride = useCallback((data) => {
    _setFilterOverride(data);
    writeTableSettings(tableId, visibleColumns, data, sortModel, pinnedColumns);
  }, [tableId, visibleColumns, sortModel, pinnedColumns]);

  const setVisibleColumns = useCallback((data) => {
    _setVisibleColumns(data);
    writeTableSettings(tableId, data, filterOverride, sortModel, pinnedColumns);
  }, [tableId, filterOverride, sortModel, pinnedColumns]);

  const onReset = useCallback(() => {
    _setFilterOverride({ items: [] });
    _setSortModel([]);
    _setVisibleColumns({});
    _setPinnedColumns({ left: [], right: [] });
    writeTableSettings(tableId);
  }, [tableId, _setFilterOverride, _setSortModel, _setVisibleColumns, _setPinnedColumns]);


  return {
    sortModel: isCurrent ? sortModel : defaultValues.sortModel,
    filterOverride: isCurrent ? filterOverride : defaultValues.filterOverride,
    visibleColumns: isCurrent ? visibleColumns : defaultValues.visibleColumns,
    pinnedColumns: isCurrent ? pinnedColumns : defaultValues.pinnedColumns,
    isPinned,
    onReset,
    setSortModel,
    setFilterOverride,
    setVisibleColumns,
    setPinnedColumns,
    isCurrent
  };
}

const readTableSettings = (tableId) => {
  if (!!tableId) {

    try {
      const json = localStorage.getItem(`tablesettings-${tableId}`);
      const data = JSON.parse(json);

      return {
        visibleColumns: data?.visibleColumns || {},
        filterOverride: data?.filterOverride || { items: [] },
        sortModel: data?.sortModel || [],
        pinnedColumns: data?.pinnedColumns || { left: [], right: [] },
      }

    } catch (err) {
      console.log("cant read table settings", tableId, err);
    }
  }

  return {
    visibleColumns: {},
    filterOverride: { items: [] },
    sortModel: [],
    pinnedColumns: { left: [], right: [] },
  };
}

const writeTableSettings = (tableId, visibleColumns = {}, filterOverride = null, sortModel = [], pinnedColumns = { left: [], right: [] }) => {
  if (!!tableId) {
    localStorage.setItem(`tablesettings-${tableId}`, JSON.stringify({ visibleColumns, filterOverride, sortModel, pinnedColumns }));
  }
};

const hasFalseKey = (data) => {
  if (!data && typeof data !== "object") return false;

  const keys = Object.keys(data);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (data[key] === false) {
      return true;
    }
  }

  return false;
}
