import { Location } from "@emberly/zenith-client";
import mapboxgl from "mapbox-gl";

export function GeoJsonPoint(coordinates, properties = {}) {
  return {
    "type": "Feature",
    "geometry": {
      "type": "Point",
      "coordinates": coordinates
    },
    "properties": {
      ...properties
    }
  };
}

export function GetBounds(list) {
  const bounds = new mapboxgl.LngLatBounds();
  const len = list.length;

  for (let i = 0; i < len; i++) {
    bounds.extend(list[i].geometry.coordinates);
  }

  return bounds;
}

export function MapboxPickerToLocation(coordinates, feature) {
  const context = feature.context;

  const place = context.find(t => t.id?.startsWith("place."))?.text || feature.text || null;
  const postCode = context.find(t => t.id?.startsWith("postcode."))?.text || null;
  const region = context.find(t => t.id?.startsWith("region."))?.text || null;
  const country = context.find(t => t.id?.startsWith("country."))?.text || null;

  const placeName = feature.place_name;
  const address = placeName.indexOf(",") !== -1 ? placeName.substring(0, placeName.indexOf(",")) : placeName;

  return new Location({
    mapboxId: feature.properties?.mapbox_id,
    type: feature.place_type[0] || "address",
    title: placeName,
    address,
    coordinates,
    postCode,
    region,
    place,
    country,
  });
}

export function MapboxQueryToLocation(option, feature) {
  const coordinates = feature?.geometry?.coordinates || null;
  const context = feature?.properties?.context;

  const address = context?.address?.name || option?.address || feature?.name || null;
  const place = context?.place?.name || null;
  const region = context?.region?.name || null;
  const country = context?.country?.name || null;
  const postCode = context?.postcode?.name || null;

  return new Location({
    ...option,
    address,
    place,
    region,
    country,
    postCode,
    coordinates
  });
}


export function FormatLocation(option) {
  if (!option) return "";
  return (typeof option === "string" ? option : !option.title ? option.address : (option.address?.startsWith(option.title) ? option.address : option.title.startsWith(option.address) ? option.title : `${option.title} - ${option.address}`)) || "";
}




export function HaversineDistance(lon1, lat1, lon2, lat2) {

  const R = 6371e3; // metres
  const theta1 = lat1 * Math.PI / 180.0; 
  const theta2 = lat2 * Math.PI / 180.0;
  const dTheta = (lat2 - lat1) * Math.PI / 180.0;
  const dLon = (lon2 - lon1) * Math.PI / 180.0;

  const a = (
    Math.sin(dTheta / 2) * Math.sin(dTheta / 2) +
    Math.cos(theta1) * Math.cos(theta2) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
  );

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // In meters

}