import { Compare } from "@emberly/zenith-client";
import { MissionEnums, OrderEnums } from "./constants";

export const ALL_OPEN_ORDERS = {
  name: "all_open_orders",
  or: [
    {
      path: "payment.method",
      comparer: Compare.EQ,
      value: OrderEnums.PaymentMethod.None
    },
    {
      path: "payment.invoice.state",
      comparer: Compare.EQ,
      value: OrderEnums.InvoiceState.None
    },
    {
      path: "payment.request.confirmed",
      comparer: Compare.EQ,
      value: false
    },
    {
      path: "payment.terminal.confirmed",
      comparer: Compare.EQ,
      value: false
    },
  ]
};

export const ACTIVE_MISSIONS = {
  path: "state",
  comparer: Compare.EQ,
  value: MissionEnums.State.Created,
  name: "active_missions"
};
