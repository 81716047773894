import { useParams } from "react-router";
import { EntityContextProvider } from "@emberly/zenith-client";
import MissionContextProvider from "../../../providers/MissionProvider";
import OrderPageSection from "./common/OrderPageSection";

export function OrderPage() {
  const { missionId } = useParams();

  return (
    <EntityContextProvider id={missionId} type="Mission">
      <MissionContextProvider>
        <OrderPageSection />
      </MissionContextProvider>
    </EntityContextProvider>
  );
}