import { useCallback, useState } from "react";
import { Typography, Avatar, CircularProgress, Slider, IconButton, Button, Grid } from "@mui/material";
import { Clear as ClearIcon, CloudUploadOutlined as CloudUploadIcon, RotateRightOutlined as RotateIcon } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import Cropper from "react-easy-crop";
import ImageUtility from "../../../common/ImageUtility";
import { useTranslation } from "react-i18next";
import { useStation } from "../../../providers/StationProvider";


const useStyles = makeStyles()(
  (theme) => ({
    avatarContainer: {
      position: "relative"
    },
    avatar: {
      position: "relative",
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
      marginRight: theme.spacing(2),
      width: "120px",
      height: "120px",
      borderRadius: theme.spacing(2),
    },
    inputHidden: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 10,
      opacity: 0,
      overflow: "hidden"
    },
    formHidden: {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: 10,
      border: `3px dashed ${theme.palette.grey[500]}`,
      borderRadius: theme.spacing(2)
    },
    editor: {
      borderRadius: theme.spacing(2)
    },
    uploadIcon: {
      position: "absolute",
      top: "22px",
      left: "46px",
      width: "28px",
      height: "28px",
      color: theme.palette.grey[500]
    },
    uploadText: {
      position: "absolute",
      top: "52px",
      left: "8px",
      width: "100px",
      color: theme.palette.common.softIcon,
      lineHeight: "18px",
      fontSize: "13px"
    },
    slider: {
      position: "absolute",
      top: 0,
      right: theme.spacing(-4)
    },
    clearButton: {
      position: "absolute",
      top: 0,
      right: 0
    },
    rotateButton: {
      position: "absolute",
      top: 0,
      left: 0
    },
    progressContainer: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      zIndex: 999
    },
    uploadProgress: {
      position: "absolute",
      top: "28px",
      left: "28px",
      zIndex: 99999
    },
    imageButton: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(1),
      width: "86px"
    }
  })
);

const cropSize = { width: 120, height: 120 };

export default function LogoEditor() {

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { station, updateStation } = useStation();
  const [updating, setUpdating] = useState(false);

  const [file, setFile] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [avatarEditorActive, setLogoEditorActive] = useState(false);
  const [minZoom, setMinZoom] = useState(1);

  const toggleLogoEditorActive = useCallback(_ => setLogoEditorActive(active => !active), [setLogoEditorActive]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, [setCroppedAreaPixels]);

  // TODO fix upload error on empty, remove cancel button and instead do double cancel, and move rotate button under or over slider.!!!!!
  const onMediaLoaded = useCallback((mediaSize) => {
    const m = Math.max(mediaSize.naturalWidth / mediaSize.naturalHeight, mediaSize.naturalHeight / mediaSize.naturalWidth);
    setMinZoom(m);
    setZoom(Math.max(zoom, m));
  }, [setMinZoom, setZoom, zoom]);

  const onChange = useCallback((ev) => {
    if (!!ev.target.files && ev.target.files.length !== 0) {
      setZoom(minZoom);
      setRotation(0);
      const f = ev.target.files[0];
      setFile(URL.createObjectURL(f));
    }
  }, [setFile, setZoom, setRotation, minZoom]);

  const onUpload = useCallback(async () => {
    setUpdating(true);
    setLogoEditorActive(false);

    const imageData = await ImageUtility.GetCroppedImage(
      file,
      croppedAreaPixels,
      rotation
    );

    setFile(null);

    await updateStation({ logoData: imageData });
    setUpdating(false);
  }, [file, setFile, croppedAreaPixels, rotation, setLogoEditorActive, updateStation]);

  const onClear = useCallback(() => setFile(null), [setFile]);
  const onZoom = useCallback((ev, val) => setZoom(val), [setZoom]);
  const onRotate = useCallback(() => setRotation(r => (r + 90) % 360), [setRotation]);

  return avatarEditorActive ? (
    <Grid container direction="row" alignItems="center">
      <Grid item className={classes.avatar}>
        {!file ? (
          <form className={classes.formHidden}>
            <input
              className={classes.inputHidden}
              type="file"
              name="Logo Editor"
              accept="image/jpeg,image/png"
              onChange={onChange}
              multiple={false}
            />
            <CloudUploadIcon className={classes.uploadIcon} />
            <Typography variant="subtitle2" className={classes.uploadText} align="center">{t("stationSettings:logoEditor:upload")}</Typography>
          </form>
        ) : (
          <>
            <Cropper
              classes={{
                containerClassName: classes.editor
              }}
              width={120}
              height={120}
              image={file}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onMediaLoaded={onMediaLoaded}
              onZoomChange={setZoom}
              cropSize={cropSize}
              minZoom={minZoom}
              maxZoom={8}
            />
            <Slider
              orientation="vertical"
              className={classes.slider}
              value={zoom}
              min={minZoom}
              max={8}
              step={0.01}
              onChange={onZoom}
            />
            <IconButton className={classes.clearButton} onClick={onClear} size="small">
              <ClearIcon />
            </IconButton>
            <IconButton className={classes.rotateButton} onClick={onRotate} size="small">
              <RotateIcon />
            </IconButton>
          </>
        )}
      </Grid>

      <Grid item>
        <Button size="medium" onClick={toggleLogoEditorActive}>{t("stationSettings:logoEditor:cancel")}</Button>
        <Button size="medium" variant="contained" color="primary" onClick={onUpload} disabled={!file}>{t("stationSettings:logoEditor:ok")}</Button>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="row" alignItems="center">

      <Grid item className={classes.avatarContainer}>
        <Avatar
          className={classes.avatar}
          alt={station?.displayName}
          src={station?.logo}
        />
        {updating ? <div className={classes.progressContainer}><CircularProgress className={classes.uploadProgress} variant="indeterminate" color="secondary" size="64px" /></div> : null}
      </Grid>

      <Grid item>
        <Button size="medium" onClick={toggleLogoEditorActive} disabled={updating}>{t("stationSettings:logoEditor:edit")}</Button>
      </Grid>

    </Grid>
  );
}

