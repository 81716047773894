import { Grid, Typography, Button } from "@mui/material";
import { InfoOutlined as InfoIcon, Add as AddIcon } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme) => ({
    container: {
      width: "100%",
      height: "100%"
    },
    button: {
      marginTop: theme.spacing(1),
      zIndex: 10
    },
    description: {
      maxWidth: "400px"
    }
  })
);

export default function NoRowsOverlay(props) {
  const { classes } = useStyles();
  const { onCreateBegin, title, description, button, canCreate } = props;
  
  return (
    <Grid className={classes.container} container direction="column" justifyContent="center" alignItems="center">

      <Grid item>
        <InfoIcon />
      </Grid>

      <Grid item>
        <Typography variant="subtitle1" textAlign="center">{title}</Typography>
      </Grid>


      <Grid item>
        <Typography variant="body2" className={classes.description} textAlign="center">{description}</Typography>
      </Grid>

      {!!canCreate ? (
        <Grid item>
          <Button className={classes.button} variant="contained" color="neutral" startIcon={<AddIcon />} size="small" onClick={onCreateBegin}>{button}</Button>
        </Grid>
      ) : null}

    </Grid>
  );
}