import Decimal from "decimal.js";
import { makeId } from "@emberly/zenith-client"
import { ExportEnums, OrderEnums, OrderEnumsLists } from "./constants";
import { CalcTotal, GetUserReference, sanitizeNumber } from "./orders";
import { MakeProductGroup } from "./billing";



export function GenerateCashExportData(orders, exportSettings, productGroups, vatCodes, user, priceUnit, t) {
  const exportId = makeId();
  const journalItems = orders.flatMap(order => MakeCashJournalEntries(exportId, order, exportSettings, productGroups, vatCodes, t));
  const orderUpdates = orders.map(order => MakeOrderExportUpdate(order, exportId));

  return {
    exportId,
    exportEntityData: {
      id: exportId,
      exportType: ExportEnums.ExportType.CashJournal,
      sender: GetUserReference(user),
    },
    exportEntityUpdate: {
      data: {
        id: exportId,
        orderCount: orders.length,
        journalLineCount: journalItems.length,
        totalSum: { currency: priceUnit, value: GetTotalSum(journalItems) }
      },
      fields: [
        "journalLineCount", "orderCount", "totalSum"
      ]
    },
    journalItems,
    orderUpdates,
    errorCode: ExportEnums.ErrorCode.Ok
  };
}


export function BuildMutations(exportEntity, exportEntityUpdate, journalItems, orderUpdates) {

  const exportNumber = exportEntity.number;

  return [
    {
      type: "CashJournal",
      create: journalItems.map(item => ({...item, exportNumber }))
    },
    {
      type: "Order",
      update: orderUpdates
    },
    {
      type: "CashExport",
      update: [
        exportEntityUpdate
      ]
    }
  ];
}

export function MakeCashJournalEntries(exportId, order, exportSettings, productGroups, vatCodes, t) {
  // TODO deductible
  const result = [];
  const orderId = order.id;
  const { deductible, payment, missionId } = order;
  const paymentMethod = t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[payment.method || 0]}`);
  const paymentInfo = payment.terminal || payment.request;
  const count = order.orderLines.length;

  for (let i = 0; i < count; i++) {
    const line = order.orderLines[i];
    const unitSum = CalcTotal(line.price, "1", line.discount);
    const costSum = CalcTotal(line.price, line.quantity, line.discount);

    const vatDecimal = new Decimal(sanitizeNumber(line.productGroup.vatCode.rate)).div(new Decimal("100"))
    const grossSum = costSum.plus(costSum.mul(vatDecimal));

    result.push({
      exportId,
      orderId,
      row: i + 1,
      orderNumber: order.number,
      missionId,
      orderDate: order.created,
      paymentMethod,
      item: line,
      unitPrice: { currency: line.price.currency, value: unitSum.negated().toString() },
      costPrice: { currency: line.price.currency, value: costSum.negated().toString() },
      grossPrice: { currency: line.price.currency, value: grossSum.negated().toString() },
      sender: payment.sender,
      account: line.productGroup.account,
      confirmedBy: paymentInfo.confirmedBy,
      confirmedDate: paymentInfo.confirmedDate,
    });

    result.push({
      exportId,
      orderId,
      row: i + 1,
      orderNumber: order.number,
      missionId,
      orderDate: order.created,
      paymentMethod,
      item: line,
      unitPrice: { currency: line.price.currency, value: unitSum.toString() },
      costPrice: { currency: line.price.currency, value: costSum.toString() },
      grossPrice: { currency: line.price.currency, value: grossSum.toString() },
      sender: payment.sender,
      account: GetRecieverAccount(payment.method, exportSettings),
      confirmedBy: paymentInfo.confirmedBy,
      confirmedDate: paymentInfo.confirmedDate,
    });

  }

  // TODO if deductible

  if (!!deductible) {

    const deductibleItem = {
      name: t("cashExportLines:deductible"),
      productGroup: MakeProductGroup(exportSettings.deductibleProductGroup.id, productGroups, vatCodes)
    };

    const sum = new Decimal(sanitizeNumber(deductible.price.value));
    const vatDecimal = new Decimal(sanitizeNumber(deductibleItem.productGroup.vatCode.rate)).div(new Decimal("100"))
    const grossSum = sum.plus(sum.mul(vatDecimal));

    result.push({
      exportId,
      orderId,
      row: count + 1,
      orderNumber: order.number,
      missionId,
      orderDate: order.created,
      paymentMethod,
      item: deductibleItem,
      unitPrice: { currency: deductible.price.currency, value: sum.negated().toString() },
      costPrice: { currency: deductible.price.currency, value: sum.negated().toString() },
      grossPrice: { currency: deductible.price.currency, value: grossSum.negated().toString() },
      sender: payment.sender,
      account: deductibleItem.productGroup.account,
      confirmedBy: paymentInfo.confirmedBy,
      confirmedDate: paymentInfo.confirmedDate,
    });

    result.push({
      exportId,
      orderId,
      row: count + 1,
      orderNumber: order.number,
      missionId,
      orderDate: order.created,
      paymentMethod,
      item: deductibleItem,
      unitPrice: { currency: deductible.price.currency, value: sum.toString() },
      costPrice: { currency: deductible.price.currency, value: sum.toString() },
      grossPrice: { currency: deductible.price.currency, value: grossSum.toString() },
      sender: payment.sender,
      account: GetRecieverAccount(payment.method, exportSettings),
      confirmedBy: paymentInfo.confirmedBy,
      confirmedDate: paymentInfo.confirmedDate,
    });
  }

  return result;
}


function GetRecieverAccount(paymentMethod, exportSettings) {

  switch (paymentMethod) {
    case OrderEnums.PaymentMethod.MobilePayment:
    case OrderEnums.PaymentMethod.MobilePaymentRequest:
      return exportSettings.mobilePaymentAccount;

    case OrderEnums.PaymentMethod.Card:
      return exportSettings.cardAccount;

    case OrderEnums.PaymentMethod.Cash:
      return exportSettings.cashAccount;

    default:
      throw "no such account"
  }
}


function MakeOrderExportUpdate(order, exportId) {


  switch (order.payment.method) {

    case OrderEnums.PaymentMethod.Cash:
    case OrderEnums.PaymentMethod.Card:
    case OrderEnums.PaymentMethod.MobilePayment:
      return {
        data: {
          id: order.id,
          payment: {
            terminal: {
              exported: true,
              exportId
            }
          }
        },
        fields: ["payment.terminal.exported", "payment.terminal.exportId"]
      };


    case OrderEnums.PaymentMethod.MobilePaymentRequest:
      return {
        data: {
          id: order.id,
          payment: {
            request: {
              exported: true,
              exportId
            }
          }
        },
        fields: ["payment.request.exported", "payment.request.exportId"]
      };

    default:
      throw "no such payment method supported for cash export";
  }
}


export function GetTotalSum(journalItems) {
  let sum = new Decimal("0.0");

  for (let i = 1; i < journalItems.length; i += 2) {
    const item = journalItems[i];
    sum = sum.plus(new Decimal(sanitizeNumber(item.costPrice.value)))
  }

  return sum.toString();
}