import { useCallback, useState } from "react";
import { Typography, IconButton, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Checkbox, TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EditOutlined as EditIcon, Add as AddIcon } from "@mui/icons-material";
import { useEntityField, Defaults, useAuth } from "@emberly/zenith-client";
import DateAndTimePicker from "../../../../../common/inputs/DateAndTimePicker";
import { makeStyles } from "tss-react/mui";
import moment from "moment/moment";

const useStyles = makeStyles()(
  (theme) => ({
    buttonContainer: {
      textAlign: "right"
    },
    button: {
      padding: "2px",
      width: "22px",
      height: "22px",
      "& svg": {
        fontSize: "18px"
      }
    }
  })
);


export default function StateChange(props) {
  const { classes } = useStyles();
  const { title, path } = props;
  const { t } = useTranslation();
  const { user } = useAuth();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [useCurrentTime, setUseCurrentTime] = useState(false);
  const [selectedTime, setSelectedTime] = useState(false);

  const state = useEntityField(path, Defaults.Null);
  const date = state.value?.lastModified;
  const username = state.value?.lastChangedBy?.name;
  const exists = !!state.value;
  const color = exists ? "textPrimary" : "textSecondary";

  const onDialogConfirm = useCallback(() => {
    setDialogOpen(false);
    const time = moment.utc().toISOString();
    const mTime = useCurrentTime ? time : (selectedTime || time)

    if (exists) {
      state.onChange(null, { ...state.value, lastModified: mTime, lastChangedBy: { id: user.sub, name: user.name } });
    } else {
      state.onChange(null, { created: time, lastModified: mTime, lastChangedBy: { id: user.sub, name: user.name } });
    }
  }, [exists, state, user]);

  const onDialogCancel = useCallback(() => {
    setDialogOpen(false);
  }, [])

  const onDialogDelete = useCallback(() => {
    setDialogOpen(false);
    state.onChange(null, null);
  }, [state])

  const onOpenDialog = useCallback(() => {
    setDialogOpen(true);
    setUseCurrentTime(true);
    if (!!state.value) {
      setSelectedTime(state.value.lastModified);
    }
  }, [state]);

  return (
    <TableRow>

      <TableCell>
        <Typography variant="body2" color={color}>{title}</Typography>
      </TableCell>

      <TableCell>
        <Typography variant="body2" color={color}>{!!date ? moment(date).format("lll") : "-"}</Typography>
      </TableCell>

      <TableCell className={classes.buttonContainer}>
        <IconButton className={classes.button} size="small" color={color} onClick={onOpenDialog}>
          {exists ? <EditIcon /> : <AddIcon />}
        </IconButton>
      </TableCell>

      {
        dialogOpen ? (
          <Dialog
            open
            onClose={onDialogCancel}
          >
            <DialogTitle id="alert-dialog-title">
              {exists ? t("mission:cards:tasks:driver:state:edit") : t("mission:cards:tasks:driver:state:add")}
            </DialogTitle>

            <DialogContent>

              <FormControlLabel
                label={t("mission:cards:tasks:driver:state:setToCurrentTime")}
                control={
                  <Checkbox
                    checked={useCurrentTime}
                    onChange={(_, checked) => setUseCurrentTime(checked)}
                  />
                }
              />

              <DateAndTimePicker
                label={`${title} ${t("mission:cards:tasks:driver:state:datetime")}`}
                value={selectedTime}
                onChange={(ev, v) => setSelectedTime(v)}
                disabled={useCurrentTime}
                includeTime
                fromNow
              />

            </DialogContent>

            <DialogActions>
              {
                exists ? (
                  <Button onClick={onDialogDelete}>
                    {t("mission:cards:tasks:driver:state:delete")}
                  </Button>
                ) : null
              }
              <Button variant={exists ? "outlined" : undefined} onClick={onDialogCancel}>
                {t("mission:cards:tasks:driver:state:cancel")}
              </Button>
              <Button variant="contained" color="primary" autoFocus onClick={onDialogConfirm} disabled={!(useCurrentTime || !!selectedTime)}>
                {exists ? t("mission:cards:tasks:driver:state:confirmEdit") : t("mission:cards:tasks:driver:state:confirmAdd")}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null
      }

    </TableRow>
  );
}
