import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export default function TextDialog(props) {
  const { task, title, description, confirmText, label, defaultValue } = props;
  const { t } = useTranslation();

  const open = !!task && !task.completed;
  const [value, setValue] = useState(defaultValue || "");

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);


  return (
    <Dialog
      open={open}
      onClose={() => task.throw()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>

        <TextField
          style={{ marginTop: "8px" }}
          label={label}
          variant="filled"
          value={value}
          onChange={ev => setValue(ev.target.value)}
          fullWidth
          size="small"
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={() => task.throw("cancelled")}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={() => task.complete(value)} autoFocus disabled={!value}>
          {confirmText || t("common:confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}