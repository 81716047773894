import { FormControl, FormLabel, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { useEffect, useMemo } from "react";
import { useEntities } from "@emberly/zenith-client";


const useStyles = makeStyles()(
  (theme) => ({
    title: {
      marginTop: theme.spacing(0.5)
    },
    formControl: {
      marginTop: theme.spacing(1)
    }
  })
);

export default function IntegrationMappings(props) {
  const { library, mappings, setMappings } = props;
  const entityTypes = useMemo(() => Object.keys(library.mappings), [library]);

  return (
    entityTypes.map(
      (g, i) =>
        <IntegrationTypeMapping library={library} entityType={g} key={i} setMappings={setMappings} mappings={mappings} />
    )
  );
}

function IntegrationTypeMapping(props) {
  const { entityType, library, mappings, setMappings } = props;
  const { t } = useTranslation();
  const { entities } = useEntities(entityType);

  return (
    <Grid item xs={12}>
      <FormLabel>{t(`integrations:library:${library.name}:mappings:${entityType}:title`)}</FormLabel>
      {entities.map(
        (entity, i) =>
          <IntegrationMapping
            key={i}
            entity={entity}
            mappings={mappings}
            setMappings={setMappings}
            mappingKeys={library.mappings[entityType]}
            entityType={entityType}
            library={library}
          />
      )}
    </Grid>
  );
}


function IntegrationMapping(props) {
  const { entity, entityType, library, mappingKeys, mappings, setMappings } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const value = mappings?.[entityType]?.[entity.id]  || "";

  useEffect(() => {
    const defaultValue = library.defaultMappings[entityType](entity);

    if (value === "" && defaultValue !== "") {
      setMappings(
        m =>
        ({
          ...m,
          [entityType]: {
            ...m?.[entityType],
            [entity.id]: defaultValue
          }
        })
      )
    }
  }, [entity, value, library, setMappings, entityType]);


  return (
    <FormControl fullWidth size="small" variant="filled" className={classes.formControl}>
      <InputLabel>{entity.code}. {entity.name}</InputLabel>
      <Select
        fullWidth
        value={value}
        onChange={
          (ev) =>
            setMappings(
              m =>
              ({
                ...m,
                [entityType]: {
                  ...m?.[entityType],
                  [entity.id]: ev.target.value
                }
              })
            )}
      >
        {mappingKeys.map((mappingKey, i) => <MenuItem value={mappingKey} key={i}>{t(`integrations:library:${library.name}:mappings:${entityType}:${mappingKey}`)}</MenuItem>)}
      </Select>
    </FormControl>
  );
}