import { TextField, InputAdornment, IconButton } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import moment from "moment/moment";
import { Today as CalendarIcon } from "@mui/icons-material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      "& ::-webkit-calendar-picker-indicator": {
        display: "none",
        backgroundImage: "none"
      }
    },
    iconButton: {
      marginRight: theme.spacing(-1.5),
    }
  })
);

const INPUT_LABEL_PROPS = {
  shrink: true
};

export default function DateAndTimePicker(props) {
  const { value, onChange, label, fromNow, disabled, includeTime } = props;
  const { classes } = useStyles();
  const ref = useRef();
  const { t } = useTranslation();
  const datetime = moment(value);
  const delta = fromNow && !includeTime && !!value ? ((datetime.endOf("day").valueOf() - Date.now()) / (1000 * 3600)) : 0;

  return (
    <TextField
      ref={ref}
      className={classes.root}
      label={fromNow ? `${label} ${!!value ? `(${includeTime ? datetime.fromNow() : (Math.abs(delta) > 24 ? datetime.endOf("day").fromNow() : (delta > 0 ? t("common:today") : t("common:yesterday")))})` : ""}` : label}
      variant="filled"
      fullWidth
      size="small"
      type={includeTime ? "datetime-local" : "date"}
      value={!!value ? datetime.format(includeTime ? "YYYY-MM-DDTHH:mm" : "YYYY-MM-DD") : ""}
      onChange={ev => {
        onChange(null, !!ev.target.value ? moment(ev.target.value).format() : null)
      }}
      InputLabelProps={INPUT_LABEL_PROPS}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={classes.iconButton}
              onClick={ev => {
                ev.preventDefault();
                ref.current?.querySelectorAll("input")[0]?.showPicker()
              }}
              disabled={disabled}
            >
              <CalendarIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      disabled={disabled}
    />
  );
}
