import { useEffect, useState, useRef } from "react";
import { makeStyles } from "tss-react/mui";
import mapboxgl from "mapbox-gl";
import { useTheme } from "@mui/material";
import Route from "../../../../common/Route";
import { useStation } from "../../../../providers/StationProvider";
import Surface from "../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { GeoJsonPoint, GetBounds } from "../../../../common/maphelpers";
import { getMissionCauseDescription, getMissionJobDescription, getMissionTargetDescription } from "../../../../providers/MissionProvider";
import { AssumeTaskStateEnum, GetTaskDriverState, GetTaskDriverStateColor, GetTaskDriverStateIcon, PaletteColorToHexColor } from "../../../../common/mission";
import { MapIcons } from "../../../../common/constants";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative",
      width: "100%",
      minHeight: "480px",
      height: "78vh",
      "& .map": {
        position: "absolute",
        width: "100%",
        height: "100%",
        borderRadius: theme.spacing(1)
      },
      "& .mapboxgl-popup": {
        maxWidth: "320px",
        "& .mapboxgl-popup-content": {
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1),
          maxWidth: "320px"
        },
        "& strong": {
          ...theme.typography.subtitle2
        },
        "& table": {
          verticalAlign: "top",
          "& tr": {
            verticalAlign: "top",
          },
          "& td": {
            ...theme.typography.body2
          },
          "& td:first-of-type": {
            color: theme.palette.text.secondary
          },
          "& td:last-of-type": {
            color: theme.palette.text.primary,
            paddingLeft: theme.spacing(0.5)
          }
        },

        "& td, strong": {
          fontFamily: "\"IBM Plex Sans\", Arial",
        }
      }
    },
  })
);


export default function HeatmapCard(props) {
  const { missions, data } = props;
  const { classes } = useStyles();
  const containerRef = useRef(null);
  const theme = useTheme()
  const { station } = useStation();
  const [map, setMap] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const zoomSet = useRef(false);

  useEffect(() => {
    const component = containerRef.current;
    if (!component) return;

    component.innerHTML = "";

    const m = new mapboxgl.Map({
      container: component, // container ID
      style: "mapbox://styles/mapbox/dark-v11", // style URL
      center: station?.location?.coordinates, // starting position [lng, lat]
      zoom: 10.5, // starting zoom
      attributionControl: false
    });

    zoomSet.current = false;


    const ro = new ResizeObserver(() => {
      if (m || m.forceKilled !== true) {
        setTimeout(() => {
          try {
            m?.resize();
          } catch { }
        }, 0);
      }
    });

    ro.observe(component);

    m.on("load", () => {

      m.addSource("mission-locations", {
        type: "geojson",
        data: Route.EmptyGeoJson()
      });



      m.addLayer({
        type: "circle",
        source: "mission-locations",
        id: "mission-points",
        minzoom: 14,
        paint: {
          // increase the radius of the circle as the zoom level and dbh value increases
          "circle-radius": {
            property: "dbh",
            type: "exponential",
            stops: [
              [{ zoom: 15, value: 1 }, 5],
              [{ zoom: 15, value: 62 }, 10],
              [{ zoom: 22, value: 1 }, 20],
              [{ zoom: 22, value: 62 }, 50]
            ]
          },
          "circle-color": {
            property: "dbh",
            type: "exponential",
            stops: [
              [0, "rgba(236,222,239,0)"],
              [10, "rgb(236,222,239)"],
              [20, "rgb(208,209,230)"],
              [30, "rgb(166,189,219)"],
              [40, "rgb(103,169,207)"],
              [50, "rgb(28,144,153)"],
              [60, "rgb(1,108,89)"]
            ]
          },
          "circle-stroke-color": "white",
          "circle-stroke-width": 1,
          "circle-opacity": {
            stops: [
              [14, 0],
              [15, 1]
            ]
          }
        }
      }
      );

      m.addLayer({
        type: "heatmap",
        source: "mission-locations",
        id: "mission-heat",
        maxzoom: 15,
        paint: {
          // increase weight as diameter breast height increases
          "heatmap-weight": {
            property: "dbh",
            type: "exponential",
            stops: [
              [1, 0],
              [62, 1]
            ]
          },
          // increase intensity as zoom level increases
          "heatmap-intensity": {
            stops: [
              [11, 1],
              [15, 3]
            ]
          },
          // assign color values be applied to points depending on their density
          "heatmap-color": [
            "interpolate",
            ["linear"],
            ["heatmap-density"],
            0,
            "rgba(33,102,172,0)",
            0.2,
            "rgb(103,169,207)",
            0.4,
            "rgb(209,229,240)",
            0.6,
            "rgb(253,219,199)",
            0.8,
            "rgb(239,138,98)",
            1,
            "rgb(178,24,43)"
          ],
          // increase radius as zoom increases
          "heatmap-radius": {
            stops: [
              [7, 10],
              [11, 16],
              [15, 20],
            ]
          },
          // decrease opacity to transition into the circle layer
          "heatmap-opacity": {
            default: 1,
            stops: [
              [14, 1],
              [15, 0]
            ]
          }
        }
      }
      );

      m.on("click", "mission-points", (e) => {
        const props = e.features[0].properties;
        if (!!props.missionId) {
          navigate(`/missions/${props.missionId}`, { relative: "path" });
        }
      });

      setMap(m);
    });

    return () => {
      m.forceKilled = true;
      setMap(null);
      m?.remove();
      ro?.disconnect();
    };
  }, [theme, station, containerRef, navigate]);

  useEffect(() => {
    if (!map || map.forceKilled) return;

    let points = [];

    for (let i = 0; i < data.length; i++) {
      const location = data[i];

      if (location?.coordinates?.length >= 2) {
        points.push(GeoJsonPoint(
          location.coordinates,
          {
            missionId: location.id,
            dbh: 40
          }
        ));
      }
    }

    map?.getSource("mission-locations")?.setData(Route.GeoJson(points));

    if (points.length !== 0 && !zoomSet.current) {
      zoomSet.current = true;
      const bounds = GetBounds(points);

      if (!!bounds) {
        map?.fitBounds(bounds, { padding: 64, maxZoom: 14 });
      }
    }

  }, [missions, data, map, theme, t]);


  return (
    <Surface xs={12} title={t("statistics:cards:map:title")}>
      <div className={classes.root}>
        <div ref={containerRef} className="map"></div>
      </div>
    </Surface>
  );
}