import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export default function SelectDialog(props) {
  const { task, title, description, confirmText, selectLabel, selectOptions, defaultValue } = props;
  const { t } = useTranslation();

  const open = !!task && !task.completed;
  const [selectValue, setSelectValue] = useState(defaultValue);

  useEffect(() => {
    setSelectValue(defaultValue);
  }, [defaultValue]);


  return (
    <Dialog
      open={open}
      onClose={() => task.throw()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>


        <FormControl fullWidth size="small" variant="filled">
          <InputLabel>{selectLabel}</InputLabel>
          <Select onChange={ev => setSelectValue(ev.target.value)} value={selectValue}>
            {selectOptions.map((option, idx) => <MenuItem selected={option.id === selectValue} value={option.id} key={idx}>{option.displayName}</MenuItem>)}
          </Select>
        </FormControl>

      </DialogContent>
      <DialogActions>
        <Button onClick={() => task.throw("cancelled")}>
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={() => task.complete(selectValue)} autoFocus disabled={selectValue === defaultValue}>
          {confirmText || t("common:confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}