import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      position: "relative",
      width: "100%",
      height: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    }
  })
);

export default function Scrollbar(props) {
  const { className } = props;
  const { classes } = useStyles();

  return (
    <div className={`${classes.root} ${className || ""}`} ref={props.scrollRef}>
      {props.children}
    </div>
  );
}