import { useCallback, useState, useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, IconButton, Typography, Button, Chip, Menu, MenuItem, ListItemText, ListItemIcon, AppBar, Toolbar, Fab } from "@mui/material";
import { ArrowBack as ArrowBackIcon, MoreVert as MoreVertIcon, Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDevice } from "../../../providers/DeviceProvider";

const useStyles = makeStyles()(
  (theme) => ({
    appBar: {
      background: theme.palette.background.default,
      boxShadow: "none",
      transition: "box-shadow 0.25s",
      zIndex: 10
    },
    appBarShadow: {
      boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.10)",
    },
    backButton: {
      marginLeft: theme.spacing(-1),
      marginBottom: theme.spacing(0.5)
    },
    secondaryActionSlimButton: {
      marginLeft: theme.spacing(1),
    },
    secondaryActionButton: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    caption: {
      marginLeft: theme.spacing(1.5),
      "@media (max-width: 1100px)": {
        display: "none"
      }
    },
    chip: {
      marginLeft: theme.spacing(2)
    },
    spaceOnTop: {
      marginTop: theme.spacing(6)
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 9999,
      borderRadius: theme.spacing(1)
    }
  })
);

export default function LargeHeader(props) {
  const { shadow, title, caption, stateTag, stateTagColor, primaryAction, secondaryAction, onPrimaryAction, onSecondaryAction, menuItems, backButton, backPath, actionButton, secondaryActionComponent, secondaryActionIcon, widget } = props;
  const { classes } = useStyles();

  const navigate = useNavigate();
  const goUp = useCallback(() => navigate(backPath || "../", { relative: "path" }), [backPath, navigate]);
  const { slim, setSidebarOpen } = useDevice();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);

  const onMenuOpen = useCallback((ev) => setMenuAnchorEl(ev.currentTarget), []);
  const onMenuClose = useCallback(() => setMenuAnchorEl(null), []);
  const openSidebar = useCallback(() => setSidebarOpen(true), []);

  const renderMenuItems = useMemo(
    () => menuItems?.map(
      (item, key) =>
        <MenuItem onClick={() => (onMenuClose(), item.onClick())} key={key}>
          {item.icon ? (
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
          ) : null}
          <ListItemText>
            {item.label}
          </ListItemText>
        </MenuItem>
    ),
    [menuItems, onMenuClose]
  );

  return slim ? (
    <Grid item xs={12}>
      <AppBar
        color="neutral"
        position="relative"
        className={`${classes.appBar} ${shadow ? classes.appBarShadow : ""}`}
      >
        <Toolbar>
          {
            backButton ? (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={goUp}
              >
                <ArrowBackIcon />
              </IconButton>
            ) : (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={openSidebar}
              >
                <MenuIcon />
              </IconButton>
            )
          }

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>

          {
            primaryAction ? (
              <Button size="small" variant="contained" color="neutral" onClick={onPrimaryAction}>
                {primaryAction}
              </Button>
            ) : null
          }

          {
            secondaryAction ? (
              <Button size="small" className={classes.secondaryActionSlimButton} component={secondaryActionComponent} variant="contained" color="primary" onClick={onSecondaryAction} startIcon={secondaryActionIcon}>
                {secondaryAction}
              </Button>
            ) : null
          }

          {
            !!actionButton ? (
              <Fab onClick={actionButton.onClick} color="primary" className={classes.fab} variant="extended">
                {actionButton.icon}
                {actionButton.text}
              </Fab>
            ) : null
          }

          {widget}

          {!!menuItems ? (
            <>
              <IconButton color="primary" onClick={onMenuOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={onMenuClose}
              >
                {renderMenuItems}
              </Menu>
            </>
          ) : null}

        </Toolbar>
      </AppBar>
    </Grid >

  ) : (
    <Grid item container xs={12} direction="row" alignItems="center" style={{ maxWidth: "100%" }} className={!backButton ? classes.spaceOnTop : undefined}>
      {
        backButton ? (
          <Grid item xs={12}>
            <IconButton color="primary" className={classes.backButton} onClick={goUp}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
        ) : null
      }

      <Grid item xs container direction="row" alignItems="center" >
        <Typography variant="h4">{title}</Typography>
        {
          !!stateTag ? (
            <Chip className={classes.chip} label={stateTag} color={stateTagColor || undefined} />
          ) : null
        }
        {
          !!caption ? (
            <Typography variant="body2" color="textSecondary" className={classes.caption}>{caption}</Typography>
          ) : null
        }
      </Grid>

      <Grid item>

        {
          primaryAction ? (
            <Button size="medium" variant="contained" color="neutral" onClick={onPrimaryAction}>
              {primaryAction}
            </Button>
          ) : null
        }

        {
          secondaryAction ? (
            <Button size="medium" className={classes.secondaryActionButton} variant="contained" color="primary" onClick={onSecondaryAction} component={secondaryActionComponent} startIcon={secondaryActionIcon}>
              {secondaryAction}
            </Button>
          ) : null
        }

        {
          actionButton ? (
            <Button size="medium" className={classes.secondaryActionSlimButton} variant="contained" color="primary" onClick={actionButton.onClick} startIcon={actionButton.icon}>
              {actionButton.text}
            </Button>
          ) : null
        }

        {widget}

        {!!menuItems ? (
          <>
            <IconButton color="primary" onClick={onMenuOpen}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={onMenuClose}
            >
              {renderMenuItems}
            </Menu>
          </>
        ) : null}
      </Grid>

    </Grid>
  );
}