import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEntityById, Compare, useEntities, useEntityFunctions, useMutateState, Task, useAuth } from "@emberly/zenith-client";
import { useStation } from "../providers/StationProvider";
import { useOrdersWithMissions } from "./useOrdersWithMissions";
import { OrderEnums } from "../common/constants";
import { BuildMutations, GenerateInvoiceExportData } from "../common/invoicejournal";
import { validateExportSettings } from "../common/exportformats/helpers";
import { useMission } from "../providers/MissionProvider";
import { useOrder } from "../providers/OrderProvider";

const ORDERS_QUERY = {
  path: "payment.invoice.state",
  comparer: Compare.EQ,
  value: OrderEnums.InvoiceState.Queued,
  name: "ready_invoice_orders"
};

export function useInvoiceExport() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { orgId, priceUnit } = useStation();
 
  const { orders, missions, relatedOrders, showLoading: ordersLoading } = useOrdersWithMissions(ORDERS_QUERY);
  const { entity: exportSettings, loading: loadingExportSettings } = useEntityById("ExportConfig", orgId, !!orgId, true);
  const { entities: productGroups, loading: loadingProductGroups } = useEntities("ProductGroup");
  const { entities: vatCodes, loading: loadingVatCodes } = useEntities("VatCode");
  const { createEntity: createExportEntity } = useEntityFunctions("InvoiceExport");

  const mutateState = useMutateState();
  const [working, setWorking] = useState(false);
  const [exportAction, setExportAction] = useState(null);

  const exportAll = useCallback(async (selectionModel) => {
    try {
      setWorking(true);
      const task = new Task();
      setExportAction(task);
      await task.wait();

      const selectedOrders = orders.filter(t => !!selectionModel.find(s => s === t.id));
      const selectedMissions = missions.filter(t => !!selectedOrders.find(s => s.missionId === t.id));

      const { exportId, exportEntityData, exportEntityUpdate, orderUpdates, errorCode, journalItems } = GenerateInvoiceExportData(
        selectedOrders, 
        relatedOrders, 
        selectedMissions, 
        exportSettings, 
        productGroups, 
        vatCodes, 
        user, 
        priceUnit, 
        t
      );

      if (!!errorCode) {
        return { success: false, exportId, errorCode };
      }

      // prepare export entity
      const exportEntity = await createExportEntity(exportEntityData);

      if (!exportEntity) {
        return { success: false, exportId, errorCode: 0 };
      }

      // map export number to mutations
      const mutations = BuildMutations(exportEntity, exportEntityUpdate, journalItems, orderUpdates);

      // transmit mutations
      const success = await mutateState(mutations);

      return { success, exportId, errorCode };

    } catch (err) {
      console.log(err);
      return { success: false, exportId: null };
    } finally {
      setWorking(false);
    }
  }, [orders, mutateState, vatCodes, productGroups, t, exportSettings, user, priceUnit, relatedOrders, createExportEntity, missions]);

  const loading = ordersLoading || loadingExportSettings || loadingProductGroups || loadingVatCodes;

  return {
    exportAll,
    working,
    orders,
    loading,
    setExportAction,
    exportAction,
    hasExportSettings: validateExportSettings(exportSettings)
  };
}




export function useInvoiceOrderExport() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { orgId, priceUnit } = useStation();
  const { entity: exportSettings, loading: loadingExportSettings } = useEntityById("ExportConfig", orgId, !!orgId, true);
  const { entities: productGroups, loading: loadingProductGroups } = useEntities("ProductGroup");
  const { entities: vatCodes, loading: loadingVatCodes } = useEntities("VatCode");
  const { createEntity: createExportEntity } = useEntityFunctions("InvoiceExport");

  const mutateState = useMutateState();
  const [working, setWorking] = useState(false);
  const [exportAction, setExportAction] = useState(null);

  const { mission, orders } = useMission();
  const { order } = useOrder();

  const loading = loadingExportSettings || loadingProductGroups || loadingVatCodes;

  const exportOrder = useCallback(async () => {
    try {
      setWorking(true);
      const task = new Task();
      setExportAction(task);
      await task.wait();

      const { exportId, exportEntityData, exportEntityUpdate, orderUpdates, errorCode, journalItems } = GenerateInvoiceExportData([order], orders, [mission], exportSettings, productGroups, vatCodes, user, priceUnit, t);

      if (!!errorCode) {
        return { success: false, exportId, errorCode };
      }

      // prepare export entity
      const exportEntity = await createExportEntity(exportEntityData);

      if (!exportEntity) {
        return { success: false, exportId, errorCode: 0 };
      }

      // map export number to mutations
      const mutations = BuildMutations(exportEntity, exportEntityUpdate, journalItems, orderUpdates);

      // transmit mutations
      const success = await mutateState(mutations);

      return { success, exportId, errorCode };

    } catch (err) {
      console.log(err);
      return { success: false, exportId: null };
    } finally {
      setWorking(false);
    }
  }, [order, orders, mutateState, vatCodes, productGroups, t, exportSettings, user, priceUnit, createExportEntity, mission]);

  return {
    exportOrder,
    working,
    orders,
    loading,
    setExportAction,
    exportAction,
    hasExportSettings: validateExportSettings(exportSettings),
  };
}
