import { useState, useCallback, useMemo, useEffect } from "react";
import { Grid } from "@mui/material";
import { ToggleSurface } from "../core/Surface";
import { makeStyles } from "tss-react/mui";
import { DataGridPro, nbNO, enUS, getGridStringOperators, getGridDateOperators, getGridBooleanOperators, getGridNumericOperators, getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import { useEntities } from "@emberly/zenith-client";
import { Add as AddIcon } from "@mui/icons-material";
import EntityEditDialog from "../inputs/EntityEditDialog";
import { useTranslation } from "react-i18next";
import EntityTableToolbar from "./EntityTableToolbar";
import NoRowsOverlay from "./NoRowsOverlay";
import { useResponsivePage } from "../core/ResponsivePage";
import { transpile } from "../../../common/filtertranspiler";
import { usePersistentFilter } from "../../../hooks/usePersistentFilter";

const useStyles = makeStyles()(
  (theme) => ({
    title: {
      marginBottom: theme.spacing(3)
    },
    gridInnerContainer: {
      flexGrow: 1,
      height: "auto",
      "& .MuiDataGrid-virtualScrollerContent": {
        minHeight: "240px !important"
      }
    },
    table: {
      border: "none",
      minHeight: "100%",
      "& .MuiDataGrid-cell:focus": {
        outline: "none"
      },
      "& ::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "& ::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.25)",
      },
      "& .MuiDataGrid-cell--editable:hover, .MuiDataGrid-cell--editing": {
        position: "relative",
        outline: "none !important",
        background: `none !important`,
        boxShadow: "none !important",
        "&.MuiDataGrid-cell:before": {
          zIndex: -1,
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          left: 0,
          borderRadius: "8px",
          background: theme.palette.common.grey.input,
          content: "''",
          display: "block",
          pointerEvents: "none"
        },
      },
    },
    tableRowHover: {
      "& .MuiDataGrid-row": {
        "&:hover": {
          background: theme.palette.action.hover
        }
      },
    },
    tabs: {
      marginBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  })
);

const PAGESIZE = 25;

const INITIAL_STATE = {
  pagination: {
    pageSize: PAGESIZE,
  },
}

const COMPONENTS = { Toolbar: EntityTableToolbar, NoRowsOverlay: NoRowsOverlay };

const SX = {
  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "2px" },
  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": { py: "8px" },
  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": { py: "12px" },
};

const EDITABLE_PROPERTY = { newEditingApi: true };

const autoHeight = () => "auto";

export default function EntityTable(props) {
  const { className, tableId, xs, sm, md, lg, xl, xxl, title, columns, type, fields, emptyStateTitle, emptyStateDescription, createTitle, editTitle, filter, onRowClick, createButton, onCreated, editable, processRowUpdate, disableRowHover, serverSidePaging, sortDescendingByDefault } = props; // TODO
  const { classes } = useStyles();

  const [page, setPage] = useState(serverSidePaging ? 0 : -1);
  const [pageSize, setPageSize] = useState(PAGESIZE);

  const {
    sortModel,
    filterOverride,
    setSortModel,
    setFilterOverride,
    onReset,
    isPinned,
    visibleColumns,
    setVisibleColumns,
    pinnedColumns,
    setPinnedColumns,
    isCurrent
  } = usePersistentFilter(tableId);

  const sortByPath = sortModel.length === 1 ? sortModel[0].field : null;
  const sortDescending = sortModel.length === 1 ? sortModel[0].sort !== "asc" : sortDescendingByDefault;

  const { t, i18n } = useTranslation();

  const filterQuery = useMemo(() => {
    if (serverSidePaging && !!filterOverride?.items?.length) {
      return transpile(columns, filterOverride, filter);
    } else {
      return filter;
    }
  }, [filter, filterOverride, serverSidePaging, columns]);

  const { createEntity, deleteEntity, updateEntity, entities, loading, totalCount } = useEntities(type, filterQuery, isCurrent, page, pageSize, sortByPath, sortDescending);
  
  const [dialog, setDialog] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);

  const showLoader = loading && entities.length === 0;

  const onHandleRowClick = useCallback((params, ev) => {
    if (window.getSelection()?.type !== "Range") {
      ev.preventDefault();
      if (!!onRowClick) {
        onRowClick(params, ev);
      } else {
        setSelectedEntity(entities.find(t => t.id === params.id));
        setDialog(true);
      }
    }
  }, [entities, onRowClick]);

  const onDialogClose = useCallback(() => {
    setDialog(null);
  }, []);

  const onCreateBegin = useCallback(() => {
    setSelectedEntity(null);
    setDialog(true);
  }, []);

  const onCreateEntity = useCallback(async (values) => {
    setUpdating(true);
    const e = await createEntity(values);
    setSelectedEntity(null);
    setDialog(null);
    setUpdating(false);
    if (typeof onCreated === "function") {
      onCreated(e);
    }
  }, [createEntity, onCreated]);

  const { setActionButton } = useResponsivePage();

  useEffect(() => {
    if (!!createButton) {
      setActionButton({
        text: t("entityTable:add"),
        icon: <AddIcon />,
        onClick: () => {
          setSelectedEntity(null);
          setDialog(true);
        }
      });

      return () => {
        setActionButton(null);
      };
    }
  }, [createButton, setActionButton, t]);

  const onUpdateEntity = useCallback(async (values, updatedFields) => {
    setUpdating(true);
    await updateEntity(values, updatedFields);
    setSelectedEntity(null);
    setDialog(null);
    setUpdating(false);
  }, [updateEntity]);

  const onDeleteEntity = useCallback(async () => {
    setUpdating(true);
    await deleteEntity(selectedEntity.id);
    setSelectedEntity(null);
    setDialog(null);
    setUpdating(false);
  }, [selectedEntity, deleteEntity]);

  const renderColumns = useMemo(() => {
    if (!serverSidePaging) return columns;
    return columns.map(column => ({ ...column, filterOperators: GetFilterOperator(column) }));
  }, [columns, serverSidePaging]);

  const onSortModelChange = useCallback((sortModel) => {
    setSortModel(sortModel.length > 0 ? [sortModel[0]] : []);
  }, [setSortModel]);

  return (
    <ToggleSurface
      xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}
      outerClassName={className}
      title={title}
    >

      <Grid item xs={12} container>
        <div className={classes.gridInnerContainer}>
          <DataGridPro
            disableColumnReorder
            sx={SX}
            className={`${classes.table} ${disableRowHover ? "" : classes.tableRowHover}`}
            rows={entities}
            loading={showLoader}
            columns={renderColumns}
            getRowHeight={autoHeight}
            onRowClick={onHandleRowClick}
            initialState={INITIAL_STATE}
            rowsPerPageOptions={useMemo(() => entities.length < PAGESIZE ? [Math.max(entities.length, 1), PAGESIZE, 50, 100, 150] : [PAGESIZE, 50, 100, 150], [entities.length])}
            disableSelectionOnClick
            autoHeight
            paginationMode={serverSidePaging ? "server" : "client"}
            pagination
            filterModel={!!filterOverride ? filterOverride : undefined}
            filterMode={serverSidePaging ? "server" : "client"}
            onFilterModelChange={setFilterOverride}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            onPageChange={serverSidePaging ? setPage : undefined}
            onPageSizeChange={serverSidePaging ? setPageSize : undefined}
            rowCount={serverSidePaging ? Math.max(totalCount, 0) : undefined}
            columnVisibilityModel={visibleColumns}
            onColumnVisibilityModelChange={setVisibleColumns}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={setPinnedColumns}
            components={COMPONENTS}
            componentsProps={{
              noRowsOverlay: {
                onCreateBegin,
                title: emptyStateTitle,
                description: emptyStateDescription,
                canCreate: !!createButton,
                button: t("entityTable:add")
              },
              toolbar: {
                canSearch: !serverSidePaging,
                isPinned,
                tableId,
                onReset,
              }
            }}
            localeText={(i18n.language === "no" ? nbNO : enUS)?.components.MuiDataGrid.defaultProps.localeText}
            experimentalFeatures={editable ? EDITABLE_PROPERTY : undefined}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={console.log}
          />
        </div>
      </Grid>

      {
        dialog ? (
          <EntityEditDialog
            createTitle={createTitle}
            editTitle={editTitle}
            canDelete={!!selectedEntity}
            entity={selectedEntity}
            updating={updating}
            fields={fields}
            open={dialog}
            onCancel={onDialogClose}
            onCreate={onCreateEntity}
            onUpdate={onUpdateEntity}
            onDelete={onDeleteEntity}
          />
        ) : null
      }

    </ToggleSurface>
  );
}

function GetFilterOperator(column) {
  switch (column.type) {

    case "boolean":
      return getGridBooleanOperators();

    case "date":
      return getGridDateOperators();

    case "dateTime":
      return getGridDateOperators(true);

    case "number":
      return getGridNumericOperators();

    case "singleSelect":
      return getGridSingleSelectOperators();

    case "string":
    default:
      return getGridStringOperators();
  }
}