import { makeStyles } from "tss-react/mui";
import { Grid, Tabs, Tab } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import RequestedPaymentsSection from "./subpages/RequestedPaymentsSection";
import CashOrdersReadyForJournalingSection from "./subpages/CashOrdersReadyForJournalingSection";
import CashJournalsSection from "./subpages/CashJournalsSection";
import CashJournalEntriesSection from "./subpages/CashJournalEntriesSection";

const useStyles = makeStyles()(
  (theme) => ({
    tabs: {
      marginBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.palette.divider}`
    },
  })
);

export default function CashOrdersPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { tabId } = useParams();

  const navigate = useNavigate();
  const onTabChange = useCallback((_, val) => navigate(!!tabId ? `../${val}` : `./${val}`, { relative: "path", replace: true }), [navigate, tabId]);

  return (
    <ResponsivePage
      title={t("cashOrders:title")}
      xs={12}
      sm={12}
      lg={12}
      xl={12}
      xxl={10}
    >

      <Grid item xs={12}>
        <Tabs className={classes.tabs} value={tabId || "requested"} onChange={onTabChange}>
          <Tab value="requested" label={t("cashOrders:paymentRequestedTab")} />
          <Tab value="ready" label={t("cashOrders:readyForJournalingTab")} />
          <Tab value="journals" label={t("cashOrders:journalsTab")} />
          <Tab value="journalentries" label={t("cashOrders:journalEntriesTab")} />
        </Tabs>
      </Grid>

      {
        !tabId || tabId === "requested" ? (
          <RequestedPaymentsSection />
        ) : tabId === "ready" ? (
          <CashOrdersReadyForJournalingSection />
        ) : tabId === "journals" ? (
          <CashJournalsSection />
        ) : tabId === "journalentries" ? (
          <CashJournalEntriesSection />
        ) : null
      }

    </ResponsivePage>
  );
}

