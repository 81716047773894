import { createContext, useCallback, useContext, useMemo } from "react";
import { useEntityById, useEntities, useMutateState, Compare, useAuth } from "@emberly/zenith-client";
import { MissionEnums, TaskEnums } from "../common/constants";
import { useStation } from "./StationProvider";
import { MapMissionTasks } from "../common/mission";
import moment from "moment/moment";

const ACTIVE_VEHICLES_QUERY = {
  path: "active",
  comparer: Compare.EQ,
  value: true,
  name: "active_vehicles_filter"
};

export const DriverContext = createContext();
export const useDriver = () => useContext(DriverContext);

export const getDriverId = (user) => {
  return !!user ? `${user.org_id}|${user.sub}` : null;
};

export const DriverProvider = (props) => {
  const { user } = useAuth();
  const { isDriver } = useStation();
  const driverId = getDriverId(user);
  const { entity: driver, updateEntityField } = useEntityById("Driver", driverId, !!user && isDriver, !!user && isDriver);
  const { entities: vehicles } = useEntities("ServiceVehicle", ACTIVE_VEHICLES_QUERY, isDriver);

  const myMissionsQuery = useMemo(() => {
    if (!driverId) return null;

    return {
      name: "my_missions_filter",
      and: [
        {
          path: "state",
          value: MissionEnums.State.Created,
          comparer: Compare.EQ
        },
        {
          path: "salvageTasks.execution.driver.id",
          value: driverId,
          comparer: Compare.EQ
        }
      ]
    };
  }, [driverId]);

  const moveVehicle = useMoveVehicleToDriver();

  const setVehicle = useCallback((vehicle) => {
    moveVehicle(driver, vehicle);
  }, [driver, moveVehicle]);


  const setActive = useCallback((active) => updateEntityField("active", active), [updateEntityField]);

  const { entities: myMissions, updateEntity } = useEntities("Mission", myMissionsQuery, !!myMissionsQuery && isDriver);

  const { activeMissions, completedMissions } = useMemo(() => {
    const tasks = myMissions.flatMap(mission => MapMissionTasks(mission)).filter(t => t.execution?.driver?.id === driverId);
    const startOfDay = moment().startOf("day").toDate();
    return {
      activeMissions: tasks.filter(t => t.state < TaskEnums.State.Completed || t.execution.driverAssignedState === TaskEnums.DriverAssignedState.Pending),
      completedMissions: tasks.filter(t => t.state >= TaskEnums.State.Completed && moment(t.execution.history?.completed?.created).toDate() >= startOfDay)
    };
  }, [myMissions, driverId]);

  return (
    <DriverContext.Provider
      value={{
        id: driverId,
        driver,
        vehicles,
        active: driver?.active || false,
        setActive,
        vehicle: driver?.vehicle,
        setVehicle,
        activeMissions,
        completedMissions,
        updateEntity
      }}
    >
      {props.children}
    </DriverContext.Provider>
  );
};



export function useMoveVehicleToDriver() {
  const mutateState = useMutateState();

  return useCallback(async (driver, vehicle) => {
    let driverUpdates = [];
    let vehicleUpdates = [];

    // should set vehicle to the given driver
    if (!!driver) {
      driverUpdates.push({
        data: {
          id: driver.id,
          vehicle: {
            name: vehicle?.title || "",
            id: vehicle?.id || ""
          }
        },
        fields: ["vehicle"]
      });
    }

    // should set driver to the current vehicle
    if (!!vehicle) {
      vehicleUpdates.push({
        data: {
          id: vehicle.id,
          driver: {
            id: driver?.id || "",
            name: driver?.name || ""
          }
        },
        fields: ["driver"]
      });
    }

    // should unset the driver from the drivers old vehicle
    if (!!driver?.vehicle?.id && driver.vehicle.id !== vehicle?.id) {
      vehicleUpdates.push({
        data: {
          id: driver.vehicle.id,
          driver: { id: "", name: "" }
        },
        fields: ["driver"]
      });
    }

    // should unset the vehicle on the vehicles previous driver
    if (!!vehicle?.driver?.id && vehicle.driver.id !== driver.id) {
      driverUpdates.push({
        data: {
          id: vehicle.driver.id,
          vehicle: { id: "", name: "" }
        },
        fields: ["vehicle"]
      });
    }

    // construct final mutations object
    let mutations = [];

    if (driverUpdates.length !== 0) {
      mutations.push({
        type: "Driver",
        update: driverUpdates
      })
    }

    if (vehicleUpdates.length !== 0) {
      mutations.push({
        type: "ServiceVehicle",
        update: vehicleUpdates
      })
    }

    return await mutateState(mutations);
  }, [mutateState]);
}