import { useState, useCallback } from "react";
import { Grid, Checkbox, FormControlLabel, Collapse, Button, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import SurfaceSection from "../../../../common/core/SurfaceSection";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import ContactSection from "./ContactSection";
import ContactPersonPicker from "../../../../common/inputs/ContactPersonPicker";
import CollapseGridItem from "../../../../common/core/CollapseGridItem";


export default function ActorsDetailsSection() {
  const { t } = useTranslation();

  const ownerNotRequester = useEntityField("actors.ownerIsNotRequester", Defaults.Boolean);
  const hasContactPerson = useEntityField("actors.hasContactPerson", Defaults.Boolean);
  const ownerContactId = useEntityField("actors.owner.id", Defaults.String);
  const requesterContactId = useEntityField("actors.requester.id", Defaults.String);
  const contactPersonName = useEntityField("actors.contactPerson.name", Defaults.String);
  const contactPersonPhoneNumber = useEntityField("actors.contactPerson.phoneNumber", Defaults.String);
  const contactPersonId = useEntityField("actors.contactPerson.id", Defaults.String);
  const [contactPerson, setContactPerson] = useState({ id: contactPersonId.value, name: contactPersonName.value });

  const onContactPersonChanged = useCallback((name, contact) => {
    if (!!contact) {
      contactPersonName.onChange(null, contact.name)
      contactPersonPhoneNumber.onChange(null, contact.phoneNumber);
      contactPersonId.onChange(null, contact.id);
      setContactPerson(contact);
    } else {
      if (contactPersonId.value && contactPersonName.value !== name) {
        contactPersonId.onChange(null, "");
      }

      contactPersonName.onChange(null, name)
      setContactPerson(null);
    }
  }, [contactPersonName, contactPersonId, contactPersonPhoneNumber]);

  return (
    <SurfaceSection title={ownerNotRequester.value ? t("mission:cards:actors:actors:owner") : `${t("mission:cards:actors:actors:owner")}/${t("mission:cards:actors:actors:requester")}`}>

      <ContactSection path="actors.owner" />

      <Grid item xs={12}>
        <FormControlLabel
          label={t("mission:cards:actors:actors:ownerNotRequester")}
          control={<Checkbox size="small" checked={ownerNotRequester.value} onChange={ownerNotRequester.onChange} />}
        />
      </Grid>

      <CollapseGridItem xs={12} in={ownerNotRequester.value}>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <Typography variant="h6">
              {t("mission:cards:actors:actors:requester")}
            </Typography>
          </Grid>

          {
            !!ownerNotRequester.value ? (
              <ContactSection path="actors.requester" />
            ) : null
          }

        </Grid>
      </CollapseGridItem>

      <Grid item xs={12}>
        <Collapse in={!hasContactPerson.value}>
          <Button variant="outlined" fullWidth onClick={() => hasContactPerson.onChange(null, true)}>{t("mission:cards:actors:actors:addContactPerson")}</Button>
        </Collapse>

        <Collapse in={hasContactPerson.value}>
          <Grid container spacing={1}>

            <Grid item xs={12}>
              <Typography variant="subtitle1">{t("mission:cards:actors:actors:contactPerson")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <ContactPersonPicker
                label={t("mission:cards:actors:actors:name")}
                contactId={ownerNotRequester.value ? requesterContactId.value : ownerContactId.value}
                load={hasContactPerson.value}
                value={contactPerson}
                onChange={onContactPersonChanged}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label={t("mission:cards:actors:actors:phoneNumber")}
                variant="filled"
                fullWidth
                size="small"
                onChange={contactPersonPhoneNumber.onChange}
                value={contactPersonPhoneNumber.value}
              />
            </Grid>

          </Grid>
        </Collapse>

      </Grid>

    </SurfaceSection>
  );
}



