import { createContext, useContext } from "react";
import { useEntity } from "@emberly/zenith-client";
import { CanChangePaymentMethod } from "../common/orders";

export const OrderContext = createContext();
export const useOrder = () => useContext(OrderContext);

export const OrderProvider = (props) => {
  const { entity: order, updateEntityField, deferUpdateEntityField } = useEntity()
  
  const open = CanChangePaymentMethod(order.payment);

  return (
    <OrderContext.Provider
      value={{
        order,
        open,
        updateOrderField: updateEntityField,
        deferUpdateOrderField: deferUpdateEntityField
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
};

