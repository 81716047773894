import { makeStyles } from "tss-react/mui";
import { Grid, Button, Autocomplete, TextField, IconButton, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, LinearProgress } from "@mui/material";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { PinDropOutlined as PinDropIcon, PinDropRounded as PinDropSelectedIcon, LocationOn as LocationOnIcon, DragHandle as DragHandleIcon, Person as PersonIcon } from "@mui/icons-material";
import { useStation } from "../../../providers/StationProvider";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSearch, Location } from "@emberly/zenith-client";
import { FormatLocation, MapboxPickerToLocation, MapboxQueryToLocation } from "../../../common/maphelpers";
import { getTextSearch } from "../../../common/search";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      background: theme.palette.common.grey.input,
      position: "relative",
      borderRadius: "8px",
      "& .MuiInputBase-root": {
        backgroundColor: "transparent !important"
      },
      "&:hover": {
        "&:after": {
          zIndex: 3,
          position: "absolute",
          height: "100%",
          width: "100%",
          right: 0,
          background: theme.palette.action.hover,
          borderRadius: "8px",
          content: "''",
          display: "block",
          pointerEvents: "none"
        },
        "& .MuiInputBase-root": {
          backgroundColor: "transparent !important"
        }
      }
    },
    input: {
      background: "none !important",
    },
    pickerButton: {
      marginRight: theme.spacing(1)
    },
    pickerActions: {
      padding: theme.spacing(3)
    },
    mapContainer: {
      position: "relative",
      height: "70vh",
      maxHeight: "700px",
      width: "100%",
      marginTop: theme.spacing(2),
      "& .map": {
        width: `calc(100% + ${theme.spacing(6)})`,
        height: "100%",
        marginLeft: theme.spacing(-3),
        marginRight: theme.spacing(-3)
      }
    },
    listbox: {
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    }
  })
);

export default function LocationPicker(props) {
  const { picker, draggable, label, value, onChange, dragHandleProps, disabled, enableContactSearching, freeSolo } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  // use station coordinates, from context, for search bias, and map init

  const [option, setOption] = useState(value || null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const { station } = useStation();
  const mapRef = useRef(null);

  // Dialog
  const [pickerOpen, setPickerOpen] = useState(false);
  const [pickerCoordinates, setPickerCoordinates] = useState(null);
  const [pickerLoading, setPickerLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);

  const filterQuery = useMemo(() => {
    return getTextSearch("name", searchQuery);
  }, [searchQuery]);

  const onPopupClose = useCallback(() => setPopupOpen(false), []);

  const onPopupOpen = useCallback(() => {
    setSearchQuery(inputValue);
    setPopupOpen(true);
  }, [inputValue]);

  useEffect(() => {
    setOption(value);
  }, [value, station]);

  const { entities: contacts } = useSearch("Contact", filterQuery, popupOpen && enableContactSearching);
  
  useEffect(() => {
    let alive = true;
    const timer = setTimeout(() => {

      if (popupOpen) {
        setSearchQuery(inputValue);
      }

      if (!!inputValue && option?.title !== inputValue && popupOpen) {


        // TODO session token and proximity and language, used to be "en"
        axios(`https://api.mapbox.com/search/searchbox/v1/suggest?q=${inputValue}&language=no&country=no%2Cse%2Cdk&proximity=ip&session_token=${"test"}&access_token=${mapboxgl.accessToken}`)
          .then((res) => {
            const { suggestions } = res.data;
            // todo might need to do something with attribution here, from res.dat
            if (!alive) return;

            // TODO process data
            setOptions(suggestions.filter(t => t.feature_type === "poi" || t.feature_type === "address" || t.feature_type === "street").map(t => ({
              title: t.name,
              address: t.full_address,
              mapboxId: t.mapbox_id,
              type: t.feature_type,
              isContact: false
            })));

          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }, 300);

    return () => {
      clearTimeout(timer);
      alive = false;
    };
  }, [inputValue, option, enableContactSearching, station, popupOpen]);

  const renderedOptions = useMemo(() => {
    if (enableContactSearching) {
      const contactOptions = contacts
        .filter(t => !!t.location && t.location?.coordinates?.length >= 2)
        .map(t => ({
          id: t.location.id,
          mapboxId: t.location.mapboxId,
          coordinates: t.location.coordinates,
          place: t.location.place,
          address: t.location.address,
          region: t.location.region,
          postCode: t.location.postCode,
          country: t.location.country,
          title: t.name || t.location.title,
          type: t.location.type,
          isContact: true
        }));

      const set = new Set(contactOptions.map(t => t.mapboxId));
      return contactOptions.concat(options.filter(t => !set.has(t.mapboxId)))
    } else {
      return options;
    }
  }, [options, contacts, enableContactSearching]);

  const onOptionSelected = useCallback((ev, option) => {
    if (!!option) {
      setOption(option);
      // TODO session token

      if (option.isContact) {
        onChange(new Location({ ...option }));
      } else {
        axios(`https://api.mapbox.com/search/searchbox/v1/retrieve/${option.mapboxId}?session_token=${"test"}&access_token=${mapboxgl.accessToken}`)
          .then(res => {
            const feature = res.data?.features[0];
            onChange(MapboxQueryToLocation(option, feature));
          })
          .catch(err => {
            console.log(err, err);
          });
      }

    } else {
      onChange(null);
    }

  }, [onChange]);


  const onMapMounted = useCallback((component) => {
    if (!component) return;

    component.innerHTML = "";
    const hasExistingCoords = !!(value?.coordinates || station?.location?.coordinates);

    const map = new mapboxgl.Map({
      container: component, // container ID
      style: "mapbox://styles/mapbox/streets-v12", // style URL
      center: hasExistingCoords ? (value?.coordinates || station?.location?.coordinates) : [8.2, 60], // starting position [lng, lat]
      zoom: hasExistingCoords ? 12 : 6, // starting zoom
      attributionControl: false
    });

    mapRef.current = map;
    let marker = new mapboxgl.Marker();
    let markerAdded = false;
    setPickerCoordinates(null);

    map.on("load", () => {
      // Add features
      if (!!value?.coordinates) {
        marker.setLngLat(value.coordinates);

        if (!markerAdded) {
          markerAdded = true;
          marker.addTo(map);
        }
      }
    });

    map.on("click", (ev) => {
      const { lat, lng } = ev.lngLat;

      setPickerCoordinates([lng, lat]);
      marker.setLngLat([lng, lat]);

      if (!markerAdded) {
        markerAdded = true;
        marker.addTo(map);
      }
    });

  }, [value, station]);

  const onPickerClose = useCallback(() => {
    mapRef.current?.remove();
    setPickerOpen(false);
  }, []);

  const onPickerConfirm = useCallback(() => {
    if (!!pickerCoordinates) {
      // try to geocode, show loading the meantime
      const timer = setTimeout(() => setPickerLoading(true), 250);

      axios(`https://api.mapbox.com/geocoding/v5/mapbox.places/${pickerCoordinates[0]},${pickerCoordinates[1]}.json?session_token=${"test"}&access_token=${mapboxgl.accessToken}`)
        .then(res => {
          clearTimeout(timer);
          const { features } = res.data;
          if (features.length > 0) {
            const feature = features[0];
            onChange(MapboxPickerToLocation(pickerCoordinates, feature));
          } else {
            onChange(new Location({
              coordinates: pickerCoordinates,
              title: pickerCoordinates.join(", "),
              address: pickerCoordinates.join(", "),
            }));
          }

          setPickerLoading(false);
          setPickerOpen(false);
        })
        .catch(err => {
          clearTimeout(timer);
          setPickerLoading(false);
          console.log("err", err);
        })
    }
  }, [pickerCoordinates, onChange]);

  return (
    <Grid container item xs direction="row" className={classes.root} justifyContent="flex-start" alignItems="center">

      <Grid item xs>
        <Autocomplete
          getOptionLabel={FormatLocation}
          isOptionEqualToValue={(a, b) => a.mapboxId === b.mapboxId}
          options={renderedOptions}
          onOpen={onPopupOpen}
          onClose={onPopupClose}
          autoComplete
          fullWidth
          freeSolo
          clearOnBlur
          filterOptions={(x) => x}
          includeInputInList
          value={option}
          disabled={disabled}
          noOptionsText={t("common:noResults")}
          onChange={onOptionSelected}
          ListboxProps={{
            className: classes.listbox
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            
            if (freeSolo && event?.type === "change") {

              onChange(new Location({
                address: newInputValue,
              }));
              
            }
          }}
          renderInput={(params) => (
            <TextField
              className={classes.input}
              {...params}
              label={label}
              size="small"
              variant="filled"
              fullWidth
              sx={{
                "&:hover": {
                  backgroundColor: "transparent"
                }
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.mapboxId}>
                <Grid container alignItems="center">
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    {option.isContact ? (
                      <PersonIcon sx={{ color: 'text.secondary' }} />
                    ) : (
                      <LocationOnIcon sx={{ color: 'text.secondary' }} />
                    )}
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    <Typography>
                      {option.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {option.address}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      </Grid>

      {
        !!draggable ? (
          <Grid item>
            <IconButton size="small" {...dragHandleProps}>
              <DragHandleIcon />
            </IconButton>
          </Grid>
        ) : null
      }

      {
        !!picker ? (
          <Grid item>
            <IconButton size="small" className={classes.pickerButton} onClick={() => setPickerOpen(true)}>
              { !!value?.coordinates?.length ? <PinDropSelectedIcon /> : <PinDropIcon /> }
            </IconButton>
            {
              pickerOpen ? (
                <Dialog
                  open
                  fullWidth
                  maxWidth="md"
                  onClose={onPickerClose}
                >
                  {pickerLoading ? (
                    <LinearProgress variant="indeterminate" />
                  ) : null}

                  <DialogTitle>
                    {t("common:location:pickerTitle")}
                  </DialogTitle>

                  <DialogContent>

                    <DialogContentText>
                      {t("common:location:pickerDescription")}
                    </DialogContentText>

                    <div className={classes.mapContainer}>
                      <div ref={onMapMounted} className="map"></div>
                    </div>

                  </DialogContent>

                  <DialogActions className={classes.pickerActions}>
                    <Button onClick={onPickerClose} disabled={pickerLoading} size="large">{t("common:cancel")}</Button>
                    <Button disabled={!pickerCoordinates || pickerLoading} onClick={onPickerConfirm} variant="contained" color="primary" size="large">{t("common:confirm")}</Button>
                  </DialogActions>

                </Dialog>
              ) : null
            }
          </Grid>
        ) : null
      }

    </Grid>
  );
}


