export function MakeProductGroup(productGroupId, productGroups, vatCodes) {

  const productGroup = productGroups.find(t => t.id === productGroupId);
  const vatCode = vatCodes.find(t => t.id === productGroup.vatCode.id);

  return {
    id: productGroupId,
    code: productGroup.code,
    name: productGroup.name,
    account: productGroup.account,
    vatCode: {
      id: vatCode.id,
      rate: vatCode.rate,
      name: vatCode.name,
      code: vatCode.code
    }
  };
}