import { useParams } from "react-router-dom";
import { EntityContextProvider } from "@emberly/zenith-client";
import AgreementInformationCard from "./cards/AgreementInformationCard";
import DiscountedProductsCard from "./cards/DiscountedProductsCard";
import AgreementLayout from "./common/AgreementLayout";

export default function AgreementPage() {
  const { agreementId } = useParams();

  return (
    <EntityContextProvider id={agreementId} type="Agreement">
      <AgreementLayout>

        <AgreementInformationCard />

        <DiscountedProductsCard />

      </AgreementLayout>
    </EntityContextProvider>
  );
}
