import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import { WarningAmber as WarningIcon } from "@mui/icons-material";

export default function MissingExportSettingsWarning() {
  const { t } = useTranslation();

  return (
    <Grid item xs={12}>
      <Alert
        color="warning"
        icon={<WarningIcon fontSize="inherit" />}
        action={
          <NavLink to="/station/export">
            <Button>{t("missingExportSettings:action")}</Button>
          </NavLink>
        }
      >
        <AlertTitle>{t("missingExportSettings:title")}</AlertTitle>
        {t("missingExportSettings:description")}
      </Alert>
    </Grid>
  );
}

