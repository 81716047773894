import { IntegrationEnums } from "../../../../common/constants";




export const IntegrationLibrary = new Map([
  [IntegrationEnums.Service.Tripletex, {
    icon: "tripletex.png",
    name: "Tripletex",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://hjelp.tripletex.no/hc/no/articles/16600381679249-Hvordan-oppretter-jeg-API-brukern%C3%B8kkel",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      createProductsIfNotExists: true,
      treatProductsAsProductGroups: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: true,
  }],
  [IntegrationEnums.Service.Duett, {
    icon: "duett.png",
    name: "Duett",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://hjelp.tripletex.no/hc/no/articles/16600381679249-Hvordan-oppretter-jeg-API-brukern%C3%B8kkel",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      createProductsIfNotExists: true,
      treatProductsAsProductGroups: false,
    },
    mappings: {
      VatCode: [
        "vat",
        "food",
        "low",
        "noVat",
      ]
    },
    defaultMappings: {
      VatCode: (entity) => {
        switch (entity.code) {
          case "3": return "vat";
          case "31": return "food";
          case "33": return "low";
          case "5": 
          case "7": return "noVat";
          default: return "";
        }
      }
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: false
  }],
  [IntegrationEnums.Service.PowerOfficeGo, {
    icon: "powerofficego.png",
    name: "PowerOfficeGo",
    category: IntegrationEnums.Category.Accounting,
    instructionsLink: "https://hjelp.tripletex.no/hc/no/articles/16600381679249-Hvordan-oppretter-jeg-API-brukern%C3%B8kkel",
    hasApiKey: true,
    hasUserKey: false,
    settings: {
      createContactsIfNotExists: true,
      createProductsIfNotExists: true,
      treatProductsAsProductGroups: false,
    },
    capabilities: {
      ImportInvoiceOrders: true,
      ImportCashOrders: false,
    },
    available: false
  }],
])
