import { useCallback, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Grid, Button } from "@mui/material";
import { ToggleSurface } from "../../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useEntity, updateFields, EntityFieldTypes } from "@emberly/zenith-client";
import EntityEditDialog from "../../../common/inputs/EntityEditDialog";
import SimpleTable from "../../../common/tables/SimpleTable";
import { MissionEnumsLists } from "../../../../common/constants";

const useStyles = makeStyles()(
  (theme) => ({
    fieldTitle: {
      fontWeight: "600",
    },

  })
);

export default function DiscountedProductsCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entity, updateEntityField } = useEntity();
  const [dialog, setDialog] = useState(false);

  const columns = useMemo(() => {
    return [
      {
        field: "code",
        headerName: t("agreement:discountedProductsCard:fieldCode"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "name",
        headerName: t("agreement:discountedProductsCard:fieldName"),
        flex: 1,
        minWidth: 100,
      },
      {
        field: "price",
        headerName: t("agreement:discountedProductsCard:fieldPrice"),
        valueGetter: params => Number(params.row.price.value),
        renderCell: (params) => {
          return `${params.value.toFixed(2)} ${MissionEnumsLists.CurrencyUnit[params.row.price.currency]}`
        },
        flex: 1,
        minWidth: 100,
      },
    ]
  }, [t]);

  const createFields = useMemo(() => [
    { name: t("agreement:discountedProductsCard:fieldName"), path: "product", type: EntityFieldTypes.Search, entityType: "Product", searchPath: "name", required: true },
    { name: t("agreement:discountedProductsCard:fieldPrice"), path: "price", type: EntityFieldTypes.String, dataType: "number", required: true }, // TODO, transform created and updated data before patching
  ], [t]);

  const updateableFields = useMemo(() => [
    { name: t("agreement:discountedProductsCard:fieldPrice"), path: "price.value", type: EntityFieldTypes.String, dataType: "number", required: true }, // TODO, transform created and updated data before patching
  ], [t]);

  const onEditCancel = useCallback(() => {
    setDialog(false);
  }, []);

  const onCreateConfirm = useCallback((v) => {
    setDialog(false);

    updateEntityField(
      "discountedProducts",
      [
        ...entity.discountedProducts.filter(t => t.id !== v.product.id),
        {
          id: v.product.id,
          code: v.product.code,
          name: v.product.name,
          price: { value: v.price, currency: 1 }
        }
      ]
    );
  }, [updateEntityField, entity]);


  const onUpdateConfirm = useCallback((product, values, updatedFields) => {
    setDialog(false);
    const updatedProduct = { ...updateFields(product, updatedFields, values) };
    const list = [...entity.discountedProducts];
    const idx = list.findIndex(t => t.id === product.id);
    list[idx] = updatedProduct
    updateEntityField("discountedProducts", list);
  }, [updateEntityField, entity]);

  const onDeleteConfirm = useCallback((contact) => {
    setDialog(false);
    updateEntityField("discountedProducts", entity.discountedProducts.filter(t => t.id !== contact.id));
  }, [updateEntityField, entity])

  return (
    <ToggleSurface title={t("agreement:discountedProductsCard:title")} xs={12}>
      <Grid item container spacing={1}>

        <Grid item xs={12}>
          <SimpleTable
            tableId="discounted_products"
            columns={columns}
            rows={entity.discountedProducts || []}
            fields={updateableFields}
            editTitle={t("agreement:discountedProductsCard:edit")}
            onUpdate={onUpdateConfirm}
            onDelete={onDeleteConfirm}
            emptyStateTitle={t("agreement:discountedProductsCard:emptyStateTitle")}
            emptyStateDescription={t("agreement:discountedProductsCard:emptyStateDescription")}
          />
        </Grid>

        <Grid item xs={12} container justifyContent="flex-end">
          <Button variant="contained" color="neutral" size="medium" onClick={() => setDialog(true)}>
            {t("agreement:discountedProductsCard:add")}
          </Button>
        </Grid>

      </Grid>

      {dialog ? (
        <EntityEditDialog
          createTitle={t("agreement:discountedProductsCard:add")}
          fields={createFields}
          open={dialog}
          onCancel={onEditCancel}
          onCreate={onCreateConfirm}
        />
      ) : null}

    </ToggleSurface>
  );
}



