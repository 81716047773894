import { makeStyles } from "tss-react/mui";
import { Typography, Stack, Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useEntities, Compare } from "@emberly/zenith-client";
import { useScrollState } from "../../../../hooks/useScrollState";
import ActivityLogItem from "./ActivityLogItem";
import { GetCategoryEnum } from "../../../../common/activity";
import { ActivityEnumsLists } from "../../../../common/constants";

const useStyles = makeStyles()(
  (theme) => ({
    list: {
      position: "relative",
      width: "100%",
      overflow: "auto",
      flexGrow: 1,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: "100%",
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    },
    loaderContainer: {
      display: "flex",
      padding: theme.spacing(2),
      justifyContent: "center"
    },
    emptyStateContainer: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      height: "calc(100% - 64px)",
      padding: theme.spacing(2),
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    }
  })
);

export default function ActivityLog(props) {
  const { resourceId, categories } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { scrollRef, scrollTop, scrollBottom } = useScrollState(true);
  const [size, setSize] = useState(15);

  const filter = useMemo(() => !!resourceId ? {
    path: "resourceId",
    comparer: Compare.EQ,
    value: resourceId,
  } : categories?.length > 0 && categories?.length < ActivityEnumsLists.Category.length ? {
    or: categories.map(category => ({
      path: "activityCategory",
      comparer: Compare.EQ,
      value: GetCategoryEnum(category)
    }))
  } : null, [resourceId, categories]);

  const { entities, loading, totalCount } = useEntities("ActivityLog", filter, true, 0, size, null, true);

  useEffect(() => {
    if (scrollBottom && !scrollTop && !loading && totalCount) {
      setSize(s => Math.min(totalCount, s + 5));
    }
  }, [scrollBottom, loading, scrollTop, totalCount]);

  return (
    <Stack className={classes.list} ref={scrollRef}>
      {entities.map((item, key) => (
        <ActivityLogItem item={item} key={key} />
      ))}
      {loading ? (
        <Box className={classes.loaderContainer}>
          <CircularProgress variant="indeterminate" size="32px" />
        </Box>
      ) : entities.length === 0 ? (
        <Box className={classes.emptyStateContainer} >
          <Typography textAlign="center" variant="subtitle1">{t("activityLog:emptyStateTitle")}</Typography>
          <Typography color="textSecondary" textAlign="center" variant="body2">{t("activityLog:emptyStateDescription")}</Typography>
        </Box>
      ) : null}
    </Stack>
  );
}


