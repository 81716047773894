import { IconButton, Popover } from "@mui/material";
import { useParams } from "react-router-dom";
import { AccessTime as AccessTimeIcon } from "@mui/icons-material";
import { useCallback, useState } from "react";
import ActivityLogPane from "../../log/ActivityLogPane";

const ANCHOR_ORIGIN = {
  vertical: "bottom",
  horizontal: "left",
}

export default function MissionActivityLog(props) {
  const { missionId } = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const onClick = useCallback((ev) => setAnchorEl(ev.currentTarget), []);
  const onClose = useCallback(() => setAnchorEl(null), []);
  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={onClick}>
        <AccessTimeIcon color="primary"/>
      </IconButton>
      <Popover
        open={open}
        onClose={onClose}
        anchorOrigin={ANCHOR_ORIGIN}
        anchorEl={anchorEl}
      >
        <ActivityLogPane contained resourceId={missionId} />
      </Popover>
    
    </>
  )
}