import { makeStyles } from "tss-react/mui";
import { Grid, Typography, TextField, Button, Switch } from "@mui/material";
import { ToggleSurface } from "../../common/core/Surface";
import { useTranslation } from "react-i18next";
import { useStation } from "../../../providers/StationProvider";
import { useCallback, useEffect, useState } from "react";
import LocationPicker from "../../common/inputs/LocationPicker";
import { Location } from "@emberly/zenith-client";
import LogoEditor from "../../common/inputs/LogoEditor";
import ResponsivePage from "../../common/core/ResponsivePage";

const useStyles = makeStyles()(
  (theme) => ({
    switch: {
      "& .MuiSwitch-track": {
        backgroundColor: theme.palette.neutral.dark
      }
    },
  })
);

export default function StationSettingsPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { station, updateStation, updating } = useStation();

  const [displayName, setDisplayName] = useState(station?.displayName || "");
  const [legalName, setLegalName] = useState(station?.legalName || "");
  const [location, setLocation] = useState(station?.location || new Location());
  const [includeDistanceFromStation, setIncludeDistanceFromStation] = useState(station?.includeDistanceFromStation || false);

  useEffect(() => {
    if (!!station) {
      setDisplayName(t => t || station.displayName || "");
      setLegalName(t => t || station.legalName || "");
      setLocation(t => station.location || t || new Location());
      setIncludeDistanceFromStation(station.includeDistanceFromStation || false);
    }
  }, [station]);

  const onSave = useCallback(async () => {

    await updateStation({
      displayName,
      legalName,
      location: location.hasCoordinates ? { coordinates: location.coordinates, address: location.address } : undefined,
      includeDistanceFromStation
    });

  }, [updateStation, displayName, legalName, location, includeDistanceFromStation]);

  const changes = !!station && (
    (!!displayName && station?.displayName !== displayName) ||
    (!!legalName && station?.legalName !== legalName) ||
    (!!location && (!station.location || station.location.address !== location.address)) ||
    (station.includeDistanceFromStation !== includeDistanceFromStation)
  );

  return (
    <ResponsivePage
      xs={12}
      sm={12}
      md={8}
      lg={5}
      xl={4}
      xxl={4}
      title={t("stationSettings:header")}
    >
      <ToggleSurface
        title={t("stationSettings:title")}
      >
        <Grid item container spacing={2}>

          <Grid item xs={12}>
            <LogoEditor />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("stationSettings:displayName")}
              variant="filled"
              value={displayName}
              onChange={ev => setDisplayName(ev.target.value)}
              fullWidth
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label={t("stationSettings:legalName")}
              helperText={t("stationSettings:legalNameDescription")}
              variant="filled"
              value={legalName}
              onChange={ev => setLegalName(ev.target.value)}
              fullWidth
              size="small"
            />
          </Grid>


          <Grid item xs={12}>
            <LocationPicker
              picker
              label={t("stationSettings:location")}
              value={location}
              onChange={setLocation}
            />
          </Grid>

          <Grid item container xs={12} spacing={2} alignItems="center">
            <Grid item xs container direction="column">
              <Typography variant="body1">
                {t("stationSettings:includeDistance")}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {t("stationSettings:includeDistanceDescription")}
              </Typography>
            </Grid>

            <Grid item>
              <Switch
                className={classes.switch}
                checked={includeDistanceFromStation}
                onChange={(ev, checked) => setIncludeDistanceFromStation(checked)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" disabled={!changes || updating} onClick={onSave}>
              {t("stationSettings:save")}
            </Button>
          </Grid>
        </Grid>
      </ToggleSurface>

    </ResponsivePage>
  );
}