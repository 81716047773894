import { makeStyles } from "tss-react/mui";
import { Grid, FormControl, InputLabel, Select, MenuItem, Button, Divider, ListSubheader } from "@mui/material";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { useEntityById, useEntities, Compare, useEntityFunctions, StatusCode, useAuth, Task } from "@emberly/zenith-client";
import SimpleTable from "../../common/tables/SimpleTable";
import { getCurrencyShorthand } from "../../../providers/StationProvider";
import { FixNumber, GetUserReference } from "../../../common/orders";
import { ToggleSurface } from "../../common/core/Surface";
import { ExportEnums, ExportEnumsLists, OrderEnumsLists } from "../../../common/constants";
import { convertCashJournalsToFormat, downloadCSV } from "../../../common/exportformats";
import { IntegrationLibrary } from "../integrations/common/library";
import { useNotification } from "../../../providers/NotificationProvider";
import ExportHistorySection from "../../common/sections/ExportHistorySection";
import moment from "moment/moment";
import ConfirmDialog from "../../common/inputs/ConfirmDialog";

const useStyles = makeStyles()(
  (theme) => ({
    number: {
      color: theme.palette.text.disabled,
      fontWeight: "500"
    }
  })
);


const FILE_FORMATS_LEN = ExportEnumsLists.CashExportFormats.length;

export default function CashExportPage() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { exportId } = useParams();

  const { alert, snackbar } = useNotification();

  const { user } = useAuth();

  const [resendAction, setResendAction] = useState(null);
  const [format, setFormat] = useState(ExportEnums.CashExportFormats.XL10);
  const { entity: exportEntity, updateEntityField } = useEntityById("CashExport", exportId);


  const { entities: journalLines, loading: journalLinesLoading } = useEntities("CashJournal", useMemo(() => ({
    path: "exportId",
    comparer: Compare.EQ,
    value: exportId,
    name: `cashjournals_${exportId}`
  }), [exportId]));

  const { entities: orders, loading: ordersLoading } = useEntities("Order", useMemo(() => ({
    or: [
      {
        path: "payment.terminal.exportId",
        comparer: Compare.EQ,
        value: exportId,
      },
      {
        path: "payment.request.exportId",
        comparer: Compare.EQ,
        value: exportId,
      }
    ],
    name: `orders_cashexport_${exportId}`
  }), [exportId]));

  const { entities: integrationEntities } = useEntities("Integration");
  const { emitServiceEvent: emitIntegrationEvent } = useEntityFunctions("Integration");
  const integrations = useMemo(() => integrationEntities.filter(t => !!IntegrationLibrary.get(t.serviceType)?.capabilities?.ImportCashOrders), [integrationEntities]);
  const [working, setWorking] = useState(false);

  const logExport = useCallback((friendlyName) => {
    updateEntityField("history", [
      ...(exportEntity.history || []),
      {
        friendlyName,
        user: GetUserReference(user),
        created: moment.utc().toISOString()
      }
    ]);
    if (!exportEntity.exported) {
      updateEntityField("exported", true);
    }
  }, [updateEntityField, user, exportEntity]);


  const sendToIntegration = useCallback(async () => {
    try {
      snackbar(t("integrations:common:sending"));
      setWorking(true);
      const selected = integrations[format - FILE_FORMATS_LEN];
      const response = await emitIntegrationEvent("ImportCashOrders", { integrationId: selected.id, exportId })
      const library = IntegrationLibrary.get(selected.serviceType);

      if (response.statusCode === StatusCode.Success) {
        snackbar(t("integrations:common:sendingSuccess"), "success");
        logExport(selected.friendlyName);

      } else {
        snackbar(t("integrations:common:sendingError"), "error");

        if (!!response?.value) {
          await alert(
            t(`integrations:library:${library.name}:errors:${response.message}`),
            (response.value.detail || response.value.title || "").replace(/[^A-Z\d\s.:æøåÆØÅ]/gi, ''),
            "error"
          );

        } else if (!!response.message) {
          await alert(
            t(`integrations:library:${library.name}:errors:${response.message}`),
            t(`integrations:library:${library.name}:errorDescriptions:${response.message}`),
            "error"
          );
        }
      }

    } catch (err) {
      console.log(err);
    } finally {
      setWorking(false);
    }
  }, [format, integrations, emitIntegrationEvent, exportId, alert, t, snackbar, logExport]);

  const downloadExport = useCallback(() => {
    const data = convertCashJournalsToFormat(format, journalLines);
    downloadCSV(data, `cashexport_${exportEntity.number}.csv`);
    logExport(ExportEnumsLists.CashExportFormats[format]);
  }, [journalLines, format, exportEntity, logExport]);


  const triggerExport = useCallback(async () => {

    if (exportEntity.exported) {
      try {
        const t = new Task();
        setResendAction(t)
        await t.wait();
      } catch { 
        return;
      } finally {
        setResendAction(null);
      }
    }

    if (format < FILE_FORMATS_LEN) {
      downloadExport();
    } else {
      sendToIntegration();
    }
  }, [format, downloadExport, sendToIntegration, exportEntity]);


  const journalLinesColumns = useMemo(() => {
    return [
      {
        field: "exportNumber",
        type: "number",
        headerName: t("cashJournal:fieldNumber"),
        flex: 0
      },
      {
        field: "orderNumber",
        type: "number",
        headerName: t("cashJournal:fieldOrderNumber"),
        flex: 0
      },
      {
        field: "row",
        type: "number",
        headerName: t("cashJournal:fieldRow"),
        flex: 0
      },
      {
        field: "item.quantity",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.item?.quantity || "1"),
        valueFormatter: params => FixNumber(params.value),
        headerName: t("cashJournal:fieldQuantity"),
        flex: 0
      },
      {
        field: "unitPrice.value",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.unitPrice?.value || "0"),
        renderCell: (params) => `${FixNumber(params.row.unitPrice?.value)} ${getCurrencyShorthand(t, params.row.unitPrice?.currency)}`,
        headerName: t("cashJournal:fieldUnitPrice"),
        minWidth: 120,
      },
      {
        field: "costPrice.value",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.costPrice?.value || "0"),
        renderCell: (params) => `${FixNumber(params.row.costPrice?.value)} ${getCurrencyShorthand(t, params.row.costPrice?.currency)}`,
        headerName: t("cashJournal:fieldCostPrice"),
        minWidth: 120,
      },
      {
        field: "grossPrice.value",
        type: "number",
        entityType: "decimal",
        valueGetter: (params) => Number(params.row.grossPrice?.value || "0"),
        renderCell: (params) => `${FixNumber(params.row.grossPrice?.value)} ${getCurrencyShorthand(t, params.row.grossPrice?.currency)}`,
        headerName: t("cashJournal:fieldGrossPrice"),
        minWidth: 120,
      },
      {
        field: "item.code",
        valueGetter: (params) => params.row.item?.code || "-",
        headerName: t("cashJournal:fieldProductCode"),
        flex: 0
      },
      {
        field: "item.name",
        valueGetter: (params) => params.row.item?.name || "-",
        headerName: t("cashJournal:fieldProductName"),
        flex: 0
      },
      {
        field: "sender.name",
        valueGetter: (params) => params.row.sender?.name || "-",
        headerName: t("cashJournal:fieldSender"),
        flex: 1,
      },
      {
        field: "account",
        valueGetter: (params) => params.row.account || "-",
        headerName: t("cashJournal:fieldAccount"),
        flex: 1,
      },
      {
        field: "item.productGroup.vatCode.code",
        valueGetter: (params) => params.row.item?.productGroup?.vatCode?.code || "-",
        headerName: t("cashJournal:fieldVatCode"),
        flex: 1,
      },
    ]
  }, [t]);

  const ordersColumns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("orderFields:fieldNumber"),
        flex: 0
      },
      {
        field: "missionNumber",
        type: "number",
        headerName: t("orderFields:fieldMission"),
        flex: 0
      },
      {
        field: "customer.name",
        headerName: t("orderFields:fieldCustomer"),
        valueGetter: (params) => params.row.customer?.name || "-",
        minWidth: 160,
        flex: 0.5,
      },
      {
        field: "payment.method",
        headerName: t("orderFields:fieldPaymentMethod"),
        valueGetter: (params) => t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[params.row.payment.method]}`),
        minWidth: 160
      },
      {
        field: "payment.calculatedTotal.value",
        type: "number",
        entityType: "decimal",
        headerName: t("orderFields:fieldSumTotal"),
        valueGetter: (params) => Number(params.row.payment.calculatedTotal?.value || "0"),
        renderCell: (params) => `${FixNumber(params.row.payment.calculatedTotal?.value)} ${getCurrencyShorthand(t, params.row.payment.calculatedTotal?.currency)}`,
        minWidth: 120
      },
      {
        field: "deductible.price.value",
        type: "number",
        entityType: "decimal",
        headerName: t("orderFields:fieldDeductible"),
        valueGetter: (params) => Number(params.row.deductible?.price?.value || "0"),
        renderCell: (params) => params.row.deductible ? `${FixNumber(params.row.deductible?.price?.value)} ${getCurrencyShorthand(t, params.row.deductible?.price?.currency)}` : "-",
        minWidth: 120
      },
      {
        field: "payment.sender.name",
        headerName: t("orderFields:fieldSender"),
        valueGetter: (params) => params.row.payment.sender?.name || "-",
        minWidth: 160,
        flex: 0.5
      },
    ]
  }, [t]);

  const onOrderRowClick = useCallback((params, ev) => {
    navigate(`./${params.row.missionId}/${params.id}`, { relative: "path" });
  }, [navigate]);


  const onJournalRowClick = useCallback((params, ev) => {
    if (!!params.row.missionId) {
      navigate(`./${params.row.missionId}/${params.row.orderId}`, { relative: "path" });
    }
  }, [navigate]);


  return (
    <ResponsivePage
      title={<>{t("cashExport:title")} <span className={classes.number}>#{exportEntity?.number || ""}</span></>}
      xs={12}
      sm={12}
      lg={12}
      xl={12}
      xxl={12}
      backPath="../"
      backButton
    >

      <ToggleSurface
        title={t("cashExport:exportTitle")}
        xs={12}
        lg={6}
      >
        <Grid item xs={12} container spacing={2} alignItems="center">

          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel>{t("cashExport:exportSelect")}</InputLabel>
              <Select onChange={(ev) => setFormat(ev.target.value)} value={format}>

                <ListSubheader>{t("cashExport:filesSubHeader")}</ListSubheader>
                {ExportEnumsLists.CashExportFormats.map((type, value) => <MenuItem value={value} key={value}>{type}</MenuItem>)}

                {!!integrations.length ? <Divider /> : null}
                {!!integrations.length ? <ListSubheader>{t("cashExport:integrationsSubHeader")}</ListSubheader> : null}
                {integrations.map((ig, value) => <MenuItem value={value + FILE_FORMATS_LEN} key={value}>{ig.friendlyName}</MenuItem>)}

              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Button variant="contained" fullWidth size="large" onClick={triggerExport} disabled={journalLinesLoading || working}>
              {working ? t("cashExport:working") : format < FILE_FORMATS_LEN ? t("cashExport:downloadButton") : t("cashExport:sendButton")}
            </Button>
          </Grid>

        </Grid>
      </ToggleSurface>

      <ConfirmDialog
        task={resendAction}
        title={t("integrations:resend:title")}
        description={t("integrations:resend:description")}
        confirmText={t("integrations:resend:confirmText")}
      />


      <ToggleSurface
        title={!!exportEntity?.history?.length ? t("cashExport:history") : null}
        xs={12}
        lg={6}
      >
        <ExportHistorySection
          loading={!exportEntity}
          history={exportEntity?.history || []}
        />
      </ToggleSurface>


      <ToggleSurface title={t("cashExport:orders")}>
        <SimpleTable
          tableId="cashexport_orders"
          loading={ordersLoading}
          onRowClick={onOrderRowClick}
          columns={ordersColumns}
          rows={orders}
          emptyStateTitle={t("cashExport:ordersEmptyStateTitle")}
          emptyStateDescription={t("cashExport:ordersEmptyStateDescription")}
          grow
          xs={12}
        />
      </ToggleSurface>

      <ToggleSurface title={t("cashExport:journalLines")}>
        <SimpleTable
          tableId="cashexport_journallines"
          loading={journalLinesLoading}
          onRowClick={onJournalRowClick}
          columns={journalLinesColumns}
          rows={journalLines}
          emptyStateTitle={t("cashJournal:emptyStateTitle")}
          emptyStateDescription={t("cashJournal:emptyStateDescription")}
          xs={12}
        />
      </ToggleSurface>

    </ResponsivePage>
  );
}

