import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { Compare } from "@emberly/zenith-client";
import moment from "moment/moment";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import EntityTable from "../../../common/tables/EntityTable";

const Query = {
  name: "payment_requested",
  and: [
    { path: "payment.request.requested", comparer: Compare.EQ, value: true },
    { path: "payment.request.confirmed", comparer: Compare.EQ, value: false }
  ]
};

export default function RequestedPaymentsSection() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("requestedPayments:fieldNumber"),
        flex: 0
      },
      {
        field: "missionNumber",
        type: "number",
        headerName: t("requestedPayments:fieldMission"),
        flex: 0
      },
      {
        field: "payment.request.requestedDate",
        type: "date",
        valueGetter: (params) => params.row.payment.request?.requestedDate,
        valueFormatter: (params) => !!params.value ? moment(params.value).format("l") : "-",
        headerName: t("requestedPayments:fieldDate"),
        minWidth: 120,
        flex: 0
      },
      {
        field: "customer.name",
        headerName: t("requestedPayments:fieldCustomer"),
        valueGetter: (params) => params.row.customer?.name || "-",
        minWidth: 160,
        flex: 0.5,
      },
      {
        field: "payment.calculatedTotal.value",
        type: "number",
        entityType: "decimal",
        headerName: t("requestedPayments:fieldSumTotal"),
        valueGetter: (params) => Number(params.row.payment.calculatedTotal?.value || "0"),
        renderCell: (params) => `${FixNumber(params.row.payment.calculatedTotal?.value)} ${getCurrencyShorthand(t, params.row.payment.calculatedTotal?.currency)}`,
        minWidth: 120
      },
      {
        field: "deductible.price.value",
        type: "number",
        entityType: "decimal",
        headerName: t("requestedPayments:fieldDeductible"),
        valueGetter: (params) => Number(params.row.deductible?.price?.value || "0"),
        renderCell: (params) => params.row.deductible ? `${FixNumber(params.row.deductible?.price?.value)} ${getCurrencyShorthand(t, params.row.deductible?.price?.currency)}` : "-",
        minWidth: 120
      },
      {
        field: "payment.sender.name",
        headerName: t("requestedPayments:fieldSender"),
        valueGetter: (params) => params.row.payment.sender?.name || "-",
        minWidth: 160,
        flex: 0.5,
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./requested/${params.row.missionId}/${params.id}` : `./${params.row.missionId}/${params.id}`, { relative: "path" });
  }, [navigate, tabId]);

  return (
    <EntityTable
      tableId="requested_payments"
      type="Order"
      filter={Query}
      onRowClick={onRowClick}
      columns={columns}
      emptyStateTitle={t("requestedPayments:emptyStateTitle")}
      emptyStateDescription={t("requestedPayments:emptyStateDescription")}
      serverSidePaging
      xs={12}
    />
  );
}

