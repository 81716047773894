import { makeStyles } from "tss-react/mui";
import { Grid, Typography, IconButton, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMission } from "../../../providers/MissionProvider";
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Delete as DeleteIcon, Description as FileIcon } from "@mui/icons-material";
import { Task } from "@emberly/zenith-client";
import ConfirmDialog from "../inputs/ConfirmDialog";
import Viewer from "viewerjs";
import moment from "moment/moment";
import "viewerjs/dist/viewer.css";
import { useDevice } from "../../../providers/DeviceProvider";
import EmptyState from "../core/EmptyState";

const useStyles = makeStyles()(
  (theme) => ({
    imageContainer: {
      position: "relative",
      width: "100%",
      height: "152px",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      "&:after": {
        zIndex: 3,
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        right: 0,
        opacity: 0,
        background: theme.palette.action.hover,
        borderRadius: theme.spacing(1),
        content: "''",
        display: "block",
        pointerEvents: "none"
      },
      "&:hover:after": {
        opacity: 1
      }
    },
    image: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: theme.spacing(1),
      cursor: "pointer"
    },
    deleteImageButton: {
      position: "absolute",
      top: "4px",
      right: "4px",
      zIndex: 3,
      background: "rgba(255,255,255,0.77)",
      "&:hover": {
        background: "rgba(255,255,255,1.0)"
      }
    },
    imageProgressContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1),
      background: theme.palette.background.grey
    },
    documentContainer: {
      borderRadius: theme.spacing(1),
      cursor: "pointer",
      "&:after": {
        zIndex: 3,
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        right: 0,
        opacity: 0,
        background: theme.palette.action.hover,
        borderRadius: theme.spacing(1),
        content: "''",
        display: "block",
        pointerEvents: "none"
      },
      "&:hover:after": {
        opacity: 1
      }
    }
  })
);

export default function FilesSection(props) {
  const { showEmptyState } = props;
  const { t } = useTranslation();
  const [deleteAction, setDeleteAction] = useState(null);
  const { slim } = useDevice();
  const { classes } = useStyles();

  const [gallery, setGallery] = useState(null);
  const ref = useRef(null);

  const { deleteFile, mission, revision, uploadingFiles, setUploadingFiles } = useMission();
  const uploads = mission?.files?.uploads;

  const { images, documents } = useMemo(() => {

    const buff = new Set(uploadingFiles);

    const files = Object.values(uploads)
      .filter(f => !!f)
      .map(f => {
        const u = uploadingFiles.find(t => t.id === f.id);

        if (!!u) {
          buff.delete(u);
          return { ...f, loaded: u.loaded, total: u.total, deleting: u.deleting }
        }

        return f;
      }).concat([...buff]);

    return {
      images: files.filter(t => t.type === 1),
      documents: files.filter(t => t.type !== 1)
    }
  }, [uploads, mission, uploadingFiles, revision]);

  const onDeleteFile = useCallback(async (ev, fileId) => {
    try {
      ev.preventDefault();
      ev.stopPropagation();

      const task = new Task();
      setDeleteAction(task);
      await task.wait();
      setUploadingFiles(l => [...l.filter(t => t.id !== fileId), { id: fileId, deleting: true }])
      await deleteFile(fileId);
      setUploadingFiles(l => l.filter(t => t.id !== fileId));

    } catch (err) {
      console.log(err);
    } finally {
      setDeleteAction(null);
    }
  }, [deleteFile]);

  const hasImages = images.length > 0;

  useEffect(() => {
    if (!!ref && hasImages) {
      const gallery = new Viewer(
        ref.current,
        {
          filter: (image) => {
            return image.classList.contains("gallery-image") && image.complete && !!image.src && !!image.naturalWidth;
          },
          transition: false,
          toolbar: false,
          button: false
        }
      );

      setGallery(gallery);

      return () => gallery?.destroy();
    }
  }, [ref, hasImages]);

  useEffect(() => {
    gallery?.update();
    const timer = setTimeout(() => {
      gallery?.update();
    }, 2500);
    return () => clearTimeout(timer);
  }, [images, gallery, uploadingFiles]);

  const isEmpty = images.length === 0 && documents.length === 0;

  return (
    <>
      <Grid item container spacing={(images.length > 0 || documents.length > 0) ? 1 : undefined} ref={ref}>

        {
          isEmpty && showEmptyState ? (
            <EmptyState
              title={t("mission:cards:files:emptyStateTitle")}
              description={t("mission:cards:files:emptyStateDescription")}
            />
          ) : null
        }

        {
          images.length > 0 ? (
            <>
              {!slim ? (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">{t("mission:cards:files:imagesHeader")}</Typography>
                </Grid>
              ) : null}

              {images.map((f, key) => <ImageItem key={key} file={f} onDelete={ev => onDeleteFile(ev, f.id)} sx={slim ? 6 : 3} />)}
            </>
          ) : null
        }

        {documents.length > 0 ? (
          <>
            <Grid item xs={12}>
              <Typography variant="subtitle1">{t("mission:cards:files:documentsHeader")}</Typography>
              <List>
                {documents.map((f, key) =>
                  <Fragment key={key}>
                    <DocumentItem file={f} onDelete={ev => onDeleteFile(ev, f.id)} />
                    {key !== documents.length - 1 ? (
                      <Divider />
                    ) : null}
                  </Fragment>
                )}
              </List>
            </Grid>
          </>
        ) : null}

      </Grid>

      <ConfirmDialog
        task={deleteAction}
        title={t("mission:cards:files:onDeleteTitle")}
        description={t("mission:cards:files:onDeleteDescription")}
        confirmText={t("mission:cards:files:onDeleteConfirm")}
      />

    </>
  );
}

function ImageItem(props) {
  const { file, onDelete, sx } = props;
  const { classes } = useStyles();

  const loading = !file.complete;
  const progress = loading && !!file.loaded ? (file.loaded / file.total) * 100.0 : 0;
  const deleting = file.deleting;

  return (
    <Grid item container xs={sx}>
      <Grid item className={classes.imageContainer}>
        {
          !!file.url && file.complete && !deleting ? (
            <img src={file.url} className={`${classes.image} gallery-image`} alt={file.name} loading="lazy" />
          ) : (
            <div className={classes.imageProgressContainer}>
              <CircularProgress variant={progress > 0 ? "determinate" : "indeterminate"} value={progress > 0 ? progress : undefined} />
            </div>
          )
        }
        <IconButton size="small" className={classes.deleteImageButton} onClick={onDelete} disabled={deleting || (loading && !!file.total)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

function DocumentItem(props) {
  const { file, onDelete } = props;
  const { classes } = useStyles();

  const loading = !file.complete;
  const progress = loading && !!file.loaded ? (file.loaded / file.total) * 100.0 : 0;
  const deleting = file.deleting;

  return (
    <ListItem
      className={classes.documentContainer}
      onClick={() => {
        if (file.complete) {
          window.open(file.url, "_blank").focus();
        }
      }}
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={onDelete} disabled={deleting || (loading && !!file.total)}>
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemAvatar>
        {!!file.url && file.complete && !deleting ? (
          <FileIcon fontSize="large" />
        ) : (
          <CircularProgress variant={progress > 0 ? "determinate" : "indeterminate"} value={progress > 0 ? progress : undefined} />
        )}
      </ListItemAvatar>
      <ListItemText primary={file.name} secondary={`${!!file.created ? moment(file.created).format("lll") : ""} - ${formatFileSize(file.size)}`} />
    </ListItem>
  );
}

const formatFileSize = (size) => {
  return size < 1024 ? `${size}B` : size < 1024 * 1024 ? `${Math.ceil(size / 1024)}KB` : `${Math.ceil(size / (1024 * 1024))}MB`;
}
