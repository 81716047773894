import { useState, useEffect } from "react";

export function useIsPortrait() {
  const [ isPortrait, setIsPortrait ] = useState(window.innerWidth < 512);

  useEffect(() => {
    const setScreenOrientation = () => {
      let orientation = window.innerWidth < 512;
      setIsPortrait(orientation);
    };

    window.addEventListener("resize", setScreenOrientation);
    window.addEventListener("orientationchange", setScreenOrientation);
    
    return () => {
      window.removeEventListener("resize", setScreenOrientation);
      window.removeEventListener("orientationchange", setScreenOrientation);
    };
  },[]);

  return isPortrait;
}