import { makeStyles } from "tss-react/mui";
import { Grid, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntityField, Defaults } from "@emberly/zenith-client";
import CustomerPicker from "../billing/CustomerPicker";
import OrderLines from "../billing/OrderLines";
import OrderSummary from "../billing/OrderSummary";
import OrderCheckout from "../billing/OrderCheckout";
import { useOrder } from "../../../providers/OrderProvider";
import { useMission } from "../../../providers/MissionProvider";
import { useMemo } from "react";
import { OrderEnums } from "../../../common/constants";

const useStyles = makeStyles()(
  (theme) => ({
    subheader: {
      marginBottom: theme.spacing(1)
    }
  })
);

export default function OrderSection(props) {
  const { sectionRef } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const comment = useEntityField("comment", Defaults.String);
  const { open, order } = useOrder();
  const { orders } = useMission();


  const disabled = useMemo(() => {
    if (!order?.vatTransfer?.order?.id) return false;

    const transferOrder = orders.find(t => t.id === order.vatTransfer.order.id);

    if (!transferOrder) return false;

    if (transferOrder?.payment?.invoice?.state >= OrderEnums.InvoiceState.Queued) {
      return true;
    }

    return false;
  }, [orders, order]);


  return (
    <Grid item container spacing={2} ref={sectionRef}>

      <Grid item container xs={12} lg={12} xxl={6} spacing={2} alignContent="flex-start">

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.subheader}>{t("order:customer:title")}</Typography>
          <CustomerPicker />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label={t("order:customer:comment")}
            variant="filled"
            multiline
            fullWidth
            size="small"
            value={comment.value}
            onChange={comment.onChange}
            disabled={!open}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.subheader}>{t("order:orderLines:title")}</Typography>
          <OrderLines disabled={disabled} />
        </Grid>
      </Grid>


      <Grid item container xs={12} lg={12} xxl={6} spacing={2} alignContent="flex-start">
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.subheader}>{t("order:summary:title")}</Typography>
          <OrderSummary disabled={disabled} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.subheader}>{t("order:checkout:title")}</Typography>
          <OrderCheckout />
        </Grid>

      </Grid>


    </Grid>
  );
}
