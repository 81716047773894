import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity } from "@emberly/zenith-client";
import { useState } from "react";
import DateAndTimePicker from "../../inputs/DateAndTimePicker";
import moment from "moment/moment";

export default function InvoiceInformationDialog(props) {
  const { onClose } = props;
  const { t } = useTranslation();
  const { updateEntityField, entity } = useEntity();

  const payment = entity.payment;
  const invoiceInfo = payment?.invoice;

  const [invoiceDate, setInvoiceDate] = useState(invoiceInfo?.invoiceDate || moment.utc().toISOString());
  const [dueDate, setDueDate] = useState(invoiceInfo?.dueDate || moment.utc().add(14, "d").toISOString());

  const [reference, setReference] = useState(payment?.reference || "");
  const [comment, setComment] = useState(payment?.comment || "");

  const onSubmit = () => {
    updateEntityField("payment.invoice.dueDate", dueDate);
    updateEntityField("payment.invoice.invoiceDate", invoiceDate);
    updateEntityField("payment.reference", reference);
    updateEntityField("payment.comment", comment);
    onClose();
  };

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        {t("order:invoiceInformationDialog:title")}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={1}>

          <Grid item xs={12}>
            <DateAndTimePicker
              label={t("order:invoiceInformationDialog:invoiceDate")}
              value={invoiceDate}
              onChange={(ev, v) => setInvoiceDate(v)}
              fromNow
            />
          </Grid>

          <Grid item xs={12}>
            <DateAndTimePicker
              label={t("order:invoiceInformationDialog:dueDate")}
              value={dueDate}
              onChange={(ev, v) => setDueDate(v)}
              fromNow
            />
          </Grid>


          <Grid item xs={12}>
            <TextField
              label={t("order:invoiceInformationDialog:reference")}
              variant="filled"
              multiline
              fullWidth
              size="small"
              value={reference}
              onChange={ev => setReference(ev.target.value)}
            />
          </Grid>


          <Grid item xs={12}>
            <TextField
              label={t("order:invoiceInformationDialog:comment")}
              variant="filled"
              multiline
              fullWidth
              size="small"
              value={comment}
              onChange={ev => setComment(ev.target.value)}
            />
          </Grid>


        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("order:invoiceInformationDialog:cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t("order:invoiceInformationDialog:save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}


