import { makeStyles } from "tss-react/mui";
import { Grid, Table, TableBody, TableRow, TableCell, TableHead, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmptyState from "../core/EmptyState";
import moment from "moment/moment";

const useStyles = makeStyles()(
  (theme) => ({
    table: {
      width: "100%",
      "& td": {
        border: "none",
      }
    },
    emptyState: {
      height: "152px",
      flexWrap: "nowrap"
    }
  })
);

export default function ExportHistorySection(props) {
  const { history, loading } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  return loading ? (
    <Grid className={classes.emptyState} item container xs={12} alignItems="center" justifyContent="center" direction="column">
      <Grid item>
        <CircularProgress variant="indeterminate" />
      </Grid>
    </Grid>
  ) : !history.length ? (
    <EmptyState
      className={classes.emptyState}
      title={t("exportHistory:emptyStateTitle")}
      description={t("exportHistory:emptyStateDescription")}
    />
  ) : (

    <Grid item container xs={12} alignItems="center" justifyContent="center" direction="column">
      <Table className={classes.table}>

        <TableHead>
          <TableRow>
            <TableCell>{t("exportHistory:created")}</TableCell>
            <TableCell>{t("exportHistory:export")}</TableCell>
            <TableCell>{t("exportHistory:exportedBy")}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>

          {
            history.map((item, i) => (
              <TableRow key={i}>
                <TableCell>{moment(item.created).format("lll")}</TableCell>
                <TableCell>{item.friendlyName}</TableCell>
                <TableCell>{item.user?.name}</TableCell>
              </TableRow>
            ))
          }

        </TableBody>
      </Table >
    </Grid>
  );
}
