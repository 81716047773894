import { Search} from "@emberly/zenith-client";

export function getTextSearch(path, inputValue) {
  const query = sanitizeSearchValue(inputValue);
  return {
    path: path,
    function: shouldUseTextSearch(query) ? Search.TEXT : Search.WILDCARD,
    value: getSearchValue(query)
  };
}


export function shouldUseTextSearch(inputValue) {
  return !!inputValue && inputValue !== "*" && inputValue.includes(" ");
}

export function getSearchValue(inputValue) {
  if (shouldUseTextSearch(inputValue)) {
    return inputValue;
  } else if (!inputValue || inputValue === "*") {
    return "*";
  } else {
    return "*" + inputValue + "*"
  }
}

export function sanitizeSearchValue(inputValue) {
  return inputValue.replace(/[-]/g, " ").replace(/[^a-zA-Z0-9* æøåÆØÅ]/g, "");
};