import React, { createContext, useContext, useCallback } from "react";
import { useMission } from "./MissionProvider";
import { AssumeTaskState, AssumeTaskStateEnum, MakeStateChange } from "../common/mission";
import { useTranslation } from "react-i18next";
import { ActivityEnums, TaskEnums } from "../common/constants";
import { useAuth } from "@emberly/zenith-client";
import { useStation } from "./StationProvider";
import { MakeActivity } from "../common/activity";


export const MissionTaskContext = createContext();
export const useMissionTask = () => useContext(MissionTaskContext);

export default function MissionTaskContextProvider(props) {
  const { id } = props;
  const { t } = useTranslation();
  const { user } = useAuth();
  const { logEvent } = useStation();
  const { mission, updateMissionField } = useMission();
  const missionTask = mission?.salvageTasks?.find(t => t.taskId === id);
  const taskIndex = mission?.salvageTasks?.findIndex(t => t.taskId === id);

  const notFound = !!mission && !missionTask;

  const taskState = AssumeTaskStateEnum(missionTask);
  const taskPath = `salvageTasks.${taskIndex}`
  const lastTaskState = AssumeTaskState(missionTask);
  const hasTransport = !missionTask?.resolvedAtLocation;

  const driverAssignedState = missionTask?.execution?.driverAssignedState || TaskEnums.DriverAssignedState.Unknown;

  const logTaskEvent = useCallback((type, value = "", description = "") => {
    logEvent(MakeActivity(ActivityEnums.Category.Mission, type, mission?.id, value, description, { id, name: missionTask.taskName }));
  }, [logEvent, mission, missionTask, id]);


  const continueMission = useCallback(async () => {

    if (driverAssignedState === TaskEnums.DriverAssignedState.Pending) {
      updateMissionField(`${taskPath}.execution.driverAssignedState`, TaskEnums.DriverAssignedState.Accepted);
      updateMissionField(`${taskPath}.execution.history.responded`, MakeStateChange(user));
      logTaskEvent(ActivityEnums.Type.AcceptedTask);
    } else {

      switch (taskState) {
        case TaskEnums.State.Created:
          updateMissionField(`${taskPath}.execution.history.started`, MakeStateChange(user));
          logTaskEvent(ActivityEnums.Type.TaskStarted);
          break;

        case TaskEnums.State.Started:
          updateMissionField(`${taskPath}.execution.history.arrived`, MakeStateChange(user));
          logTaskEvent(ActivityEnums.Type.TaskArrived);
          break;

        case TaskEnums.State.Arrived:
          if (hasTransport) {
            updateMissionField(`${taskPath}.execution.history.loaded`, MakeStateChange(user));
            logTaskEvent(ActivityEnums.Type.TaskLoaded);
          } else {
            updateMissionField(`${taskPath}.execution.history.completed`, MakeStateChange(user));
            logTaskEvent(ActivityEnums.Type.TaskCompleted);
          }
          break;

        case TaskEnums.State.Loaded:
          updateMissionField(`${taskPath}.execution.history.completed`, MakeStateChange(user));
          logTaskEvent(ActivityEnums.Type.TaskCompleted);
          break;

        case TaskEnums.State.Completed:
          updateMissionField(`${taskPath}.execution.history.acknowledged`, MakeStateChange(user));
          logTaskEvent(ActivityEnums.Type.TaskAcknowledged);
          break;

        default:
          console.log("err state");
      }

    }
  }, [updateMissionField, taskPath, driverAssignedState, taskState, user, hasTransport, logTaskEvent]);

  const rejectTask = useCallback(async () => {
    updateMissionField(`${taskPath}.execution.driverAssignedState`, TaskEnums.DriverAssignedState.Rejected);
    updateMissionField(`${taskPath}.execution.history.responded`, MakeStateChange(user));
    logTaskEvent(ActivityEnums.Type.RejectedTaskTask);
  }, [updateMissionField, taskPath, logTaskEvent, user]);


  return (
    <MissionTaskContext.Provider
      value={{
        taskId: id,
        missionTask,
        lastTaskState,
        mission,
        taskState,
        updateMissionField,
        taskPath,
        taskIndex,
        hasTransport,
        driverAssignedState,
        rejectTask,
        continueMissionText: getContinueMissionText(t, taskState, hasTransport, driverAssignedState),
        continueMission,
        notFound
      }}
    >
      {props.children}
    </MissionTaskContext.Provider>
  );
}



function getContinueMissionText(t, taskState, hasTransport, driverAssignedState) {
  if (driverAssignedState === TaskEnums.DriverAssignedState.Pending) return t("task:common:accept");

  switch (taskState) {
    case TaskEnums.State.Created:
      return t("task:enums:state:Started");

    case TaskEnums.State.Started:
      return t("task:enums:state:Arrived");

    case TaskEnums.State.Arrived:
      if (hasTransport) {
        return t("task:enums:state:Loaded");
      } else {
        return t("task:enums:state:Completed");
      }

    case TaskEnums.State.Loaded:
      return t("task:enums:state:Completed");

    case TaskEnums.State.Completed:
      return t("task:enums:state:Acknowledged");

    default:
      return null;
  }
}
