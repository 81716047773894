import DetailsCard from "./cards/details/DetailsCard";
import MissionContextProvider from "../../../providers/MissionProvider";
import ServiceCard from "./cards/service/ServiceCard";
import CreateMissionButtonRow from "./common/CreateMissionButtonRow";
import ActorsCard from "./cards/actors/ActorsCard";
import { useParams } from "react-router-dom";
import { EntityContextProvider } from "@emberly/zenith-client";
import TaskCard from "./cards/tasks/TaskCard";
import FilesCard from "./cards/files/FilesCard";
import MissionLayout from "./common/MissionLayout";
import OrderCards from "./cards/order/OrderCards";


export default function MissionPage() {
  const { missionId } = useParams();
  return (
    <EntityContextProvider id={missionId} type="Mission">
      <MissionContextProvider>
        <MissionLayout>

          <DetailsCard />

          <ActorsCard />

          <TaskCard />

          <ServiceCard />

          <FilesCard />

          <CreateMissionButtonRow />

          <OrderCards />

        </MissionLayout>
      </MissionContextProvider>
    </EntityContextProvider>
  );
}