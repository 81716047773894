
import { TextField } from "@mui/material";
import { useCallback } from "react";

export default function TextFieldDecimal(props) {
  const { value, onChange, label, InputProps, disabled } = props;

  const handleChange = useCallback((ev) => {
    if (!ev.target.validity.badInput) {
      onChange(ev);
    }
  }, [onChange]);

  return (
    <TextField
      label={label}
      onChange={handleChange}
      value={value}
      variant="filled"
      fullWidth
      type="number"
      size="small"
      InputProps={InputProps}
      disabled={disabled}
    />
  );
}

