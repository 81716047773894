import { Chip } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      borderRadius: theme.spacing(0.5),
      height: "24px",
      background: "#FFF1D6",
      color: "#8E5722",
      fontSize: "14px",
    },
    stateTrue: {
      background: "#DBF6E5",
      color: "#118D57",
    }
  })
);

export default function FlagChip(props) {
  const { label, value } = props;
  const { classes } = useStyles();

  return (
    <Chip className={`${classes.root} ${value ? classes.stateTrue : ""}`} label={label} />
  );
}
