import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MissionEnumsLists } from "../../../../common/constants";
import { Compare } from "@emberly/zenith-client";
import EntityTable from "../../../common/tables/EntityTable";
import moment from "moment/moment";

export default function ContactMissionsCard(props) {
  const { contactId } = props;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onRowClick = useCallback((params, ev) => {
    navigate(`./${params.id}`, { relative: "path" });
  }, [navigate]);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        headerName: t("common:tables:id"),
        flex: 0
      },
      {
        field: "created",
        headerName: t("common:tables:created"),
        valueFormatter: (params) => moment(params.value).locale(i18n.language).format("L"),
        flex: 0,
        minWidth: 120,
      },
      {
        field: "type",
        headerName: t("common:tables:type"),
        valueGetter: (params) => t(`mission:enums:type:${MissionEnumsLists.Type[params.row.details.type]}`),
        flex: 1,
        minWidth: 120,
      },
      {
        field: "cause",
        headerName: t("common:tables:cause"),
        valueGetter: params => params.row.details?.cause?.name || "-",
        flex: 1,
        minWidth: 120,
      },
      {
        field: "object",
        headerName: t("common:tables:object"),
        valueGetter: (params) => {
          const type = params.row.target.type;
          const details = type === 1 ? params.row.target.carDetails : (type === 2 ? params.row.target.boatDetails : null);
          return details ? `${details.make} ${details.model}` : "-";
        },
        flex: 1,
        minWidth: 120,
      }
    ]
  }, [t, i18n.language]);

  const query = useMemo(() => ({
    or: [
      {
        path: "actors.owner.id",
        comparer: Compare.EQ,
        value: contactId, 
      },{
        path: "actors.requester.id",
        comparer: Compare.EQ,
        value: contactId, 
      },
    ],
    name: `contactmissionscard_${contactId}`
  }), [contactId]);

  return (
    <EntityTable
      tableId="contact_missions"
      type="Mission"
      columns={columns}
      filter={query}
      onRowClick={onRowClick}
      emptyStateTitle={t("contact:contactMissionsCard:emptyStateTitle")}
      emptyStateDescription={t("contact:contactMissionsCard:emptyStateDescription")}
      xs={12}
    />
  );
}