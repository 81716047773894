import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import moment from "moment/moment";
import { GetDaysStored, GetStoragePrice } from "../../../../../../common/mission";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1),
      background: theme.palette.background.grey,
      width: "100%",
      height: "auto"
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      }
    }
  })
);

export default function StorageDetailsCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { storageTask } = props;

  const { daysElapsed, accCosts} = useMemo(() => {
    return {
      daysElapsed: GetDaysStored(storageTask), 
      accCosts: GetStoragePrice(storageTask)
    };
  }, [storageTask, storageTask.stored, storageTask.rate.value]);

  return (
    <Grid item xs={12}>
      <div className={classes.root}>
        <Grid container alignItems="center">

          <Grid item xs={12}>
            <Typography variant="subtitle1">{t("mission:cards:tasks:storageTask:state:title")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Table className={classes.table}>
              <TableBody>

                <TableRow>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">{t("mission:cards:tasks:storageTask:state:elapsedTime")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{daysElapsed}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">{t("mission:cards:tasks:storageTask:state:accumulatedCosts")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{accCosts}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <Typography color="textSecondary" variant="body2">{t("mission:cards:tasks:storageTask:state:delivered")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">{storageTask.delivered ? moment(storageTask.deliveryTime).format("lll") : "-"}</Typography>
                  </TableCell>
                </TableRow>

              </TableBody>
            </Table>
          </Grid>

        </Grid>
      </div>
    </Grid>
  );
}

