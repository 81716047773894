import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Avatar, Divider, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ActivityEnumsLists } from "../../../../common/constants";
import moment from "moment/moment";
import { NavLink } from "react-router-dom";
import { GetResourceLink } from "../../../../common/activity";
import { Link as LinkIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      padding: theme.spacing(0),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    avatar: {
      marginTop: theme.spacing(0.5)
    },
    link: {
      textDecoration: "none",
      color: "inherit",
      "& svg": {
        marginBottom: "-4px",
        fontSize: "16px"
      }
    }
  })
);

export default function ActivityLogItem(props) {
  const { item } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const typeName = ActivityEnumsLists.Type[item.activityType];
  
  const categoryName = ActivityEnumsLists.Category[item.activityCategory];
  const timestamp = moment(item.created);
  const now = moment();
  const days = timestamp.diff(now, "days");

  const renderts = days === 0 ? timestamp.format("LT") : timestamp.format("LT l");


  return (
    <Box className={classes.root}>
      <Grid container direction="row" spacing={2} alignItems="flex-start">

        <Grid item>
          <Avatar
            className={classes.avatar}
            alt={item.sender?.name}
            src={item.avatar}
          />
        </Grid>

        <Grid item container xs alignItems="baseline" spacing={1}>
          <Grid item xs>
            <Typography variant="subtitle2">
              {t(`activity:enums:type:${typeName}`)}
            </Typography>
            <NavLink to={GetResourceLink(item)} className={classes.link}>
              <Typography variant="body2" color="textSecondary">{t(`activity:enums:category:${categoryName}`)} <LinkIcon color="inherit" fontSize="inherit"/></Typography>
            </NavLink>
            <Typography variant="caption" color="textSecondary">{item.sender?.name || ""}</Typography>
          </Grid>

          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {renderts}
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

      </Grid>
    </Box>
  );
}


