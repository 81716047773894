import Route from "./Route";
import { Location } from "@emberly/zenith-client";


export default class RouteCollection {

  constructor(stationLocation = null) {
    this._stationLocation = stationLocation;
    this._map = new Map();
  }

  setStationLocation(stationLocation) {
    this._stationLocation = stationLocation;
    this._map.forEach(t => t.setStationLocation(stationLocation));
  }

  getRoute(path) {
    if (this._map.has(path)) {
      return this._map.get(path);
    }

    const route = new Route([new Location()], this._stationLocation);
    this._map.set(path, route);

    return route;
  }

  destroy() {
    this._map.forEach(r => {
      r.removeAllListeners();
    });

    this._map.clear();
  }


}