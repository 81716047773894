import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";


export default function AlertDialog(props) {
  const { task, title, description, confirmText, color } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      open
      onClose={() => task.complete()}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color={color} onClick={() => task.complete()} autoFocus>
          {confirmText || t("common:ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}