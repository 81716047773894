import { Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";

const useStyles = makeStyles()(
  (theme) => ({
    emptyState: {
      height: "40vh",
      padding: theme.spacing(3),
    },
  })
);

export default function EmptyState(props) {
  const { title, description, className } = props;
  const { classes } = useStyles();

  return (
    <Grid item container xs={12} alignItems="center" justifyContent="center" direction="column" className={`${classes.emptyState} ${className || ""}`}>
      <Grid item>
        <InfoIcon />
      </Grid>

      <Grid item>
        <Typography variant="subtitle1" textAlign="center">{title}</Typography>
      </Grid>

      <Grid item>
        <Typography variant="body2" textAlign="center" color="textSecondary">{description}</Typography>
      </Grid>
    </Grid>
  );
}
