import { combineReducers } from "redux";

// reducer import
import appReducer from "./appreducer";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  app: appReducer
});

export default reducer;