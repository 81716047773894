import { ActivityEnums } from "./constants";



export function MakeActivity(activityCategory, activityType, resourceId, value = "", description = "", target = null) {
  return {
    resourceId,
    activityCategory,
    activityType,
    target,
    description,
    value,
  };
}


export function GetCategoryEnum(category) {
  switch (category) {
    case "Mission":
      return ActivityEnums.Category.Mission;
    case "Billing":
      return ActivityEnums.Category.Billing;
    case "Other":
      return ActivityEnums.Category.Other;

    default:
      return ActivityEnums.Category.None;
  }
}


export function GetResourceLink(item) {
  switch (item.activityCategory) {
    case ActivityEnums.Category.Mission:
      return `/missions/${item.resourceId}`;
    case ActivityEnums.Category.Billing:
      return "";
    case ActivityEnums.Category.Other:
      return "";
    default:
      return "";
  }
}