import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import moment from "moment/moment";
import { FixNumber } from "../../../../common/orders";
import { getCurrencyShorthand } from "../../../../providers/StationProvider";
import SimpleTable from "../../../common/tables/SimpleTable";
import { ToggleSurface } from "../../../common/core/Surface";
import { Grid } from "@mui/material";
import { MissionEnums, OrderEnums } from "../../../../common/constants";
import { Compare } from "@emberly/zenith-client";
import { AllTasksCompleted, AssumeStorageState } from "../../../../common/mission";
import { useOrdersWithMissions } from "../../../../hooks/useOrdersWithMissions";

const ORDERS_QUERY = {
  path: "payment.invoice.state",
  comparer: Compare.EQ,
  value: OrderEnums.InvoiceState.None,
  name: "open_invoice_orders"
};

export default function OpenInvoiceOrdersSection() {
  const { t } = useTranslation();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const { orders, showLoading } = useOrdersWithMissions(ORDERS_QUERY);

  const columns = useMemo(() => {
    return [
      {
        field: "number",
        type: "number",
        headerName: t("openInvoiceOrders:fieldNumber"),
        flex: 0
      },
      {
        field: "missionNumber",
        type: "number",
        headerName: t("openInvoiceOrders:fieldMission"),
        flex: 0
      },
      {
        field: "storage",
        headerName: t("openInvoiceOrders:fieldStorageState"),
        valueGetter: (params) => AssumeStorageState(params.row.mission),
        valueFormatter: (params) =>
          params.value === MissionEnums.StorageState.None ? t("openInvoiceOrders:noStorage") :
            params.value === MissionEnums.StorageState.Stored ?
              t("openInvoiceOrders:storageNotCompleted") :
              t("openInvoiceOrders:storageCompleted"),
      },
      {
        field: "taskState",
        type: "boolean",
        headerName: t("openInvoiceOrders:fieldTaskState"),
        valueGetter: (params) => AllTasksCompleted(params.row.mission),
        renderCell: (params) => params.value ? t("openInvoiceOrders:tasksCompleted") : t("openInvoiceOrders:tasksNotCompleted"),
        flex: 1,
        minWidth: 120
      },
      {
        field: "missionCreated",
        type: "date",
        headerName: t("openInvoiceOrders:fieldMissionCreated"),
        valueGetter: (params) => params.row.mission?.created,
        valueFormatter: (params) => !params.value ? "-" : moment(params.value).format("ll"),
        flex: 1,
        minWidth: 120
      },
      {
        field: "customer.name",
        headerName: t("openInvoiceOrders:fieldCustomer"),
        valueGetter: (params) => params.row.customer?.name || "-",
        minWidth: 160,
        flex: 0.5,
      },
      {
        field: "payment.calculatedTotal.value",
        type: "number",
        entityType: "decimal",
        headerName: t("openInvoiceOrders:fieldSumTotal"),
        valueGetter: (params) => params.row.totalSum?.toNumber() || 0,
        renderCell: (params) => `${FixNumber(params.row.totalSum)} ${getCurrencyShorthand(t, params.row.payment.calculatedTotal?.currency)}`,
        minWidth: 120
      },
      {
        field: "deductible.price.value",
        type: "number",
        entityType: "decimal",
        headerName: t("openInvoiceOrders:fieldDeductible"),
        valueGetter: (params) => Number(params.row.deductible?.price?.value || "0"),
        renderCell: (params) => params.row.deductible ? `${FixNumber(params.row.deductible?.price?.value)} ${getCurrencyShorthand(t, params.row.deductible?.price?.currency)}` : "-",
        minWidth: 120
      },
    ]
  }, [t]);

  const onRowClick = useCallback((params, ev) => {
    navigate(!tabId ? `./ready/${params.row.missionId}/${params.id}` : `./${params.row.missionId}/${params.id}`, { relative: "path" });
  }, [navigate, tabId]);

  return (
    <>
      <ToggleSurface>
        <Grid item xs={12}>
          <SimpleTable
            tableId="openinvoice_orders"
            onRowClick={onRowClick}
            columns={columns}
            rows={orders}
            loading={showLoading}
            emptyStateTitle={t("openInvoiceOrders:emptyStateTitle")}
            emptyStateDescription={t("openInvoiceOrders:emptyStateDescription")}
            xs={12}
          />
        </Grid>
      </ToggleSurface>
    </>
  );
}

