import { useMemo } from "react";
import { CalcToPaySum } from "../common/orders";
import { useEntities, Compare, distinct, toMap } from "@emberly/zenith-client";

export function useOrdersWithMissions(query) {

  const { entities: orders, loading: ordersLoading } = useEntities("Order", query);

  const missionIds = useMemo(() => distinct(orders, t => t.missionId), [orders]);

  const { entities: relatedOrders, loading: relatedOrdersLoading } = useEntities("Order", useMemo(() => ({
    path: "missionId",
    comparer: Compare.IN,
    value: missionIds
  }), [missionIds]), missionIds.length !== 0);

  const { entities: missions, loading: missionsLoading } = useEntities("Mission", useMemo(() => ({
    path: "id",
    comparer: Compare.IN,
    value: missionIds
  }), [missionIds]), missionIds.length !== 0);

  const missionOrders = useMemo(() => {
    const mmap = toMap(missions, t => t.id);
    return orders.map(order => ({ ...order, totalSum: CalcToPaySum(order, relatedOrders), mission: mmap.get(order.missionId) }));
  }, [missions, orders, relatedOrders]);

  const showLoading = (ordersLoading && orders.length === 0) || (missionIds.length !== 0 &&  (missionsLoading || relatedOrdersLoading));

  return {
    orders: missionOrders,
    relatedOrders,
    missions,
    showLoading,
    missionIds
  }
}