import { Grid, Typography, Button } from "@mui/material";
import { ToggleSurface } from "../../common/core/Surface";
import { useTranslation } from "react-i18next";
import ResponsivePage from "../../common/core/ResponsivePage";
import { NavLink } from "react-router-dom";


export default function NotFoundPage(props) {
  const { backPath } = props;
  const { t } = useTranslation();

  return (
    <ResponsivePage
      xs={12}
      lg={6}
      title={t("notFound:title")}
    >
      <ToggleSurface>

        <Grid item container spacing={1}>

          <Grid item xs={12}>
            <Typography variant="subtitle1">{t("notFound:subHeader")}</Typography>
            <Typography variant="body2" color="textSecondary">{t("notFound:description")}</Typography>
          </Grid>

          <Grid item xs={12}>
            <NavLink to={backPath || "/"} relative="path">
              <Button variant="contained">{t("notFound:button")}</Button>
            </NavLink>
          </Grid>

        </Grid>


      </ToggleSurface>

    </ResponsivePage>
  );
}