import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Table, TableBody, TableRow, TableCell, Divider, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStation } from "../../../../providers/StationProvider";
import { OrderEnums, OrderEnumsLists } from "../../../../common/constants";
import { useMemo } from "react";
import { FixNumber, SumOrdersByPaymentMethod } from "../../../../common/orders";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
        "& .MuiSkeleton-rounded": {
          minWidth: "24px"
        }
      },
    },
  })
);

export default function SettlementSummaryCard(props) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { orders, relatedOrders, loading, settlement } = props;
  const { priceUnit } = useStation();
  const currency = t(`order:enums:currencyShortHand:${OrderEnumsLists.Currency[priceUnit]}`); // TODO

  const { mobilePaymentSum, cashSum, cardSum, totalSum } = useMemo(() => {

    if (loading) {
      return {
        mobilePaymentSum: "0",
        cashSum: "0",
        cardSum: "0",
        totalSum: "0",
      };
    } else if (settlement) {
      return {
        mobilePaymentSum: settlement.mobilePaymentSum?.value || "0",
        cashSum: settlement.cashSum?.value || "0",
        cardSum: settlement.cardSum?.value || "0",
        totalSum: settlement.cardSum?.value || "0",
      };
    } else {
      const mobilePaymentSum = SumOrdersByPaymentMethod(orders, relatedOrders, OrderEnums.PaymentMethod.MobilePayment);
      const cashSum = SumOrdersByPaymentMethod(orders, relatedOrders, OrderEnums.PaymentMethod.Cash);
      const cardSum = SumOrdersByPaymentMethod(orders, relatedOrders, OrderEnums.PaymentMethod.Card);

      return {
        mobilePaymentSum,
        cashSum,
        cardSum,
        totalSum: mobilePaymentSum.plus(cardSum).plus(cashSum)
      };
    }

  }, [orders, relatedOrders, settlement, loading]);

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">{t("cashSettlementSummary:title")}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" >{t("cashSettlementSummary:cash")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" textAlign="right">{loading ? <Skeleton variant="rounded" /> : `${FixNumber(cashSum)} ${currency}`}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" >{t("cashSettlementSummary:card")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" textAlign="right">{loading ? <Skeleton variant="rounded" /> : `${FixNumber(cardSum)} ${currency}`}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" >{t("cashSettlementSummary:mobilePayment")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" textAlign="right">{loading ? <Skeleton variant="rounded" /> : `${FixNumber(mobilePaymentSum)} ${currency}`}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={2}>
                  <Divider />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body1" fontWeight={500}>{t("cashSettlementSummary:sum")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" textAlign="right">{loading ? <Skeleton variant="rounded" /> : `${FixNumber(totalSum)} ${currency}`}</Typography>
                </TableCell>
              </TableRow>


            </TableBody>
          </Table>
        </Grid>

      </Grid>
    </div>
  );
}
