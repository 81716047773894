import { makeStyles } from "tss-react/mui";
import { Collapse, Grid } from "@mui/material";


const useStyles = makeStyles()(
  () => ({
    root: {
      transition: "padding-top 0.25s"
    },
    hidden: {
      paddingTop: "0 !important"
    }
  })
);


export default function CollapseGridItem(props) {
  const { xs, sm, md, lg, xl, xxl } = props;
  const { classes } = useStyles();

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} className={`${classes.root} ${!props.in ? classes.hidden : ""}`}>
      <Collapse in={props.in}>
        {props.children}
      </Collapse>
    </Grid>
  )
}