import { useTranslation } from "react-i18next";
import { useEntity } from "@emberly/zenith-client";
import { useMission } from "../providers/MissionProvider";
import { useStation } from "../providers/StationProvider";
import { useCallback } from "react";
import { MakeReceipt } from "../common/receipt";



export default function useReceipt() {
  const { station, priceUnit } = useStation();
  const { mission, orders } = useMission();
  const { entity } = useEntity();
  const { t } = useTranslation();

  const downloadPDF = useCallback(() => {
    const doc = MakeReceipt(station, mission, orders, entity, priceUnit, t);
    doc.save(`receipt_${entity.number}.pdf`);
  }, [station, mission, orders, entity, t, priceUnit]);

  const sharePDF = useCallback(() => {
    const doc = MakeReceipt(station, mission, orders, entity, priceUnit, t);
    const name = `receipt_${entity.number}.pdf`;
    
    if (navigator.share) {

      try {
        const blob = doc.output("blob", { filename: name });
        const file = new File([blob], name, { type: "application/pdf" });

        navigator.share({
          title: t("order:receipt:shareTitle"),
          files: [ file ]
        });

      } catch (err) {
        console.log(err);
        doc.save(name);
      }
    
    } else {
      doc.save(name);
    }

  }, [station, mission, orders, entity, t, priceUnit]);

  return {
    downloadPDF,
    sharePDF
  };
}