import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearch } from "@emberly/zenith-client";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { getTextSearch } from "../../../common/search";

const useStyles = makeStyles()(
  (theme) => ({
    listbox: {
      scrollbarGutter: "stable both-edges",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "6px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "rgba(155, 155, 155, 0.5)",
      },
    }
  })
);


export default function EntitySearchPicker(props) { // https://mui.com/material-ui/react-autocomplete/
  const { path, type, label, value, onChange, disabled } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [popupOpen, setPopupOpen] = useState(false);
  
  const [option, setOption] = useState(value || null);
  const [inputValue, setInputValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setOption(value);
  }, [value]);

  // Make input, with button, and allow for endAdornment prop to be used as drag handle etc

  useEffect(() => {
    if (popupOpen) {

      const timer = setTimeout(
        () => setSearchQuery(inputValue),
        250
      );

      return () => {
        clearTimeout(timer);
      };
    }
  }, [inputValue, popupOpen]);

  const onPopupOpen = useCallback(() => {
    setSearchQuery(inputValue);
    setPopupOpen(true);
  }, [inputValue]);

  const filterQuery = useMemo(() => getTextSearch(path || "name", searchQuery), [searchQuery, path]);
  
  const { entities, loading } = useSearch(type, filterQuery, popupOpen);

  const onOptionSelected = useCallback((ev, option) => {
    if (!!option) {
      setOption(option);
      onChange(option);
    } else {
      onChange(null);
    }
  }, [onChange]);


  const onPopupClose = useCallback(() => setPopupOpen(false), []);

  return (
    <Autocomplete
      getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      options={entities}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      autoComplete
      fullWidth
      filterOptions={(x) => x}
      includeInputInList
      value={option}
      disabled={disabled}
      noOptionsText={loading ? <Skeleton variant="rounded" /> : t("common:noResults")}
      onChange={onOptionSelected}
      onInputChange={(_, v) => setInputValue(v)}
      ListboxProps={{
        className: classes.listbox
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          variant="filled"
          fullWidth
          sx={{
            "&:hover": {
              backgroundColor: "transparent"
            }
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option[path]}
          </li>
        );
      }}
    />
  );
}


