import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import HorizontalSelector from "../../../common/inputs/HorizontalSelector";
import { useDriver } from "../../../../providers/DriverProvider";

export default function DriverActiveStateSelector() {
  const { t } = useTranslation();
  const { active, setActive } = useDriver();

  const items = useMemo(() => [
    t("myMissions:driverActiveStateSelector:working"), 
    t("myMissions:driverActiveStateSelector:notWorking")
  ], [t]);

  return (
    <HorizontalSelector
      items={items}
      value={!!active ? 0 : 1}
      onChange={useCallback((v) => setActive(v === 0 ? true : false), [setActive])}
    />
  );
}
