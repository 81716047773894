import { makeStyles } from "tss-react/mui";
import { Grid, Typography, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntity } from "@emberly/zenith-client";
import moment from "moment/moment";
import { OrderEnums, OrderEnumsLists } from "../../../../../common/constants";

const useStyles = makeStyles()(
  (theme) => ({
    root: {
      width: "100%",
      height: "auto",
      background: theme.palette.background.grey,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2)
    },
    table: {
      width: "100%",
      "& td": {
        border: "none",
        padding: theme.spacing(1),
        paddingLeft: 0,
        paddingBottom: theme.spacing(0.25),
        paddingTop: theme.spacing(0.25),
      },
    },
  })
);

export default function CardInformationCard() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const { entity } = useEntity();
  const { terminal, method } = entity.payment;

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">

        <Grid item xs={12}>
          <Typography variant="subtitle2">{t("order:terminalInformation:title")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Table className={classes.table}>
            <TableBody>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:terminalInformation:state")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{terminal?.confirmed ? t("order:terminalInformation:stateConfirmed") : "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:terminalInformation:paymentMethod")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{t(`order:enums:paymentMethod:${OrderEnumsLists.PaymentMethod[entity.payment.method]}`)}</Typography>
                </TableCell>
              </TableRow>

              {method === OrderEnums.PaymentMethod.MobilePayment ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">{t("order:terminalInformation:service")}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" textAlign="left">{terminal?.service || "-"}</Typography>
                  </TableCell>
                </TableRow>
              ) : null}

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:terminalInformation:confirmedDate")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{terminal.confirmed && !!terminal.confirmedDate ? moment(terminal.confirmedDate).format("ll") : "-"}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:terminalInformation:person")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{entity?.payment?.sender?.name || ""}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:terminalInformation:settlement")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{terminal.settled ? t("order:terminalInformation:settled") : t("order:terminalInformation:notSettled")}</Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <Typography variant="body2" color="textSecondary">{t("order:terminalInformation:comment")}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" textAlign="left">{entity?.payment?.comment || "-"}</Typography>
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </Grid>

      </Grid>
    </div>
  );
}
