




export function validateExportSettings(exportSettings) {

  if (!exportSettings) return false;

  return !!exportSettings.mobilePaymentAccount && !!exportSettings.cashAccount && !!exportSettings.cardAccount && !!exportSettings.deductibleProductGroup?.id && !!exportSettings.vatTransferProductGroup?.id;
}