import { useDataFetcher } from "@emberly/zenith-client";
import { useState, useEffect } from "react";

export function useLocationStatistics() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetcher = useDataFetcher();

  useEffect(() => {
    let current = true;

    const cancelFetching = fetcher(`mission/statistics/locations`, (data) => {
      if (current) {
        setData(data);
        setLoading(false);
      }
    });

    return () => {
      current = false;
      cancelFetching();
    };
  }, [fetcher]);

  return { loading, data };
}